import { apiClient } from '../../../actions'
import { FINISHED_CONTENT } from '../../../config'
import { translation } from '../../../utils'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/surveys`

/**
 * Retrieve survey listing.
 */
export const fetchSurveys = (filter, config = {}) => {
    if (filter === 'ongoing') {
        return apiClient.get(`${baseUrl}?status=ongoing`, config)
    }
    if (filter === translation(FINISHED_CONTENT)) {
        return apiClient.get(`${baseUrl}?status=finished`, config)
    }
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve survey details by slug.
 */
export const fetchSurveyBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}

/**
 * Retrieve survey details.
 */
export const fetchSurvey = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}

/**
 * Retrieve survey stats results.
 */
export const fetchSurveyStatisticsBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}/statistics?slug=${slug}`, config)
}
