import _ from 'lodash'

export const setCoordsFromData = data => {
    if (typeof data === 'undefined') {
        return false
    }
    return data.map((coord, id) => {
        if (!coord.length) return []
        if (_.isArray(coord[0])) {
            return setCoordsFromData(coord)
        } else {
            return { lat: coord[0], lng: coord[1] }
        }
    })
}
