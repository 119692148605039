export const formIoDependencies = [
    {
        type: 'script',
        src: 'https://cdn.jsdelivr.net/npm/choices.js@4/public/assets/scripts/choices.min.js',
        attribute: 'data-formio-injected',
    },
    {
        type: 'link',
        src: '/assets/bootstrap.css',
        attribute: 'data-formio-injected',
    },
    {
        type: 'link',
        src: 'https://cdn.jsdelivr.net/npm/choices.js@4/public/assets/styles/choices.min.css',
        attribute: 'data-formio-injected',
    },
    {
        type: 'link',
        src: 'https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css',
        attribute: 'data-formio-injected',
    },
]
