import React from 'react'

import { Content } from '../../../components/Content'
import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'
import { HeaderPage } from '../../../components/HeaderPage'
import { Card } from '../../../components/Card'

import { ForgotPasswordResetContainer } from '../components/ForgotPassword'

const ResetPasswordView = ({
    match: {
        params: { tokenId },
    },
}) => (
    <View>
        <HeaderPage
            heading="hub.resetPassword.title"
            icon="subscribe"
            breadcrumb="app.espace_citoyen.title"
        />

        <Wrapper option={['paddingLarge']}>
            <Card option={['paddingLarge']}>
                <Wrapper option={['small']}>
                    <Content>
                        <ForgotPasswordResetContainer token={tokenId} />
                    </Content>
                </Wrapper>
            </Card>
        </Wrapper>
    </View>
)

export default ResetPasswordView
