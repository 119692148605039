import React from 'react'
import ContentLoader from 'react-content-loader'

import { WorkNewsHeader } from '.'
import { ContentBlocks } from '../../../../components/ContentBlock'

const WorkNewsDetails = ({ workNews }) => {
    return (
        <React.Fragment>
            <WorkNewsHeader workNews={workNews} />
            <ContentBlocks blocks={workNews.attachments} showNav={false} />
        </React.Fragment>
    )
}

WorkNewsDetails.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    ></ContentLoader>
)

export default WorkNewsDetails
