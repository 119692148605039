import { OPEN_LIGHTBOX, CLOSE_LIGHTBOX } from '../actions/lightboxActions'

const initialState = {
    isLightboxOpen: false,
    urlToMediumImageFile: false,
    urlToLargeImageFile: false,
    alt: false,
}

export const lightboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LIGHTBOX:
            return {
                ...state,
                isLightboxOpen: true,
                urlToMediumImageFile: action.urlToMediumImageFile,
                urlToLargeImageFile: action.urlToLargeImageFile,
                alt: action.alt,
            }
        case CLOSE_LIGHTBOX:
            return {
                ...state,
                isLightboxOpen: false,
                urlToMediumImageFile: false,
                urlToLargeImageFile: false,
                alt: false,
            }

        default:
            return state
    }
}
