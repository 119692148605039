// import _ from 'lodash'

// import { FETCH_SERVICE_LIST_SUCCESS } from '../modules/hub/actions/serviceActions'

const initialState = []

export function routes(state = initialState, action) {
    switch (action.type) {
        case 'ADD_ROUTES':
            return [...state, ...action.payload]
        // case FETCH_SERVICE_LIST_SUCCESS:
        //     const serviceRoutes = _.compact(
        //         _.map(services, service => {
        //             const { type: viewKey } = service
        //             const resolvedComponent = resolveMappedComponent(viewKey, viewMap)
        //             if (resolvedComponent === null) {
        //                 return resolvedComponent
        //             }

        //             // Return the view component and its path in the format expected by createRoutes.
        //             // No ID is needed as we're not rendering the route using a magic-matched component.
        //             return {
        //                 component: resolvedComponent,
        //                 path: service.slug,
        //             }
        //         })
        //     )
        //     return state
        default:
            return state
    }
}
