import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { isTranslationObject } from '../../../utils'

import { Wrapper } from '../../Wrapper'
import { ContentBlockHeader } from '../'

import { ContentBlockDocumentFile } from '.'
import { ContentBlockDocumentExternal } from '.'

import './_contentDocument.scss'
import '../_contentBlock.scss'

const ContentBlockDocument = ({ title, files, id }) => (
    <div className={`contentBlock`} id={id}>
        <Wrapper option={['medium']}>
            {title && <ContentBlockHeader title={title} />}

            <ul className="contentDocument">
                {files.map((file, index) => {
                    if (_.isNil(file.file)) {
                        return null
                    }

                    const fileProps = _.omit(file, 'type')

                    if (file.type === 'file') {
                        return <ContentBlockDocumentFile key={index} {...fileProps} fromGallery />
                    } else if (file.type === 'external') {
                        return (
                            <ContentBlockDocumentExternal key={index} {...fileProps} fromGallery />
                        )
                    }
                    return null
                })}
            </ul>
        </Wrapper>
    </div>
)

ContentBlockDocument.propTypes = {
    title: isTranslationObject,
    files: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default ContentBlockDocument
