import React from 'react'

import PropTypes from 'prop-types'
import { Heading } from '../Heading'
import { Translation } from '../Translation'

import { renderClasses } from '../../utils'

import './_card.scss'

const CardHeader = ({ heading, description, children, ...classProps }) => {
    const isSmall = classProps.option ? classProps.option.includes('small') : false

    return (
        <div className={renderClasses('card_header', classProps)}>
            {heading && (
                <>
                    {isSmall ? (
                        <Heading el="h3" option={['h3']}>
                            <Translation value={heading} />
                        </Heading>
                    ) : (
                        <Heading el="h2" option={['h1']}>
                            <Translation value={heading} />
                        </Heading>
                    )}
                </>
            )}

            {description && (
                <p>
                    <Translation value={description} isHtml />
                </p>
            )}

            {children && <div className="card_header_more">{children}</div>}
        </div>
    )
}

CardHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
}

export default CardHeader
