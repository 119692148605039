import moment from 'moment'
import { ONGOING_CONTENT, FINISHED_CONTENT } from '../config'
import { translation } from '.'

export const formatDate = date => {
    return moment(date).format('D MMM YYYY')
}

export const resolveContentStatus = (startDate, endDate) => {
    const currentDate = Date.now()
    // startDate = new Date(startDate)
    endDate = new Date(moment(endDate, 'YYYY-MM-DD HH:mm').toDate())

    if (endDate <= currentDate) {
        return translation(FINISHED_CONTENT)
    }

    return ONGOING_CONTENT
}
