import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { GoogleMap } from '../../../../components/GoogleMap'
import { Translation } from '../../../../components/Translation'
import { ButtonCheckbox } from '../../../../components/Button'
import { Layout, LayoutItem } from '../../../../components/Layout'

const SubscriptionFormZoneDetails = ({
    data,
    defaultSelectedZones = [],
    defaultType = 'polygon',
    sendZones,
}) => {
    const [selectedZones, setSelectedZones] = useState(
        data.map(zone => {
            const isSelected = defaultSelectedZones.indexOf(zone.id) !== -1 ? true : false
            return {
                id: zone.id,
                type: defaultType,
                selected: isSelected,
            }
        })
    )

    const polygonsParking = data.map(zone => zone.geometry)
    const buttons = data.map((zone, index) => {
        return (
            <LayoutItem option={['auto']} key={index}>
                <div className="subcriptionForm_map_button">
                    <ButtonCheckbox
                        status={[selectedZones[index].selected ? 'checked' : '']}
                        onClick={() => triggerZone(defaultType, index)}
                    >
                        <Translation value={zone.name} />
                    </ButtonCheckbox>
                </div>
            </LayoutItem>
        )
    })

    // retrieve clicked from google component map on clicked element
    const retrieveClickedItem = (type, index) => {
        setSelectedItems(type, index)
    }

    // trigger button click
    const triggerZone = (type, index) => {
        setSelectedItems(type, index)
    }

    const setSelectedItems = (type, index) => {
        let newSelectedZone = selectedZones[index]
        let newAllSelectedZones = selectedZones

        newSelectedZone.selected = !selectedZones[index].selected
        newAllSelectedZones[index] = newSelectedZone

        setSelectedZones([...newAllSelectedZones])
        sendZones([...newAllSelectedZones])
    }

    return (
        <div className="subcriptionForm_map">
            <div className="subcriptionForm_map_main">
                <GoogleMap
                    polygons={polygonsParking}
                    isClickable
                    sendClickedItem={retrieveClickedItem}
                    selectedItems={selectedZones}
                    displayPlaceholder={false}
                />
            </div>
            <div className="subcriptionForm_map_buttons">
                <Layout option={['gutterSmall']}>{buttons}</Layout>
            </div>
        </div>
    )
}

SubscriptionFormZoneDetails.propTypes = {
    data: PropTypes.array.isRequired,
    defaultSelectedZones: PropTypes.array,
    defaultType: PropTypes.string,
    sendZones: PropTypes.func.isRequired,
}

export default SubscriptionFormZoneDetails
