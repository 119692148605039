import _ from 'lodash'
import { renderToStaticMarkup } from 'react-dom/server'
import {
    addTranslationForLanguage,
    getTranslate,
    getActiveLanguage,
    initialize,
} from 'react-localize-redux'

import { getStore } from '../store'
import enTranslations from '../translations/en.json'
import frTranslations from '../translations/fr.json'

export const translation = data => {
    const localizeState = getStore().getState().localize
    const { code: lang } = getActiveLanguage(localizeState)
    if (typeof data === 'object') {
        return data[lang]
    }

    const translate = getTranslate(localizeState)
    return translate(data)
}

export const setupLocalization = (history, store, modules, shouldDispatch = true) => {
    const languages = process.env.REACT_APP_LOCALE_LIST.split(',')
    const localeSlug =
        (function() {
            const slug = history.location.pathname.split('/')[1]
            return _.isEmpty(slug) || !languages.includes(slug) ? null : slug
        })() || process.env.REACT_APP_LOCALE_DEFAULT

    if (shouldDispatch) {
        store.dispatch(
            initialize({
                languages,
                options: {
                    defaultLanguage: localeSlug,
                    renderToStaticMarkup,
                },
            })
        )

        let translations = {
            en: enTranslations,
            fr: frTranslations,
        }

        // Loop the supported modules...
        for (let module of modules) {
            // ...to extract their translations...
            _.merge(translations, module.translations)
        }
        // ...add then add them to the localization store.
        store.dispatch(addTranslationForLanguage(translations.en, 'en'))
        store.dispatch(addTranslationForLanguage(translations.fr, 'fr'))
    }

    return {
        localeSlug,
    }
}
