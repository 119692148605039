import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/subscriptions`

/**
 * Delete the authenticated user's memo subscription.
 */
export const DELETE_USER_SUBSCRIPTION_SELF_REQUEST = 'DELETE_USER_SUBSCRIPTION_SELF_REQUEST'
export const DELETE_USER_SUBSCRIPTION_SELF_SUCCESS = 'DELETE_USER_SUBSCRIPTION_SELF_SUCCESS'
export const DELETE_USER_SUBSCRIPTION_SELF_FAILURE = 'DELETE_USER_SUBSCRIPTION_SELF_FAILURE'
export const deleteSelfSubscription = (config = {}) => {
    return {
        promise: client => client.delete(`${baseUrl}/self`, config),
        types: [
            DELETE_USER_SUBSCRIPTION_SELF_REQUEST,
            DELETE_USER_SUBSCRIPTION_SELF_SUCCESS,
            DELETE_USER_SUBSCRIPTION_SELF_FAILURE,
        ],
    }
}

/**
 * Retrieve the authenticated user's memo subscription.
 */
export const FETCH_USER_SUBSCRIPTION_SELF_REQUEST = 'FETCH_USER_SUBSCRIPTION_SELF_REQUEST'
export const FETCH_USER_SUBSCRIPTION_SELF_SUCCESS = 'FETCH_USER_SUBSCRIPTION_SELF_SUCCESS'
export const FETCH_USER_SUBSCRIPTION_SELF_FAILURE = 'FETCH_USER_SUBSCRIPTION_SELF_FAILURE'
export const fetchSelfSubscription = (config = {}) => {
    return {
        promise: client => client.get(`${baseUrl}/self`, config),
        types: [
            FETCH_USER_SUBSCRIPTION_SELF_REQUEST,
            FETCH_USER_SUBSCRIPTION_SELF_SUCCESS,
            FETCH_USER_SUBSCRIPTION_SELF_FAILURE,
        ],
    }
}

/**
 * Update the authenticated user's memo subscription.
 */
export const UPDATE_USER_SUBSCRIPTION_SELF_REQUEST = 'UPDATE_USER_SUBSCRIPTION_SELF_REQUEST'
export const UPDATE_USER_SUBSCRIPTION_SELF_SUCCESS = 'UPDATE_USER_SUBSCRIPTION_SELF_SUCCESS'
export const UPDATE_USER_SUBSCRIPTION_SELF_FAILURE = 'UPDATE_USER_SUBSCRIPTION_SELF_FAILURE'
export const updateSelfSubscription = (formData = {}, config = {}) => {
    return {
        promise: client => client.patch(`${baseUrl}/self`, formData, config),
        types: [
            UPDATE_USER_SUBSCRIPTION_SELF_REQUEST,
            UPDATE_USER_SUBSCRIPTION_SELF_SUCCESS,
            UPDATE_USER_SUBSCRIPTION_SELF_FAILURE,
        ],
    }
}

/**
 * Create new memo subscription.
 */
export const createSubscription = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}`, formData, config)
}

/**
 * Check duplication subscription email
 */
export const checkDuplicate = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/check-duplicate`, formData, config)
}
