import { LOCATION_CHANGE } from 'connected-react-router'

import { OPEN_SIDEBAR, CLOSE_SIDEBAR, TOGGLE_SIDEBAR } from '../actions/sidebarActions'

const initialState = {
    isSidebarOpen: false,
}

export const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: true,
            }

        case CLOSE_SIDEBAR:
        case LOCATION_CHANGE:
            return {
                ...state,
                isSidebarOpen: false,
            }

        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            }

        default:
            return state
    }
}
