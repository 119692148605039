import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import Slider from 'react-slick'
import { openSidebar } from '../../../../actions/sidebarActions'

import { Icon } from '../../../../components/Icon'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { Link } from '../../../../components/Link'
import { Heading } from '../../../../components/Heading'

import './_onboarding.scss'

const Onboarding = ({ onboardingViewed = () => {}, openSidebar }) => {
    const refSlider = useRef(null)
    const [isLast, setIsLast] = useState(false)

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => {
            setIsLast(newIndex === refSlider.current.props.children.length - 1 ? true : false)
        },
    }

    useEffect(() => {
        return () => {
            if (isLast) {
                onboardingViewed()
            }
        }
    }, [onboardingViewed, isLast])

    const slides = [
        {
            title: <Translation value="hub.onboarding.slider.slides.1.title" />,
            content: <Translation value="hub.onboarding.slider.slides.1.content" />,
            image: '/assets/images/onboarding/agora-slide-1.png',
            mobileImage: '/assets/images/onboarding/mobile/agora-slide-1.png',
        },
        {
            title: <Translation value="hub.onboarding.slider.slides.2.title" />,
            content: <Translation value="hub.onboarding.slider.slides.2.content" />,
            image: '/assets/images/onboarding/agora-slide-2.png',
            mobileImage: '/assets/images/onboarding/mobile/agora-slide-2.png',
        },
        {
            title: <Translation value="hub.onboarding.slider.slides.3.title" />,
            content: <Translation value="hub.onboarding.slider.slides.3.content" />,
            image: '/assets/images/onboarding/agora-slide-3.png',
            mobileImage: '/assets/images/onboarding/mobile/agora-slide-3.png',
        },
        {
            title: <Translation value="hub.onboarding.slider.slides.4.title" />,
            content: <Translation value="hub.onboarding.slider.slides.4.content" />,
            image: '/assets/images/onboarding/agora-slide-4.png',
            mobileImage: '/assets/images/onboarding/mobile/agora-slide-4.png',
        },
        {
            title: <Translation value="hub.onboarding.slider.slides.5.title" />,
            content: <Translation value="hub.onboarding.slider.slides.5.content" />,
            image: '/assets/images/onboarding/agora-slide-5.png',
            mobileImage: '/assets/images/onboarding/mobile/agora-slide-5.png',
        },
    ]

    return (
        <div className={'onboarding' + (isLast ? ' is-last' : '')}>
            <Link className="onboarding_logo" route="home">
                <Icon name="logo" utility={['white']} />
            </Link>

            <div className="onboarding_login">
                <Button
                    option={['white', 'circleIcon', 'iconRight']}
                    icon={'person'}
                    onClick={() => {
                        onboardingViewed()
                        openSidebar()
                    }}
                >
                    <Translation value="hub.loginForm.submit" />
                </Button>
            </div>

            <Slider className="onboarding_carousel" ref={refSlider} {...settings}>
                {slides.map((item, index) => {
                    return (
                        <div
                            className={
                                'onboarding_panel' + (index === slides.length - 1 ? ' -center' : '')
                            }
                            key={index}
                        >
                            <div className="onboarding_panel_inner">
                                <div className="onboarding_panel_image-wrap">
                                    <img
                                        className="onboarding_panel_image -mobile"
                                        src={item.mobileImage}
                                        alt=""
                                    />
                                </div>

                                <div className="onboarding_panel_text">
                                    {item.title && (
                                        <Heading option={['h2']} el="h1">
                                            {item.title}
                                        </Heading>
                                    )}

                                    <p>{item.content}</p>
                                </div>

                                <img className="onboarding_panel_image" src={item.image} alt="" />
                            </div>
                        </div>
                    )
                })}
            </Slider>

            <div className="onboarding_buttons">
                {isLast ? (
                    <div className="onboarding_panel_buttonSignUp">
                        <Button
                            option={['green', 'icon', 'iconLeft']}
                            icon="check"
                            route="hub-subscription"
                        >
                            <Translation value={'hub.onboarding.signUp.title'} />
                        </Button>
                    </div>
                ) : (
                    <Button
                        option={['green', 'icon', 'iconLeft']}
                        icon="arrow-next-down"
                        onClick={e => refSlider.current.slickNext()}
                    >
                        <Translation value={'hub.onboarding.slider.next.title'} />
                    </Button>
                )}

                <button className="onboarding_buttons_back" onClick={e => onboardingViewed()}>
                    <Translation value={'hub.onboarding.back.title'} />
                </button>
            </div>
        </div>
    )
}

Onboarding.propTypes = {
    onboardingViewed: PropTypes.func,
}

Onboarding.Placeholder = () => <ContentLoader></ContentLoader>

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(null, mapDispatchToProps)(Onboarding)
