import { apiClient } from '../../../../actions'

import { inquiryBaseUrl } from '../inquiryActions'

const baseUrl = `${inquiryBaseUrl}/categories`

/**
 * Retrieve contact categories listing.
 */
export const fetchCategories = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve contact category.
 *
 * NOT USED
export const fetchCategory = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
*/
