/* global google */
import React, { Fragment, useState /*, useCallback, useEffect */ } from 'react'
import PropTypes from 'prop-types'
import { getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'

import { GoogleMap as ReactGoogleMap, useLoadScript } from '@react-google-maps/api'

import _ from 'lodash'

import { setCoordsFromData } from '../../utils'
import { GoogleMapPolygon, GoogleMapPolyline, GoogleMapPoint } from '.'

import { Button } from '../Button'
import { Translation } from '../Translation'
import { Spinner } from '../Spinner'

import './_googleMap.scss'

const GoogleMap = ({
    mapPlaceholder,
    displayPlaceholder = true,
    lang,
    mapCenter,
    defaultMapCenter,
    zoom = 15,
    isClickable = false,
    polygons = [],
    polylines = [],
    points = [],
    sendClickedItem = false,
    selectedItems = false,
    defaultFitBounds = true,
}) => {
    const { isLoaded /*loadError*/ } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
        language: lang,
    })

    const currentMapCenter = mapCenter || defaultMapCenter
    const defaultCenterCoords = setCoordsFromData([currentMapCenter])[0]

    const polygonsCoords = setCoordsFromData(polygons)
    const polylinesCoords = setCoordsFromData(polylines)
    const pointsCoords = setCoordsFromData(points)

    // Re-center map when resizing the window
    const bindResizeListener = (map, bounds) => {
        google.maps.event.addDomListenerOnce(map, 'idle', () => {
            google.maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds)
            })
        })
    }

    // Iterate all geometry data to size, center, and zoom map to contain all markers
    const fitBounds = map => {
        if (defaultFitBounds) {
            const places = _.flattenDeep([polygonsCoords, polylinesCoords, [pointsCoords]])
            if (_.isEmpty(places)) {
                return
            }
            const bounds = new google.maps.LatLngBounds()
            places.map(place => {
                bounds.extend(place)
                return place
            })
            map.fitBounds(bounds)
            bindResizeListener(map, bounds)
        }
    }

    const isSelectedItemFromType = (type, index) => {
        return _.isArray(selectedItems) &&
            selectedItems[index].type === type &&
            selectedItems[index].selected
            ? true
            : false
    }

    const [hasLoaded, setHasLoaded] = useState(!displayPlaceholder)
    if (!hasLoaded) {
        return (
            <div
                className="googleMap_preload"
                style={{ backgroundImage: `url('${mapPlaceholder}')` }}
            >
                <div className="googleMap_preload_inner">
                    <Button onClick={() => setHasLoaded(true)}>
                        <Translation value="map.button_load" />
                    </Button>
                </div>
            </div>
        )
    }

    return !isLoaded ? (
        <div className="googleMap_preload_inner">
            <Spinner />
        </div>
    ) : (
        <Fragment>
            <ReactGoogleMap
                id="agora-google-map"
                mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                    // position: 'absolute'
                }}
                onLoad={fitBounds}
                zoom={zoom}
                // only set center if set in props
                center={!isClickable ? defaultCenterCoords : null}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    styles: [
                        {
                            featureType: 'all',
                            elementType: 'all',
                            stylers: [
                                {
                                    saturation: '32',
                                },
                                {
                                    lightness: '-3',
                                },
                                {
                                    visibility: 'on',
                                },
                                {
                                    weight: '1.18',
                                },
                            ],
                        },
                        {
                            featureType: 'all',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'on',
                                },
                            ],
                        },
                        {
                            featureType: 'administrative',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'on',
                                },
                            ],
                        },
                        {
                            featureType: 'landscape',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'on',
                                },
                            ],
                        },
                        {
                            featureType: 'landscape.man_made',
                            elementType: 'all',
                            stylers: [
                                {
                                    saturation: '-70',
                                },
                                {
                                    lightness: '14',
                                },
                            ],
                        },
                        {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'poi.park',
                            elementType: 'geometry.fill',
                            stylers: [
                                {
                                    color: '#7fe0b9',
                                },
                            ],
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'on',
                                },
                            ],
                        },
                        {
                            featureType: 'transit',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'water',
                            elementType: 'all',
                            stylers: [
                                {
                                    saturation: '100',
                                },
                                {
                                    lightness: '-14',
                                },
                            ],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry.fill',
                            stylers: [
                                {
                                    color: '#4071dc',
                                },
                            ],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                                {
                                    lightness: '12',
                                },
                            ],
                        },
                    ],
                }}
            >
                {!_.isEmpty(polygonsCoords) &&
                    polygonsCoords.map((data, index) => {
                        const isSelected = isSelectedItemFromType('polygon', index)
                        return (
                            <GoogleMapPolygon
                                key={index}
                                index={index}
                                paths={data}
                                isClickable={isClickable}
                                selectPolygon={index => sendClickedItem('polygon', index)}
                                isSelectedItem={isSelected}
                            />
                        )
                    })}

                {!_.isEmpty(polylinesCoords) &&
                    polylinesCoords.map((data, index) => {
                        const isSelected = isSelectedItemFromType('polyline', index)
                        return (
                            <GoogleMapPolyline
                                key={index}
                                index={index}
                                paths={data}
                                isClickable={isClickable}
                                selectPolyline={index => sendClickedItem('polyline', index)}
                                isSelectedItem={isSelected}
                            />
                        )
                    })}

                {!_.isEmpty(pointsCoords) &&
                    pointsCoords.map((data, index) => (
                        <GoogleMapPoint
                            key={index}
                            index={index}
                            path={data}
                            isClickable={isClickable}
                            selectPolyline={index => sendClickedItem('point', index)}
                        />
                    ))}
            </ReactGoogleMap>
        </Fragment>
    )
}

GoogleMap.propTypes = {
    lang: PropTypes.string.isRequired,
    mapPlaceholder: PropTypes.string,
    mapCenter: PropTypes.array,
    defaultMapCenter: PropTypes.array.isRequired,
    zoom: PropTypes.number,
    isClickable: PropTypes.bool,
    polygons: PropTypes.array,
    polylines: PropTypes.array,
    points: PropTypes.array,
    sendClickedItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    selectedItems: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
}

const mapStateToProps = state => ({
    mapPlaceholder: state.hub.hubSettings.entities.mapPlaceholder,
    defaultMapCenter: state.hub.hubSettings.entities.map.defaultCenter,
    lang: getActiveLanguage(state.localize).code,
})

export default connect(mapStateToProps)(GoogleMap)
