import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../Translation'
import { isTranslationObject } from '../../utils'

import { Wrapper } from '../../components/Wrapper'
import { Content } from '../../components/Content'
import { ContentBlockHeader } from '.'

import './_contentBlock.scss'

const ContentBlockText = ({ title, description, id }) => (
    <div className="contentBlock" id={id}>
        <Wrapper option={['medium']}>
            {title && <ContentBlockHeader title={title} option={['border']} />}

            {description && (
                <Content>
                    <Translation isHtml value={description} />
                </Content>
            )}
        </Wrapper>
    </div>
)

ContentBlockText.propTypes = {
    title: isTranslationObject,
    description: isTranslationObject,
    id: PropTypes.string,
}

export default ContentBlockText
