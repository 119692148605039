import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { FilterButtons } from '../../../../components/FilterButtons'
import { FilterButtonsItem } from '../../../../components/FilterButtons'
import { isTranslationObject, localizePath, findServiceByType } from '../../../../utils'

import { SERVICE_IDEA_IDEAS } from '../../config'

const IdeaFilterPicker = ({ baseUrl, serviceIdeas = false, serviceIdeasSubmit = false }) => {
    if (!serviceIdeas && !serviceIdeasSubmit) {
        return
    }

    return (
        <FilterButtons>
            {serviceIdeas && (
                <FilterButtonsItem
                    href={localizePath(serviceIdeas.slug)}
                    label={serviceIdeas.name}
                    selected={baseUrl === serviceIdeas.slug}
                />
            )}

            {serviceIdeasSubmit && (
                <FilterButtonsItem
                    href={localizePath(serviceIdeasSubmit.slug)}
                    label={serviceIdeasSubmit.name}
                    selected={baseUrl === serviceIdeasSubmit.slug}
                />
            )}
        </FilterButtons>
    )
}

IdeaFilterPicker.propTypes = {
    baseUrl: isTranslationObject,
    serviceIdeas: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    serviceIdeasSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

const mapStateToProps = state => ({
    serviceIdeas: findServiceByType(state.hub.services.entities, SERVICE_IDEA_IDEAS),
    serviceIdeasSubmit: findServiceByType(
        state.hub.services.entities,
        'city/idea/service/ideas/submit'
    ),
})

export default connect(mapStateToProps)(IdeaFilterPicker)
