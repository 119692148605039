import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

const FormLabel = ({ children, inputId = null, ...classProps }) => (
    <label className={renderClasses('form_label', classProps)} htmlFor={inputId}>
        {children}
    </label>
)

FormLabel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    inputId: PropTypes.string,
}

export default FormLabel
