import React from 'react'

import PropTypes from 'prop-types'
import { Translation } from '../Translation'
// import { Card } from '../Card'
// import { Icon } from '../Icon'
import { Heading } from '../Heading'

import { renderClasses } from '../../utils'

import './_cardAlert.scss'

const CardAlert = ({ children, icon, heading, description, ...classProps }) => {
    return (
        <div className={renderClasses('cardAlert', classProps)}>
            <div className="cardAlert_wrapper">
                <Heading option={['h3']}>
                    <Translation value={heading} />
                </Heading>
                <p className="cardAlert_description">
                    <Translation value={description} />
                </p>
                {children}
            </div>
        </div>
    )
}

CardAlert.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default CardAlert
