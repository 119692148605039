import ContactModule from './modules/contact'
import ContentModule from './modules/content'
import HubModule from './modules/hub'
import MemoModule from './modules/memo'
import ProjectModule from './modules/project'
import SurveyModule from './modules/survey'
import IdeaModule from './modules/idea'
import ParkingModule from './modules/parking'
import PaymentModule from './modules/payment'
import PermitModule from './modules/permit'

/**
 * Define all the modules that are supported by the app.
 *
 * @type {Array}
 */
const modules = [
    ContactModule,
    ContentModule,
    HubModule,
    MemoModule,
    ProjectModule,
    SurveyModule,
    IdeaModule,
    ParkingModule,
    PaymentModule,
    PermitModule,
]
// const modules = []
export default modules
