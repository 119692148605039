import moment from 'moment'

import {
    FETCH_WORK_SUBSCRIPTION_SELF_REQUEST,
    FETCH_WORK_SUBSCRIPTION_SELF_SUCCESS,
    FETCH_WORK_SUBSCRIPTION_SELF_FAILURE,
    CREATE_WORK_SUBSCRIPTION_REQUEST,
    CREATE_WORK_SUBSCRIPTION_SUCCESS,
    CREATE_WORK_SUBSCRIPTION_FAILURE,
    UPDATE_WORK_SUBSCRIPTION_REQUEST,
    UPDATE_WORK_SUBSCRIPTION_SUCCESS,
    UPDATE_WORK_SUBSCRIPTION_FAILURE,
    UPDATE_WORK_SUBSCRIPTION_PENDING,
    DELETE_WORK_SUBSCRIPTION_REQUEST,
    DELETE_WORK_SUBSCRIPTION_SUCCESS,
    DELETE_WORK_SUBSCRIPTION_FAILURE,
    DELETE_WORK_SUBSCRIPTION_PENDING,
} from '../actions/work/subscriptionActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function subscriptions(state = initialState, action) {
    switch (action.type) {
        /**
         * Retrieve the authenticated user's subscription's votes.
         */
        case FETCH_WORK_SUBSCRIPTION_SELF_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_WORK_SUBSCRIPTION_SELF_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_WORK_SUBSCRIPTION_SELF_FAILURE:
            return {
                entities: [],
            }

        /**
         * Create a new work's subscription
         */
        case CREATE_WORK_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case CREATE_WORK_SUBSCRIPTION_SUCCESS:
            // update entities by append subscription to existing subscription entities
            return {
                ...state,
                entities: [...state.entities, action.result.data],
            }
        case CREATE_WORK_SUBSCRIPTION_FAILURE:
            return {
                ...state,
            }

        /**
         * Update the existing work's subscription
         */
        case UPDATE_WORK_SUBSCRIPTION_REQUEST:
            return {
                ...state,
            }
        case UPDATE_WORK_SUBSCRIPTION_SUCCESS:
            // update entities by replacing subscription object in existing vote entities
            const subscriptions = state.entities.map(entity => {
                if (entity.id === action.result.data.id) {
                    return action.result.data
                }
                return entity
            })

            return {
                ...state,
                entities: subscriptions,
            }
        case UPDATE_WORK_SUBSCRIPTION_FAILURE:
        case UPDATE_WORK_SUBSCRIPTION_PENDING:
            return {
                ...state,
            }

        /**
         * Delete the existing work's subscription
         */
        case DELETE_WORK_SUBSCRIPTION_REQUEST:
            return {
                ...state,
            }
        case DELETE_WORK_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
            }
        case DELETE_WORK_SUBSCRIPTION_FAILURE:
        case DELETE_WORK_SUBSCRIPTION_PENDING:
            return {
                ...state,
            }

        default:
            return state
    }
}
