import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/onboarding-panels`

/**
 * Retrieve a on boarding boards.
 */
export const fetchOnboardingPanels = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

export const FETCH_ONBOARDING_SELF_REQUEST = 'FETCH_ONBOARDING_SELF_REQUEST'
export const fetchOnboardingSelf = () => {
    return { type: FETCH_ONBOARDING_SELF_REQUEST }
}
