import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../components/Card'
import { Translation } from '../../../../components/Translation'
import { Content } from '../../../../components/Content'
import { Heading } from '../../../../components/Heading'

import './_faqQuestion.scss'

const FaqQuestion = ({ item, category, selectedCategory = false }) => {
    const [isOpen, setIsOpen] = useState(false)

    if (selectedCategory !== 'all' && selectedCategory !== item.category) {
        return null
    }

    return (
        <Card className={'faqQuestion' + (isOpen ? ' is-open' : '')} option={['paddingLarge']}>
            {item.answer && (
                <button
                    className="faqQuestion_button"
                    type="button"
                    onClick={e => setIsOpen(!isOpen)}
                    tabIndex="-1"
                >
                    <span className="faqQuestion_button_plus"></span>
                </button>
            )}

            <div className="faqQuestion_inner">
                <button
                    className="faqQuestion_title"
                    onClick={e => setIsOpen(!isOpen)}
                    type="button"
                >
                    <Heading el="h3" option={['h3']}>
                        <Translation value={item.question} />
                    </Heading>
                </button>

                {item.answer && (
                    <div className="faqQuestion_content">
                        <Content>
                            <Translation isHtml value={item.answer} />
                        </Content>
                    </div>
                )}
            </div>
        </Card>
    )
}

FaqQuestion.propTypes = {
    item: PropTypes.object.isRequired,
    category: PropTypes.object,
    selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default FaqQuestion
