import React from 'react'

import { Translation } from '../../../../../components/Translation'
import { Card } from '../../../../../components/Card'
import { Icon } from '../../../../../components/Icon'
import { Heading } from '../../../../../components/Heading'
import { Button } from '../../../../../components/Button'
import { isTranslationObject, translation } from '../../../../../utils'

import './_pageCard.scss'

const PageCard = ({ icon, title, summary, slug }) => (
    <article className="pageCard">
        <Card href={translation(slug)}>
            <div>
                <header className="pageCard_header">
                    <div className="pageCard_icon">
                        <Icon name="reglements" />
                    </div>
                    <Heading option={['h3']}>
                        <Translation value={title} />
                    </Heading>
                </header>
                {summary && (
                    <div className="pageCard_description">
                        <Translation value={summary} isHtml />
                    </div>
                )}
            </div>
            <div className="pageCard_button">
                <Button option={['blue']}>
                    <Translation value="content.page.learnMore" />
                </Button>
            </div>
        </Card>
    </article>
)

PageCard.propTypes = {
    title: isTranslationObject,
    summary: isTranslationObject,
    slug: isTranslationObject,
}

export default PageCard
