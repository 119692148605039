import React from 'react'

import PropTypes from 'prop-types'

import './_cardWrapper.scss'

const CardWrapper = ({ children }) => <div className="cardWrapper">{children}</div>

CardWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default CardWrapper
