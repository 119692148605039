import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { ContentBlockAccordionPanel } from '.'
import { Wrapper } from '../../../components/Wrapper'
import { ContentBlockHeader } from '../'

import { isTranslationObject } from '../../../utils'

import './_contentAccordion.scss'
import '../_contentBlock.scss'

const ContentBlockAccordion = ({ title, content, id }) => (
    <div className={`contentBlock`} id={id}>
        <Wrapper option={['medium']}>
            {title && <ContentBlockHeader title={title} />}

            <ul className="contentAccordion">
                {content.map((panel, index) => {
                    const panelProps = _.omit(panel, 'type')
                    if (panel.type === 'accordion-panel') {
                        return <ContentBlockAccordionPanel key={index} {...panelProps} />
                    }
                    return null
                })}
            </ul>
        </Wrapper>
    </div>
)

ContentBlockAccordion.propTypes = {
    title: isTranslationObject,
    content: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default ContentBlockAccordion
