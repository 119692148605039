import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { renderRoute } from '../../../utils'

export const userIsAuthenticatedRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => state.hub.auth.isAuthenticated,
    redirectPath: () => renderRoute('home'),
    wrapperDisplayName: 'UserIsAuthenticated',
})

export const userIsAuthenticatedSubscriptionRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => !state.hub.auth.isAuthenticated,
    redirectPath: () => renderRoute('hub-edit-profile'),
    wrapperDisplayName: 'UserIsAuthenticatedSubscription',
})

export const userIsNotAuthenticatedRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => !state.hub.auth.isAuthenticated,
    redirectPath: () => renderRoute('home'),
    wrapperDisplayName: 'UserIsNotAuthenticated',
})
