import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Helmet } from 'react-helmet-async'

import { FINISHED_CONTENT } from '../../../../config'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { ProgressBar } from '../../../../components/ProgressBar'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Wrapper } from '../../../../components/Wrapper'
import { isTranslationObject, translation } from '../../../../utils'

import { findServiceByType, resolveServiceLink } from '../../../../utils'

import { SERVICE_SURVEY_SURVEYS } from '../../config'

import { StatisticQuestion } from '../Statistic'

import avatar from '../../../../assets/images/avatar.svg'

const SurveyResultDetails = ({ survey, services }) => {
    const [currentStep, setCurrentStep] = useState(0)
    const totalSteps = survey.statistics.length

    const questionStat = survey.statistics[currentStep]
    const displayPrev = currentStep === 0 ? false : true
    const displayNext = currentStep === totalSteps - 1 ? false : true
    const surveyListService = findServiceByType(services, SERVICE_SURVEY_SURVEYS)
    const { url } = resolveServiceLink(surveyListService)

    const prevStep = () => {
        setCurrentStep(currentStep - 1)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const nextStep = () => {
        setCurrentStep(currentStep + 1)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div className="survey">
            <Helmet>
                <title itemProp="name">{translation(survey.title)}</title>
                {/*<meta name="description" content={translation(survey.description)} />*/}
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={survey.startDate} />
                <meta property="article:modified_time" content={survey.lastModified} />
                <meta property="article:expiration_time" content={survey.endDate} />
            </Helmet>
            <ProgressBar
                option={['fixed']}
                step={currentStep}
                total={totalSteps}
                data="survey.details.title"
            />

            <div className="survey_close">
                <Button option={['circle', 'white']} icon="close" href={url} />
            </div>
            <div className="survey_main -intro">
                <div className="survey_background"></div>

                <div className="question">
                    <Wrapper option={['small']}>
                        <p className="question_title">
                            <Translation value="survey.details.results.title" />
                        </p>
                        <div className="question_wrap">
                            <div className="question_avatar">
                                <img src={avatar} alt="Agora" />
                            </div>
                            <TransitionGroup>
                                <CSSTransition
                                    key={questionStat.id}
                                    classNames="has-transition"
                                    timeout={300}
                                    appear
                                    exit
                                >
                                    <div className="question_bubble">
                                        <Translation value={questionStat.title} isHtml />
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </Wrapper>
                </div>
                <StatisticQuestion data={questionStat} />

                <Layout option={['gutterSmall']}>
                    {displayPrev && (
                        <LayoutItem option={['auto']}>
                            <Button onClick={prevStep}>
                                <Translation value="survey.details.previous.title" />
                            </Button>
                        </LayoutItem>
                    )}
                    {displayNext && (
                        <LayoutItem option={['auto']}>
                            <Button onClick={nextStep}>
                                <Translation value="survey.details.next.title" />
                            </Button>
                        </LayoutItem>
                    )}
                    {currentStep + 1 === totalSteps && (
                        <LayoutItem option={['auto']}>
                            <Button href={`${url}?status=${translation(FINISHED_CONTENT)}`}>
                                <Translation value="survey.details.results.back" />
                            </Button>
                        </LayoutItem>
                    )}
                </Layout>
            </div>
        </div>
    )
}

SurveyResultDetails.NotAvailable = () => {
    return (
        <div>
            <Translation value="survey.details.not_finished.description" />
        </div>
    )
}

const mapStateToProps = state => ({
    services: state.hub.services.entities,
})

SurveyResultDetails.propTypes = {
    survey: PropTypes.shape({
        title: isTranslationObject,
        statistics: PropTypes.array.isRequired,
    }).isRequired,
}

SurveyResultDetails.Placeholder = () => <ContentLoader></ContentLoader>

export default connect(mapStateToProps)(SurveyResultDetails)
