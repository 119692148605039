import { API_URL } from '../config'

const baseUrl = `${API_URL}/ideas/votes`

/**
 * Create a new ideas's vote.
 */
export const CREATE_IDEA_VOTE_REQUEST = 'CREATE_IDEA_VOTE_REQUEST'
export const CREATE_IDEA_VOTE_SUCCESS = 'CREATE_IDEA_VOTE_SUCCESS'
export const CREATE_IDEA_VOTE_FAILURE = 'CREATE_IDEA_VOTE_FAILURE'
export const createVote = (formData = {}, config = {}) => {
    return {
        promise: client => client.post(`${baseUrl}`, formData, config),
        types: [CREATE_IDEA_VOTE_REQUEST, CREATE_IDEA_VOTE_SUCCESS, CREATE_IDEA_VOTE_FAILURE],
    }
}

/**
 * Retrieve the authenticated user's idea's votes.
 */
export const FETCH_IDEA_VOTE_SELF_REQUEST = 'FETCH_IDEA_VOTE_SELF_REQUEST'
export const FETCH_IDEA_VOTE_SELF_SUCCESS = 'FETCH_IDEA_VOTE_SELF_SUCCESS'
export const FETCH_IDEA_VOTE_SELF_FAILURE = 'FETCH_IDEA_VOTE_SELF_FAILURE'
export const fetchSelf = (config = {}) => {
    return {
        promise: client => client.get(`${baseUrl}/self`, config),
        types: [
            FETCH_IDEA_VOTE_SELF_REQUEST,
            FETCH_IDEA_VOTE_SELF_SUCCESS,
            FETCH_IDEA_VOTE_SELF_FAILURE,
        ],
    }
}

/**
 * Update an exisiting idea's vote.
 */
export const UPDATE_IDEA_VOTE_REQUEST = 'UPDATE_IDEA_VOTE_REQUEST'
export const UPDATE_IDEA_VOTE_SUCCESS = 'UPDATE_IDEA_VOTE_SUCCESS'
export const UPDATE_IDEA_VOTE_FAILURE = 'UPDATE_IDEA_VOTE_FAILURE'
export const UPDATE_IDEA_VOTE_PENDING = 'UPDATE_IDEA_VOTE_PENDING'
export const updateVote = (formData = {}, config = {}) => {
    return {
        promise: client => client.patch(`${baseUrl}/self`, formData, config),
        types: [
            UPDATE_IDEA_VOTE_REQUEST,
            UPDATE_IDEA_VOTE_SUCCESS,
            UPDATE_IDEA_VOTE_FAILURE,
            UPDATE_IDEA_VOTE_PENDING,
        ],
    }
}

export const FETCH_IDEA_VOTE_ANONYMOUS_REQUEST = 'FETCH_IDEA_VOTE_ANONYMOUS_REQUEST'
export const fetchAnonymousVotes = () => {
    return { type: FETCH_IDEA_VOTE_ANONYMOUS_REQUEST }
}
