import React from 'react'

import { View } from '../../../components/View'

import { WorkNewsDetailsContainer } from '../components/WorkNews'

const WorkNewsDetailsView = ({ location }) => (
    <View>
        <WorkNewsDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default WorkNewsDetailsView
