import moment from 'moment'

import {
    FETCH_HUB_SETTINGS_REQUEST,
    FETCH_HUB_SETTINGS_SUCCESS,
    FETCH_HUB_SETTINGS_FAILURE,
} from '../actions/settingActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function hubSettings(state = initialState, action) {
    switch (action.type) {
        case FETCH_HUB_SETTINGS_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_HUB_SETTINGS_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_HUB_SETTINGS_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
