import React from 'react'

import { View } from '../../../components/View'

import { AlertDetailsContainer } from '../components/Alert'

const AlertDetailsView = ({ location }) => (
    <View>
        <AlertDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default AlertDetailsView
