import React, { useCallback } from 'react'
import { SwitchTransition } from 'react-transition-group'
import _ from 'lodash'

import { Fade } from '../../../../components/Fade'
import { NotFound } from '../../../../components/NotFound'
import { useDev, logErrors, useFetchItems } from '../../../../utils'

import { fetchWorkNewsBySlug } from '../../actions/work/workNewsActions'
import { WorkNewsDetails } from '.'

const WorkNewsDetailsContainer = ({ slug }) => {
    // prepare function to retrieve work news by slug
    const useFetchWorkNews = useCallback(() => {
        return fetchWorkNewsBySlug(slug)
    }, [slug])

    const { items, isLoading, requestStatus, error } = useFetchItems(useFetchWorkNews)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <WorkNewsDetails.Placeholder />
                ) : (
                    <WorkNewsDetails workNews={items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default WorkNewsDetailsContainer
