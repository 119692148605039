import React from 'react'

import PropTypes from 'prop-types'

import { translation, formatDate } from '../../../../utils'

import { Card } from '../../../../components/Card'
import { CardLabel } from '../../../../components/Card'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'
import { ContentBlockHeader } from '../../../../components/ContentBlock'

import './_projectNewsCard.scss'

const ProjectNewsCardContainer = ({ news }) => (
    <React.Fragment>
        <ContentBlockHeader title={'project.details.news.title'} option={['border']} />

        {news.map((item, index) => {
            return (
                <Card
                    className="projectNewsCard"
                    option={['paddingLarge']}
                    key={index}
                    href={`/${translation(item.slug)}`}
                >
                    <div className="projectNewsCard_inner">
                        {item.thumbnail && (
                            <div className="projectNewsCard_image">
                                <div
                                    className="projectNewsCard_image_inner"
                                    style={{ backgroundImage: `url(${item.thumbnail})` }}
                                ></div>
                            </div>
                        )}

                        <div className="projectNewsCard_text">
                            <CardLabel option={['blue']}>{formatDate(item.newsDate)}</CardLabel>

                            <Heading option={['h2']}>
                                <Translation value={item.title} />
                            </Heading>

                            {item.summary && (
                                <div className="projectNewsCard_description">
                                    <Translation value={item.summary} />
                                </div>
                            )}

                            <Button>
                                <Translation value={'project.details.news.read_more'} />
                            </Button>
                        </div>
                    </div>
                </Card>
            )
        })}
    </React.Fragment>
)

ProjectNewsCardContainer.propTypes = {
    news: PropTypes.array,
}

export default ProjectNewsCardContainer
