import React from 'react'

import { View } from '../../../components/View'

import { SubscriptionFormContainer } from '../components/SubscriptionForm'

const MemoSubscriptionView = () => (
    <View>
        <SubscriptionFormContainer />
    </View>
)

export default MemoSubscriptionView
