import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import { withLocalize } from 'react-localize-redux'
import axios from 'axios'
import _ from 'lodash'

import { Button } from '../../../../components/Button'
import {
    Form,
    // FormError,
    FormInput,
    FormItem,
    FormLabel,
    FormNotifications,
} from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Spinner } from '../../../../components/Spinner'
import { Heading } from '../../../../components/Heading'
import { formatFormNotifications, verifySameField } from '../../../../utils'
import { resetPassword } from '../../actions/userActions'

const ForgotPasswordResetForm = ({
    form,
    form: { getFieldError, getFieldValue, validateFields },
    openSidebar,
    translate,
    token,
}) => {
    const EMAIL_FIELD = 'email'
    const EMAIL_CONFIRMATION_FIELD = 'emailConfirmation'
    const PASSWORD_FIELD = 'password'
    const PASSWORD_CONFIRMATOIN_FIELD = 'passwordConfirmation'

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (formErrors || isLoading) {
                return false
            }

            setIsLoading(true)
            setErrors([])

            let fields = _.omit(formValues, [EMAIL_CONFIRMATION_FIELD, PASSWORD_CONFIRMATOIN_FIELD])
            fields.tokenId = token

            await resetPassword(token, fields, { cancelToken: signal.token })
                .then(response => {
                    setErrors([])
                    setIsLoading(false)
                    setIsFinished(true)
                })
                .catch(error => {
                    const errorMessage =
                        error.response.status === 401
                            ? 'hub.resetPassword.tokenExpired.title'
                            : error.response.status === 404
                            ? 'hub.resetPassword.errorEmailOrToken.title'
                            : 'hub.error'

                    setErrors([{ message: translate(errorMessage) }])
                    setIsLoading(false)
                })
        })
        return false
    }

    if (isLoading) {
        return <Spinner />
    }

    if (isFinished) {
        return (
            <React.Fragment>
                <div className="u-textCenter">
                    <Heading el="p" option={['h2']}>
                        <Translation value="hub.resetPassword.finish.title" />
                    </Heading>

                    <Button onClick={openSidebar} option={['marginTop']}>
                        <Translation value="hub.loginForm.login" />
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []

    return (
        <Form onSubmit={handleSubmit}>
            {!_.isEmpty(notifications) && (
                <FormItem>
                    <FormNotifications
                        hasErrors={hasErrors}
                        hasSuccess={isFinished}
                        items={notifications}
                    />
                </FormItem>
            )}
            <FormItem>
                <FormLabel inputId={EMAIL_FIELD}>
                    <Translation value="hub.loginForm.email" />
                </FormLabel>
                <FormInput
                    status={[getFieldError(EMAIL_FIELD) && 'error']}
                    form={form}
                    id={EMAIL_FIELD}
                    name={EMAIL_FIELD}
                    options={{
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: translate('hub.loginForm.emailMissing'),
                            },
                        ],
                    }}
                    type="email"
                />
                {/*<FormError>{getFieldError(EMAIL_FIELD)}</FormError>*/}
            </FormItem>
            <FormItem>
                <FormLabel inputId={PASSWORD_FIELD}>
                    <Translation value="hub.resetPassword.passwordField.title" />
                </FormLabel>
                <FormInput
                    status={[getFieldError(PASSWORD_FIELD) && 'error']}
                    form={form}
                    id={PASSWORD_FIELD}
                    name={PASSWORD_FIELD}
                    options={{
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: translate('hub.subscription.errors.required'),
                            },
                        ],
                    }}
                    type="password"
                />
                {/*<FormError>{getFieldError(PASSWORD_FIELD)}</FormError>*/}
            </FormItem>
            <FormItem>
                <FormLabel inputId={PASSWORD_CONFIRMATOIN_FIELD}>
                    <Translation value="hub.resetPassword.passwordField.confirmation" />
                </FormLabel>
                <FormInput
                    status={[getFieldError(PASSWORD_CONFIRMATOIN_FIELD) && 'error']}
                    form={form}
                    id={PASSWORD_CONFIRMATOIN_FIELD}
                    name={PASSWORD_CONFIRMATOIN_FIELD}
                    options={{
                        initialValue: '',
                        rules: [
                            {
                                message: translate('hub.subscription.password.errorSame'),
                                validator: (rule, value) =>
                                    verifySameField(value, getFieldValue(PASSWORD_FIELD)),
                            },
                        ],
                    }}
                    type="password"
                />
                {/*<FormError>{getFieldError(PASSWORD_CONFIRMATOIN_FIELD)}</FormError>*/}
            </FormItem>

            <FormItem>
                <Button type="submit">
                    <Translation value="hub.resetPassword.confirm.title" />
                </Button>
            </FormItem>
        </Form>
    )
}

ForgotPasswordResetForm.propTypes = {
    token: PropTypes.string,
    form: formShape,
    openSidebar: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
}

export default withLocalize(createForm()(ForgotPasswordResetForm))
