import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'

import { fetchPageBySlug } from '../../actions/pageActions'
import { useDev, logErrors } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { PageDetails } from './'

const PageDetailsContainer = ({ slug }) => {
    // prepare function to retrieve alert by slug
    const useFetchPage = useCallback(() => {
        return fetchPageBySlug(slug)
    }, [slug])

    const page = useFetchItems(useFetchPage)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, page, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {page.requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <PageDetails.Placeholder />
                ) : (
                    <PageDetails page={page.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default PageDetailsContainer
