import ProjectListView from '../views/ProjectListView'
import WorkListView from '../views/WorkListView'
import { SERVICE_PROJECT_PROJECTS, SERVICE_WORK_WORKS } from '.'

export const componentMap = {}
export const viewMap = {
    'city/project/service/projects': ProjectListView,
    'city/work/service/works': WorkListView,
}

export const routeServiceMap = {
    'project-details': SERVICE_PROJECT_PROJECTS,
    'work-details': SERVICE_WORK_WORKS,
}
