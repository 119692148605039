import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/home-dashboards`

/**
 * Retrieve a home dashboard.
 */
export const fetchHomeDashboard = (dashboardId, config = {}) => {
    return apiClient.get(`${baseUrl}/${dashboardId}`, config)
}
