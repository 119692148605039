import { ConnectedRouter } from 'connected-react-router'
import moment from 'moment'
import 'moment/locale/fr'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import svg4everybody from 'svg4everybody'

import { fetchHubSettings } from './modules/hub/actions/settingActions'
import { fetchServices } from './modules/hub/actions/serviceActions'
import { fetchServiceCategories } from './modules/hub/actions/serviceCategoryActions'
import { fetchOnboardingSelf } from './modules/hub/actions/onboardingActions'
import { AUTH_SET_TOKEN, fetchSelf } from './modules/hub/actions/userActions'
import { App } from './components/App'
import * as serviceWorker from './serviceWorker'
import { getHelmetContext, getHistory, getStore } from './store'
import { setupLocalization, storeStaticRoutes } from './utils'

// Import Modules last because of deep dependencies & imports
import modules from './modules'

// Internet Explorer override
if (window.document.documentMode) {
    const markup = `
        <div class="wrapper -medium u-margin-vertical">
            <div class="card -paddingLarge">
                <h1 class="heading -h1">Oups! :(</h1>
                <div class="content">
                    <p>La plateforme Agora n'est pas supportée par <b>Internet Explorer</b>.</p>
                    <p>Bonne nouvelle, vous pouvez utiliser un navigateur moderne tel que Google Chrome, Firefox ou encore Edge pour une meilleure expérience en toute sécurité!</p>
                </div>
            </div>
        </div>
    `
    document.getElementsByTagName('body')[0].style.backgroundColor = '#054AE2'
    document.getElementsByTagName('body')[0].innerHTML = markup

    throw new Error('Internet Explorer')
}

// Initialize global states.
const store = getStore()
const history = getHistory()
const helmetContext = getHelmetContext()
const isSsr = store.getState().app.isSsr

// Setting up localization.
const { localeSlug } = setupLocalization(history, store, modules, !isSsr)
moment.locale(localeSlug)

// Setting up routing.
if (!isSsr) {
    storeStaticRoutes(store, modules)
}

/**
 * Function that attempts to load the access token stored in state and save it to local storage.
 *
 * @param {Object} state
 */
const LOCALSTORAGE_TOKEN = 'token'
function setAuthState(state) {
    try {
        const token = JSON.stringify((state.hub.auth || {}).token)
        localStorage.setItem(LOCALSTORAGE_TOKEN, token)
    } catch (error) {
        return undefined
    }
}

// Subscribe to auth state changes to update local storage.
store.subscribe(() => {
    setAuthState(store.getState())
})

// Save tokens in state before rendering the app.
store.dispatch({
    type: AUTH_SET_TOKEN,
    token: null,
    ...(function() {
        try {
            return {
                token: JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN)) || null,
            }
        } catch (error) {
            return {}
        }
    })(),
})

const root = document.getElementById('root')

// Requests to be made before rendering the app. Avoid querying user/self if no auth token is present.
Promise.all([
    ...(!isSsr ? [store.dispatch(fetchHubSettings())] : []),
    ...(store.getState().hub.auth.token !== null ? [store.dispatch(fetchSelf())] : []),
    ...(!isSsr ? [store.dispatch(fetchServices())] : []),
    ...(!isSsr ? [store.dispatch(fetchServiceCategories())] : []),
    ...(!isSsr ? [store.dispatch(fetchOnboardingSelf())] : []),
])
    .catch(error => {
        // Do nothing.
    })
    .finally(() => {
        const Application = (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App
                        helmetContext={helmetContext}
                        pathname={history.location.pathname}
                        store={store}
                    />
                </ConnectedRouter>
            </Provider>
        )
        if (root.hasChildNodes() === true) {
            hydrate(Application, root)
        } else {
            svg4everybody()
            render(Application, root)
        }
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register()
