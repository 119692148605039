import React from 'react'
import ContentLoader from 'react-content-loader'
import { getLanguages } from 'react-localize-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { Translation } from '../../../../components/Translation'
import { Content } from '../../../../components/Content'
import { ContentBlocks } from '../../../../components/ContentBlock'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Wrapper } from '../../../../components/Wrapper'
import { Section } from '../../../../components/Section'

import { translation } from '../../../../utils'

const PageDetails = ({ page, locales }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title itemProp="name">{translation(page.title)}</title>
                {page.summary && <meta name="description" content={translation(page.summary)} />}
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={page.publishDate} />
                <meta property="article:modified_time" content={page.lastModified} />
                <meta property="article:expiration_time" content={page.expiryDate} />
                {locales.map(locale => (
                    <link
                        rel="alternate"
                        href={`${window.location.origin}/${page.slug[locale.code]}`}
                        hrefLang={locale.code}
                        key={locale.code}
                    />
                ))}
            </Helmet>
            <article className="Page">
                {page.image ? (
                    <React.Fragment>
                        <HeaderPage
                            option={['noMargin']}
                            heading={page.title}
                            subheading={page.subtitle && translation(page.subtitle)}
                            breadcrumb="app.espace_citoyen.title"
                            icon={page.icon ? page.icon : 'reglements'}
                        />

                        <div className="projectHeader_image u-margin-bottom">
                            <img src={page.image} alt={translation(page.title)} />
                        </div>
                    </React.Fragment>
                ) : (
                    <HeaderPage
                        option={['page']}
                        heading={page.title}
                        subheading={page.subtitle && translation(page.subtitle)}
                        breadcrumb="app.espace_citoyen.title"
                        icon={page.icon ? page.icon : 'reglements'}
                    />
                )}
                <Wrapper>
                    <Section>
                        <Content>
                            {page.description && <Translation isHtml value={page.description} />}
                        </Content>
                    </Section>

                    <ContentBlocks blocks={page.attachments} showNav={true} />
                </Wrapper>
            </article>
        </React.Fragment>
    )
}

PageDetails.propTypes = {
    page: PropTypes.object.isRequired,
    locales: PropTypes.array,
}

const mapStateToProps = state => ({
    locales: getLanguages(state.localize),
})

PageDetails.Placeholder = () => <ContentLoader></ContentLoader>

export default connect(mapStateToProps)(PageDetails)
