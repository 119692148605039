import React from 'react'

import { View } from '../../../components/View'

import { IdeaDetailsContainer } from '../components/Idea'

const IdeaDetailsView = ({ location }) => (
    <View>
        <IdeaDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default IdeaDetailsView
