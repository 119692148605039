import React from 'react'

export const resolveMappedComponent = (key = '', map = {}) => {
    if (!(key in map)) {
        return null
    }
    return map[key]
}

export const renderMappedComponent = (key, map, props = {}) => {
    const resolvedComponent = resolveMappedComponent(key, map)
    if (resolvedComponent === null) {
        return resolvedComponent
    }
    const Component = resolvedComponent
    const { id, ...rest } = props
    return <Component key={props.id} {...rest} />
}
