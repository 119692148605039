import PropTypes from 'prop-types'
import React from 'react'

import { isTranslationObject, translation, formatDate } from '../../../../utils'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Heading } from '../../../../components/Heading'
import { Section } from '../../../../components/Section'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'

import '../Project/_projectHeader.scss'

const ProjectNewsHeader = ({ projectNews: { title, image, summary, newsDate, project } }) => (
    <div className="projectHeader">
        <HeaderPage
            option={['generic']}
            heading={title}
            subheading={formatDate(newsDate)}
            breadcrumb={'project.details.news.title'}
            backLink={translation(project.slug)}
        />

        <Section>
            <div className="u-relative">
                {image && (
                    <div className="projectHeader_image">
                        <img src={translation(image)} alt={translation(title)} />
                    </div>
                )}
            </div>
        </Section>

        {summary && (
            <div className="contentBlock">
                <Wrapper option={['medium']}>
                    <Heading el="p" option={['h2']}>
                        <Translation isHtml value={summary} />
                    </Heading>
                </Wrapper>
            </div>
        )}
    </div>
)

ProjectNewsHeader.propTypes = {
    projectNews: PropTypes.shape({
        title: isTranslationObject,
        image: PropTypes.string,
        summary: isTranslationObject,
        newsDate: PropTypes.string,
    }).isRequired,
}

export default ProjectNewsHeader
