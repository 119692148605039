import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/questions`

/**
 * Retrieve faq quetions listing.
 */
export const fetchQuestions = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve faq categories listing.
 */
export const fetchCategories = (config = {}) => {
    return apiClient.get(`${baseUrl}/categories`, config)
}
