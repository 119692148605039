import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { openSidebar, closeSidebar } from '../../actions/sidebarActions'
import { closeNav } from '../../actions/navActions'

import { LoginFormContainer } from '../../modules/hub/components/LoginForm'
import { ProfileContainer } from '../../modules/hub/components/Profile'
import { Copyright } from '../Copyright'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { Translation } from '../Translation'
import { Link } from '../Link'

import './_sidebar.scss'

const Sidebar = ({
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    isAuthenticated,
    layout,
    closeNav,
}) => {
    const openSide = () => {
        closeNav()
        openSidebar()
    }

    return (
        <div className={classNames('sidebar', { 'is-open': isSidebarOpen })}>
            <div className="sidebar_button">
                {layout === 'survey' ? (
                    <Button option={['circle', 'white']} icon="person" onClick={openSide}></Button>
                ) : (
                    <Button
                        option={['white', 'circleIcon', 'iconRight', 'mobileLabel']}
                        icon="person"
                        onClick={openSide}
                    >
                        {isAuthenticated ? (
                            <Translation value="hub.profile.title" />
                        ) : (
                            <Translation value="hub.loginForm.submit" />
                        )}
                    </Button>
                )}
            </div>
            <div className="sidebar_background" onClick={closeSidebar}></div>
            <div className="sidebar_close">
                <Button option={['circle', 'white']} icon="close" onClick={closeSidebar}></Button>
            </div>
            <div className="sidebar_main">
                <div>
                    <div className="sidebar_photo">
                        <Icon name="person" />
                    </div>
                    <LoginFormContainer />
                    <ProfileContainer />
                </div>
                <div className="sidebar_footer">
                    <Copyright />
                    <Link route="home">
                        <div className="sidebar_logo">
                            <Icon name="logo-mark" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    isSidebarOpen: PropTypes.bool.isRequired,
    openSidebar: PropTypes.func.isRequired,
    closeSidebar: PropTypes.func.isRequired,
    closeNav: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isSidebarOpen: state.sidebar.isSidebarOpen,
    isAuthenticated: state.hub.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
    closeSidebar: () => dispatch(closeSidebar()),
    closeNav: () => dispatch(closeNav()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
