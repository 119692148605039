import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { Lightbox as ModalImage } from 'react-modal-image'

import { openLightbox, closeLightbox } from '../../actions/lightboxActions'

import './_lightbox.scss'

const Lightbox = ({
    isLightboxOpen,
    urlToMediumImageFile,
    urlToLargeImageFile,
    alt,
    openLightbox,
    closeLightbox,
}) => {
    if (!isLightboxOpen) return null

    return (
        <ModalImage
            medium={urlToMediumImageFile}
            large={urlToLargeImageFile}
            alt={alt}
            onClose={closeLightbox}
        />
    )
}

Lightbox.propTypes = {
    isLightboxOpen: PropTypes.bool.isRequired,
    openLightbox: PropTypes.func.isRequired,
    closeLightbox: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isLightboxOpen: state.lightbox.isLightboxOpen,
    urlToMediumImageFile: state.lightbox.urlToMediumImageFile,
    urlToLargeImageFile: state.lightbox.urlToLargeImageFile,
    alt: state.lightbox.alt,
})

const mapDispatchToProps = dispatch => ({
    openLightbox: () => dispatch(openLightbox()),
    closeLightbox: () => dispatch(closeLightbox()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox)
