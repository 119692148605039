import React, { useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { SwitchTransition } from 'react-transition-group'

import { Fade } from '../../../../components/Fade'
import { NotFound } from '../../../../components/NotFound'
import { useFetchItems, responseCustomizer, useDev, logErrors } from '../../../../utils'

import { fetchInvoice } from '../../actions/invoiceActions'
import { mapApiObjectToAppAction } from '../../config/mappings'

import { PaymentDetails } from './'

const PaymentRequestDetailsContainer = ({ id, services }) => {
    const useFetchPaymentInvoice = useCallback(() => {
        return fetchInvoice(id)
    }, [id])
    const payment = useFetchItems(useFetchPaymentInvoice)

    const useFetchRelatedObjectPayment = useCallback(() => {
        if (
            _.isNil(payment.items.objectType) ||
            !_.isFunction(mapApiObjectToAppAction[payment.items.objectType])
        ) {
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return mapApiObjectToAppAction[payment.items.objectType](payment.items.objectId)
    }, [payment.items.objectType, payment.items.objectId])
    const relatedObject = useFetchItems(useFetchRelatedObjectPayment)

    const { requestStatus } = payment
    const { isLoading, error } = _.mergeWith({}, payment, relatedObject, responseCustomizer)
    useDev(logErrors(error))

    let headerPage = useRef({
        heading: 'payment.title',
        breadcrumb: 'app.online_services.title',
        icon: 'dollar',
    })

    let cardInfo = useRef({
        title: 'payment.title',
        description: 'payment.description.title',
    })
    for (const service of services) {
        if (
            service.type === 'city/permit/service/request' &&
            payment.items.objectType === 'City\\Permit\\Object\\Request' &&
            service.options.category === relatedObject.items.category
        ) {
            headerPage.current.heading = service.name
            headerPage.current.icon = service.icon
            cardInfo.current.title = 'permit.pay.title'
            cardInfo.current.description = 'permit.pay.description.title'
        }
    }

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <PaymentDetails.Placeholder />
                ) : (
                    <PaymentDetails
                        headerPage={headerPage.current}
                        cardInfo={cardInfo.current}
                        payment={payment.items}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    services: state.hub.services.entities,
})

export default connect(mapStateToProps)(PaymentRequestDetailsContainer)
