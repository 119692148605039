import React from 'react'

import './_spinner.scss'

const Spinner = () => (
    <svg className="spinner" viewBox="25 25 50 50">
        <circle className="spinner_circle" cx="50" cy="50" r="20"></circle>
    </svg>
)

export default Spinner
