import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchQuestions, fetchCategories } from '../../actions/faqActions'

import { FaqList } from './'

const FaqListContainer = () => {
    const useFetchQuestions = useCallback(() => {
        return fetchQuestions()
    }, [])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const questions = useFetchItems(useFetchQuestions)
    const categories = useFetchItems(useFetchCategories)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, questions, categories, responseCustomizer)
    useDev(logErrors(result.error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <FaqList.Placeholder />
                ) : (
                    <FaqList questions={questions.items} categories={categories.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default FaqListContainer
