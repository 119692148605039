import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SwitchTransition } from 'react-transition-group'

import { logErrors, useDev, useFetchItems } from '../../../../utils'
import {
    createFlashSurveyAnswer,
    fetchFlashSurvey,
    fetchFlashSurveyUserSelf,
} from '../../actions/flashSurveyActions'
import { Fade } from '../../../../components/Fade'
import { FlashSurvey } from '.'

const FlashSurveyContainer = ({ flashsurvey, loadUserSelfSurveys, offset = true }) => {
    // use state in order to use in cleanup useEffect function
    const [loadUserSelfSurveysRef, setLoadUserSelfSurveysRef] = useState(loadUserSelfSurveys)
    const [flashsurveyData, setFlashsurveyData] = useState(flashsurvey)

    useEffect(() => {
        setLoadUserSelfSurveysRef(loadUserSelfSurveys)
        return () => setLoadUserSelfSurveysRef(false)
    }, [loadUserSelfSurveysRef, loadUserSelfSurveys])

    const useFetchSurveyUserSelf = useCallback(() => {
        if (!loadUserSelfSurveysRef) {
            // used to dispatch from type and set isLoading to false
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return fetchFlashSurveyUserSelf()
    }, [loadUserSelfSurveysRef])

    const { items, error, isLoading } = useFetchItems(useFetchSurveyUserSelf)
    useDev(logErrors(error))

    if (_.isNull(flashsurveyData.id)) {
        return null
    }

    const refreshFlashSurvey = id => {
        fetchFlashSurvey(id).then(({ data }) => {
            setFlashsurveyData(data)
        })
    }

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <FlashSurvey.Placeholder />
                ) : (
                    <FlashSurvey
                        flashsurvey={flashsurveyData}
                        userSurveys={items}
                        createFlashSurveyAnswer={createFlashSurveyAnswer}
                        refreshData={refreshFlashSurvey}
                        offset={offset}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}
const mapStateToProps = state => ({
    loadUserSelfSurveys: state.hub.auth.isAuthenticated ? true : false,
})

export default connect(mapStateToProps)(FlashSurveyContainer)
