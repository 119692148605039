import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchWorks } from '../../actions/work/workActions'
import { fetchCategories } from '../../actions/project/categoryActions'
import { fetchService } from '../../../hub/actions/serviceActions'

import { WorkList } from '.'

const WorkListContainer = ({ filter, serviceData, url }) => {
    const useFetchService = useCallback(() => {
        return fetchService(serviceData.id)
    }, [serviceData.id])

    const useFetchWorks = useCallback(() => {
        return fetchWorks(filter)
    }, [filter])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const service = useFetchItems(useFetchService)
    const works = useFetchItems(useFetchWorks)
    const categories = useFetchItems(useFetchCategories)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, works, categories, service, responseCustomizer)
    useDev(logErrors(result.error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <WorkList.Placeholder />
                ) : (
                    <WorkList
                        filter={filter}
                        works={works.items}
                        categories={categories.items}
                        flashsurvey={service.items.flashsurvey}
                        url={serviceData.slug}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default WorkListContainer
