import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchCategories } from '../../actions/inquiry/categoryActions'
import { fetchFileSettings } from '../../actions/inquiry/fileActions'
import { fetchContactSettings } from '../../actions/settingActions'

import { InquiryForm } from './'

const InquiryFormContainer = ({ fetchContactSettings, contactSettings, hubSettings }) => {
    useEffect(() => {
        fetchContactSettings()
    }, [fetchContactSettings])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])
    const categories = useFetchItems(useFetchCategories)

    const useFetchFileSettings = useCallback(() => {
        return fetchFileSettings()
    }, [])
    const fileSettings = useFetchItems(useFetchFileSettings)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, categories, fileSettings, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <InquiryForm.Placeholder />
                ) : (
                    <InquiryForm
                        categories={categories.items}
                        hubSettings={hubSettings}
                        contactSettings={contactSettings}
                        fileSettings={fileSettings.items.files}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    hubSettings: state.hub.hubSettings.entities,
    contactSettings: state.contact.settings.entities,
})

const mapDispatchToProps = dispatch => ({
    fetchContactSettings: config => dispatch(fetchContactSettings(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InquiryFormContainer)
