import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import { Heading } from '../Heading'

import emojiImages from './emojiImages'

const MoodRadio = ({
    i,
    children,
    id,
    form,
    name,
    value,
    checked,
    options = {},
    ...classProps
}) => {
    const radio = (
        <div className={renderClasses('moodRadioItem', classProps)}>
            <input
                className="moodRadio_input"
                id={id}
                type="radio"
                name={name}
                value={value}
                defaultChecked={checked}
            />
            <label className="moodRadio_label" htmlFor={id}>
                <div className="moodRadio_text">
                    <Heading option={['h3']}>{children}</Heading>
                </div>

                <div className="moodRadio_emoji">
                    <img src={emojiImages[i]} alt="" />
                </div>
            </label>
        </div>
    )

    if (form) {
        return form.getFieldDecorator(name, options)(radio)
    } else {
        return radio
    }
}

MoodRadio.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    options: PropTypes.object,
}

export default MoodRadio
