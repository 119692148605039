import React, { useState, Fragment } from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Section } from '../../../../components/Section'
import { FilterPicker } from '../../../../components/FilterPicker'
import { ContentBlockHeader } from '../../../../components/ContentBlock'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ALL_BUTTON_CATEGORIES_VALUE } from '../../config'
import { FaqQuestion } from './'

const FaqList = ({ questions, categories }) => {
    // @TODO : Change the useState to set filtered questions, not selected category
    // Currently weird re-render because of the Container
    const [selectedCategory, setSelectedCategory] = useState(ALL_BUTTON_CATEGORIES_VALUE)

    //@TODO maybe do this sorting in api instead of here
    const displayedFAQ = categories.map((categorySection, index) => {
        const displayedQuestions = _.filter(questions, { category: categorySection.id })

        if (selectedCategory !== 'all' && selectedCategory !== categorySection.id) {
            return null
        } else if (_.isEmpty(displayedQuestions)) {
            return null
        }

        return (
            <Section key={index} option={['marginLarge']}>
                <ContentBlockHeader title={categorySection.name} option={['border']} />

                {displayedQuestions.map((question, index) => {
                    return (
                        <FaqQuestion
                            key={index}
                            item={question}
                            category={categorySection}
                            selectedCategory={selectedCategory}
                        />
                    )
                })}
            </Section>
        )
    })

    // filter categories from existing related question
    const questionsCategoriesIds = questions.map(question => question.category)
    const filteredCategories = _.filter(categories, cat =>
        !_.isNil(_.find(questionsCategoriesIds, questionCatId => questionCatId === cat.id))
            ? true
            : false
    )

    return (
        <Fragment>
            <Section>
                <FilterPicker
                    option={['noMargin']}
                    categories={filteredCategories}
                    triggerSelection={categoryId => setSelectedCategory(categoryId)}
                />
            </Section>

            <TransitionGroup>
                <CSSTransition
                    key={selectedCategory}
                    classNames="has-transition"
                    timeout={300}
                    appear
                >
                    <div className="faqList">{displayedFAQ}</div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

FaqList.propTypes = {
    questions: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

FaqList.Placeholder = () => <ContentLoader></ContentLoader>

export default FaqList
