import FaqListView from '../views/FaqListView'
// import PageView from '../views/PageView'

export const componentMap = {}
export const viewMap = {
    'city/content/service/questions': FaqListView,
    // 'city/content/service/page': PageView,
}

export const routeServiceMap = {
    page: 'city/content/service/page',
}
