import { API_URL } from '../config'

const baseUrl = `${API_URL}/settings`

/**
 * Retrieve hub settings.
 */
export const FETCH_HUB_SETTINGS_REQUEST = 'FETCH_HUB_SETTINGS_REQUEST'
export const FETCH_HUB_SETTINGS_SUCCESS = 'FETCH_HUB_SETTINGS_SUCCESS'
export const FETCH_HUB_SETTINGS_FAILURE = 'FETCH_HUB_SETTINGS_FAILURE'
export const FETCH_HUB_SETTINGS_PENDING = 'FETCH_HUB_SETTINGS_PENDING'
export const fetchHubSettings = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_HUB_SETTINGS_REQUEST,
            FETCH_HUB_SETTINGS_SUCCESS,
            FETCH_HUB_SETTINGS_FAILURE,
            FETCH_HUB_SETTINGS_PENDING,
        ],
    }
}
