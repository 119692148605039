import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/users`

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'

/**
 * Create a new login session, authenticating a specific user.
 */
export const CREATE_USER_LOGIN_REQUEST = 'CREATE_USER_LOGIN_REQUEST'
export const CREATE_USER_LOGIN_SUCCESS = 'CREATE_USER_LOGIN_SUCCESS'
export const CREATE_USER_LOGIN_FAILURE = 'CREATE_USER_LOGIN_FAILURE'
export const login = (formData, config = {}) => {
    return {
        promise: client => client.post(`${baseUrl}/login`, formData, config),
        types: [CREATE_USER_LOGIN_REQUEST, CREATE_USER_LOGIN_SUCCESS, CREATE_USER_LOGIN_FAILURE],
    }
}

/**
 * Delete a login session, unauthenticating the authenticated user.
 */
export const DELETE_USER_LOGIN_REQUEST = 'DELETE_USER_LOGIN_REQUEST'
export const DELETE_USER_LOGIN_SUCCESS = 'DELETE_USER_LOGIN_SUCCESS'
export const DELETE_USER_LOGIN_FAILURE = 'DELETE_USER_LOGIN_FAILURE'
export const logout = (config = {}) => {
    return {
        promise: client => client.delete(`${baseUrl}/login`, config),
        types: [DELETE_USER_LOGIN_REQUEST, DELETE_USER_LOGIN_SUCCESS, DELETE_USER_LOGIN_FAILURE],
    }
}

/**
 * Retrieve the authenticated user's profile.
 */
export const FETCH_USER_SELF_REQUEST = 'FETCH_USER_SELF_REQUEST'
export const FETCH_USER_SELF_SUCCESS = 'FETCH_USER_SELF_SUCCESS'
export const FETCH_USER_SELF_FAILURE = 'FETCH_USER_SELF_FAILURE'
export const fetchSelf = (config = {}) => {
    return {
        promise: client => client.get(`${baseUrl}/self`, config),
        types: [FETCH_USER_SELF_REQUEST, FETCH_USER_SELF_SUCCESS, FETCH_USER_SELF_FAILURE],
    }
}

/**
 * Update the authenticated user's profile.
 */
export const UPDATE_USER_SELF_REQUEST = 'UPDATE_USER_SELF_REQUEST'
export const UPDATE_USER_SELF_SUCCESS = 'UPDATE_USER_SELF_SUCCESS'
export const UPDATE_USER_SELF_FAILURE = 'UPDATE_USER_SELF_FAILURE'
export const updateSelf = (formData = {}, config = {}) => {
    return {
        promise: client => client.patch(`${baseUrl}/self`, formData, config),
        types: [UPDATE_USER_SELF_REQUEST, UPDATE_USER_SELF_SUCCESS, UPDATE_USER_SELF_FAILURE],
    }
}

/**
 * Delete current self user.
 */
export const deleteUser = (formData = {}, config = {}) => {
    return apiClient.delete(`${baseUrl}/self`, formData, config)
}

/**
 * Create a new user.
 */
export const createUser = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}`, formData, config)
}

/**
 * Confirm the user subscription from with email token.
 */
export const confirmUser = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}/self/confirm/email`, formData, config)
}

/**
 * Create a new reset-password token and sends a reset password link to user's email.
 */
export const createLostPasswordToken = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/lost-password-tokens`, formData, config)
}

/**
 * Consume a reset-password token and change a user's password.
 */
export const resetPassword = (tokenId, formData, config = {}) => {
    return apiClient.post(`${baseUrl}/lost-password-tokens/${tokenId}`, formData, config)
}

/**
 * Check duplication user email
 */
export const checkDuplicate = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/check-duplicate`, formData, config)
}
