import _ from 'lodash'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { localizePath, renderRoute } from '../../utils'
import {
    userIsAuthenticatedRedirection,
    userIsAuthenticatedSubscriptionRedirection,
    userIsNotAuthenticatedRedirection,
} from '../../modules/hub/utils'

/**
 * Wrap a route component with an authentication related redirection check.
 *
 * @param  {Object} route
 * @param  {JSX}    component
 * @return {JSX}
 */
const wrapRouteComponent = (route, component) => {
    if (route.isAuthenticated) {
        component = userIsAuthenticatedRedirection(component)
    } else if (route.isNotAuthenticated) {
        component = userIsNotAuthenticatedRedirection(component)
    }

    if (route.id === 'hub-subscription') {
        component = userIsAuthenticatedSubscriptionRedirection(component)
    }

    return component
}

/**
 * Create a collection of rendered 'react-router-dom' components that define the app's routing.
 *
 * @param  {Array} routes An array of all the app's route definitions.
 * @return {JSX}
 */
const createRoutes = (routes, views) => {
    // _.compact will remove falsy or null values fron our mapped array
    return _.compact(
        _.map(routes, (route, index) => {
            // Rudimentary support for redirection. Only supported in static route definitions
            // ...for now.
            if (route.isRedirect === true) {
                const redirectFrom = localizePath(route.path)
                const redirectTo = renderRoute(route.redirectTo)
                if (redirectTo) {
                    return <Redirect key={index} exact from={redirectFrom} to={redirectTo} />
                } else {
                    console.error(`Redirect could not interpret "${redirectFrom}".`)
                    return false
                }
            }

            /**
             * A component identifier.
             *
             * Determines the component to render for the currently interpreted route.
             * In its most basic usage, componentIdent will be a string identifying which
             * component, imported by `views/index.js`, we want to render.
             *
             * However, we also support function references, which basically amounts to doing:
             *
             * ```
             * import NewsListView from 'views'
             *
             * const routes = [
             *     {
             *         component: NewsListView
             *     }
             * ]
             * ```
             *
             * @type {String|Function}
             */
            const componentIdent = _.has(route, 'component')
                ? // When using the 'component' key, either a string identifier or an imported
                  // reference must be used.
                  // (ex.: "NewsListView" or function NewsListView )
                  route.component
                : // Magicly convert a route ID into a view component
                  // (ex.: "news-list" becomes "NewsListView")
                  _.upperFirst(_.camelCase(`${route.id}-view`))

            /**
             * The component to be rendered.
             *
             * A string componentIdent indicates that an identifier was given, elsewise we've
             * been given a function reference
             *
             * @type {Function}
             */
            const Component = wrapRouteComponent(
                route,
                _.isString(componentIdent) ? views[componentIdent] : componentIdent
            )

            // In any case, validate the component
            if (typeof Component === 'undefined') {
                console.error(`View ${componentIdent} could not be found.`)
                return false
            } else {
                // <Route /> render prop is ready to inject interesting props into the desired.
                // Any and all data can be given. You are encouraged to let your wildest dreams go free.
                const routeProps = _.has(route, 'props') ? route.props : {}
                return (
                    <Route
                        key={index}
                        exact
                        path={localizePath(route.path)}
                        render={routerProps => <Component {...routerProps} {...routeProps} />}
                    />
                )
            }
        })
    )
}

/**
 * Component that defines all the application's routes, be it they come from:
 *
 *  - the app's route config (config/routes.json),
 *  - a module's route config (agora-module-.../config/routes.json),
 *  - or an API endpoint (service routes)
 */
const RouteResolver = ({ routes, views }) => (
    <Route
        render={({ location }) => {
            return (
                <TransitionGroup>
                    <CSSTransition
                        appear
                        key={location.key}
                        classNames="has-transition"
                        timeout={{ enter: 600, exit: 600 }}
                    >
                        <Switch location={location}>
                            {createRoutes(routes, views)}
                            <Route component={views['NotFoundView']} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )
        }}
    />
)

export default RouteResolver
