import React from 'react'

import './_loader.scss'

const Loader = () => (
    <div className="loader">
        <div className="loader_main"></div>
    </div>
)

export default Loader
