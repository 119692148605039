import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { openNav, closeNav } from '../../actions/navActions'
import {
    findServiceBySlug,
    findServiceByType,
    getServiceTypeFromRoutePath,
    parseServiceCategories,
    renderRoute,
    resolveServiceLink,
} from '../../utils'
import { Button } from '../Button'
import { Copyright } from '../Copyright'
import { Icon } from '../Icon'
import { Link } from '../Link'
import { NavItem } from '.'
import { Translation } from '../Translation'

import './_nav.scss'

const Nav = ({
    handleCloseNav,
    handleOpenNav,
    isAuthenticated,
    isNavOpen,
    serviceCategories,
    services,
}) => {
    const { pathname } = useLocation()

    if (!services) {
        return null
    }

    let alertService = findServiceByType(services, 'city/memo/service/alert-external')
    if (!alertService) {
        alertService = findServiceByType(services, 'city/memo/service/alerts')
    }
    const cleanedServices = _.filter(
        services,
        service => service !== alertService && !_.isNull(service.category)
    )
    alertService = alertService ? resolveServiceLink(alertService) : false

    const groupedServices = parseServiceCategories(serviceCategories, cleanedServices)

    let currentService = findServiceBySlug(services, pathname)
    // Retrieve current service for static route (contains the service in route object from store)
    if (!currentService) {
        currentService = findServiceByType(services, getServiceTypeFromRoutePath(pathname))
    }

    return (
        <nav className={classNames('nav', { 'is-open': isNavOpen })}>
            <div className="nav_button">
                <Button option={['circle', 'white']} icon="burger" onClick={handleOpenNav}></Button>
            </div>
            <div className="nav_background" onClick={handleCloseNav}></div>
            <div className="nav_main">
                <Link className="nav_logo" route="home">
                    <Icon name="logo" />
                </Link>
                <div className="nav_section">
                    {!isAuthenticated && (
                        <div className="nav_item">
                            <Link
                                className={classNames('nav_link', {
                                    'is-current': pathname === renderRoute('hub-subscription'),
                                })}
                                route="hub-subscription"
                            >
                                <Translation value="app.signup" />
                            </Link>
                        </div>
                    )}
                    {alertService && (
                        <div className="nav_item">
                            <Link
                                className={classNames('nav_link')}
                                href={alertService.url}
                                external={alertService.external}
                            >
                                {alertService.label}
                            </Link>
                        </div>
                    )}
                </div>
                <div className="nav_section -main">
                    {groupedServices.map((serviceCategory, index) => (
                        <NavItem
                            item={serviceCategory}
                            currentService={currentService}
                            key={index}
                        />
                    ))}
                </div>
                <div className="nav_section -footer">
                    <Copyright />
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    isNavOpen: state.nav.isNavOpen,
    serviceCategories: state.hub.serviceCategories.entities,
    services: state.hub.services.entities,
})

const mapDispatchToProps = dispatch => ({
    handleCloseNav: () => dispatch(closeNav()),
    handleOpenNav: () => dispatch(openNav()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
