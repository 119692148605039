import { apiClient } from '../../../../actions'

import { WORK_API_URL } from '../../config'

const baseUrl = `${WORK_API_URL}/works/news`

/**
 * Retrieve work's news details by slug.
 */
export const fetchWorkNewsBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}
