import React from 'react'

import { View } from '../../../components/View'

import { PageDetailsContainer } from '../components/Page'
const PageView = ({ location }) => (
    <View>
        <PageDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default PageView
