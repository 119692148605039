import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'
import { SwitchTransition } from 'react-transition-group'

import { Fade } from '../../../../components/Fade'

import { useFetchItems } from '../../../../utils'

import { fetchSelfSubscription } from '../../../memo/actions/subscriptionActions'
import { fetchMemoSettings } from '../../../memo/actions/settingActions'
import { fetchCategories as fetchMemoCategories} from '../../../memo/actions/categoryActions'

import { login, updateSelf, fetchSelf } from '../../actions/userActions'

import { SubscriptionForm } from '.'

const SubscriptionFormContainer = props => {
    const { hasMemoSubscription, fetchSelfSubscription, fetchMemoSettings, memoSettings } = props

    useEffect(() => {
        fetchMemoSettings()

        if (!hasMemoSubscription) {
            return () => ({ type: 'NO_FETCH' })
        }
        fetchSelfSubscription()
    }, [hasMemoSubscription, fetchSelfSubscription, fetchMemoSettings])

    const useFetchMemoCategories = useCallback(() => {
        return fetchMemoCategories()
    }, [])
    const memoCategories = useFetchItems(useFetchMemoCategories)

    const isLoading = _.isEmpty(memoSettings) || _.isEmpty(memoCategories.items)

    return  (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                { isLoading ? (
                    <SubscriptionForm.Placeholder />
                ) : (
                    <SubscriptionForm memoCategories={memoCategories.items} {...props} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    hasRemoteAddresses: state.hub.hubSettings.entities.hasRemoteAddresses,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
    memoSettings: state.memo.settings.entities,
    privacyPolicy: state.hub.hubSettings.entities.privacyPolicy,
    termsOfUse: state.hub.hubSettings.entities.termsOfUse,
})

const mapDispatchToProps = dispatch => ({
    login: (data, config) => dispatch(login(data, config)),
    updateSelf: (data, config) => dispatch(updateSelf(data, config)),
    fetchSelf: config => dispatch(fetchSelf(config)),
    fetchSelfSubscription: config => dispatch(fetchSelfSubscription(config)),
    fetchMemoSettings: config => dispatch(fetchMemoSettings(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionFormContainer)
