import React from 'react'
import queryString from 'query-string'

import { ONGOING_CONTENT, FINISHED_CONTENT } from '../../../config'
import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'
import { translation } from '../../../utils'

import { WorkFilterPicker, WorkListContainer } from '../components/Work'

const WorkListView = ({ location, serviceData, serviceCategoryData }) => {
    // Retrieve GET parameter in order to set the project's filter
    const params = queryString.parse(location.search)
    let type = ONGOING_CONTENT
    if (params.status && params.status === translation(FINISHED_CONTENT)) {
        type = translation(FINISHED_CONTENT)
    }

    return (
        <View>
            <ServiceMetatags data={serviceData} />
            <HeaderPage
                heading="work.listing.title"
                breadcrumb={serviceCategoryData.name}
                icon={serviceData.icon}
                intro={serviceData.introBox}
            />
            <WorkFilterPicker baseUrl={serviceData.slug} selected={type} />
            <WorkListContainer filter={type} serviceData={serviceData} />
        </View>
    )
}

export default WorkListView
