import React, { useEffect, useCallback } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getActiveLanguage } from 'react-localize-redux'
import { SwitchTransition } from 'react-transition-group'

import { Fade } from '../../../../components/Fade'
import { useDev, logErrors, useFetchItems, responseCustomizer } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { fetchSelf, login } from '../../../hub/actions/userActions'
import {
    LOCALSTORAGE_SUBSCRIBED_USERID,
    LOCALSTORAGE_SUBSCRIBED_USER_EMAIL,
} from '../../../hub/config'

import { fetchMemoSettings } from '../../actions/settingActions'
import { fetchSelfSubscription, updateSelfSubscription } from '../../actions/subscriptionActions'
import { fetchCategories } from '../../actions/categoryActions'

import { fetchZones as fetchParkingZones } from '../../../parking/actions/zoneActions'
import { fetchZones as fetchSnowZones } from '../../../snow/actions/zoneActions'
import { fetchZones as fetchWasteZones } from '../../../waste/actions/zoneActions'

import { SubscriptionForm } from '.'

const SubscriptionFormContainer = props => {
    const { hasMemoSubscription, userId, fetchSelfSubscription, fetchMemoSettings } = props

    useEffect(() => {
        fetchMemoSettings()

        if (!hasMemoSubscription) {
            return () => ({ type: 'NO_FETCH' })
        }
        fetchSelfSubscription()
    }, [hasMemoSubscription, fetchSelfSubscription, fetchMemoSettings])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])
    const categories = useFetchItems(useFetchCategories)

    const useFetchParkingZones = useCallback(() => {
        return fetchParkingZones()
    }, [])
    const parkingZones = useFetchItems(useFetchParkingZones)

    const useFetchSnowZones = useCallback(() => {
        return fetchSnowZones()
    }, [])
    const snowZones = useFetchItems(useFetchSnowZones)

    const useFetchWasteZones = useCallback(() => {
        return fetchWasteZones()
    }, [])
    const wasteZones = useFetchItems(useFetchWasteZones)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith(
        {},
        categories,
        parkingZones,
        snowZones,
        wasteZones,
        responseCustomizer
    )
    useDev(logErrors(error))

    const zones = {
        parking: parkingZones.items,
        snow: snowZones.items,
        waste: wasteZones.items,
    }

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {!userId ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <SubscriptionForm.Placeholder />
                ) : (
                    <SubscriptionForm categories={categories.items} zones={zones} {...props} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    userId: state.hub.auth.isAuthenticated
        ? state.hub.auth.user.id
        : localStorage.getItem(LOCALSTORAGE_SUBSCRIBED_USERID) || null,
    userEmail: localStorage.getItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL) || null,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    memoSettings: state.memo.settings.entities,
    memo:
        state.hub.auth.isAuthenticated && state.memo.subscription ? state.memo.subscription : false,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
    lang: getActiveLanguage(state.localize).code,
})

const mapDispatchToProps = dispatch => ({
    login: (data, config) => dispatch(login(data, config)),
    updateSelfSubscription: (data, config) => dispatch(updateSelfSubscription(data, config)),
    fetchSelf: config => dispatch(fetchSelf(config)),
    fetchSelfSubscription: config => dispatch(fetchSelfSubscription(config)),
    fetchMemoSettings: config => dispatch(fetchMemoSettings(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionFormContainer)
