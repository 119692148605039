import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Wrapper } from '../../../../components/Wrapper'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'
import { Section } from '../../../../components/Section'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Button } from '../../../../components/Button'
import { translation } from '../../../../utils'

import { ProjectCard } from './'
import { FlashSurveyContainer } from '../../../survey/components/FlashSurvey'
import { FINISHED_CONTENT } from '../../../../config'

const ProjectList = ({ projects, categories, filter, flashsurvey = false, url }) => {
    if (!projects.length) {
        if (filter === translation(FINISHED_CONTENT)) {
            return (
                <Wrapper>
                    <CardFeedback text="project.listing.finished.placeholder.description">
                        <Button
                            option={['blue', 'iconLeft']}
                            icon="arrow-left"
                            href={translation(url)}
                        >
                            <Translation value="project.listing.finished.placeholder.button" />
                        </Button>
                    </CardFeedback>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    <CardFeedback text="project.listing.ongoing.placeholder.description">
                        <Button
                            option={['blue', 'iconRight']}
                            icon="arrow-right"
                            href={`${translation(url)}?status=${translation(FINISHED_CONTENT)}`}
                        >
                            <Translation value="project.listing.ongoing.placeholder.button" />
                        </Button>
                    </CardFeedback>
                </Wrapper>
            )
        }
    }

    // First element large if odd count
    let renderedProjects
    if (projects.length % 2) {
        const firstProjectCategory = _.find(
            categories,
            category => category.id === projects[0].category
        )
        const renderedFirstProject = (
            <LayoutItem key={projects[0].id}>
                <ProjectCard
                    project={projects[0]}
                    category={firstProjectCategory}
                    isLarge
                    key={projects[0].id}
                />
            </LayoutItem>
        )
        renderedProjects = [
            renderedFirstProject,
            ..._.tail(projects).map((project, i) => {
                const category = _.find(categories, category => category.id === project.category)

                return (
                    <LayoutItem utility={['1/2@from-small']} key={i}>
                        <ProjectCard project={project} category={category} key={project.id} />
                    </LayoutItem>
                )
            }),
        ]
    } else {
        renderedProjects = projects.map((project, i) => {
            const category = _.find(categories, category => category.id === project.category)

            return (
                <LayoutItem utility={['1/2@from-small']} key={i}>
                    <ProjectCard project={project} category={category} key={project.id} />
                </LayoutItem>
            )
        })
    }

    let viewFilter = 'ongoing'
    if (filter === translation(FINISHED_CONTENT)) {
        viewFilter = 'finished'
    }

    return (
        <div>
            <Wrapper>
                <Heading option={['h3']} el="h2">
                    <Translation value={'project.listing.' + viewFilter + '.title'} />
                    <sup>{projects.length}</sup>
                </Heading>
                <Section>
                    <Layout option={['flex', 'stretch', 'gutterSmall', 'gutterBottom']}>
                        {renderedProjects}
                    </Layout>
                </Section>
            </Wrapper>
            {flashsurvey && <FlashSurveyContainer flashsurvey={flashsurvey} />}
        </div>
    )
}

ProjectList.propTypes = {
    projects: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

ProjectList.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="28" y="19" rx="5" ry="5" width="219" height="123" />
        <rect x="288" y="35" rx="0" ry="0" width="132" height="5" />
        <rect x="288" y="57" rx="0" ry="0" width="141" height="14" />
        <rect x="288" y="106" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="79" rx="0" ry="0" width="141" height="14" />
        <rect x="30" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="50" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="50" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="50" y="318" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="308" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="308" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="308" y="318" rx="20" ry="20" width="122" height="33" />
    </ContentLoader>
)

export default ProjectList
