import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../Translation'
import { isTranslationObject, renderClasses } from '../../utils'

import { Heading } from '../Heading'

import './_contentHeader.scss'

const ContentBlockHeader = ({ title, ...classProps }) => (
    <div className={renderClasses('contentHeader', classProps)}>
        <Heading el="h2" option={['h2', 'noMargin']}>
            {title && <Translation value={title} />}
        </Heading>
    </div>
)

ContentBlockHeader.propTypes = {
    title: PropTypes.oneOfType([isTranslationObject, PropTypes.string]),
    border: PropTypes.bool,
}

export default ContentBlockHeader
