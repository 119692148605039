import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../Translation'
import { Icon } from '../../Icon'
import { isTranslationObject, translation } from '../../../utils'

const ContentBlockDocumentExternal = ({ title, file, fromGallery = false }) => (
    <li className="contentDocument_row">
        <a
            href={translation(file)}
            target="_blank"
            rel="noopener noreferrer"
            className="contentDocument_row_inner"
        >
            {title && <Translation value={title} />}

            <div className="contentDocument_row_button">
                <span className="contentDocument_row_button_icon">
                    <Icon name="project-document-link" />
                </span>
            </div>
        </a>
    </li>
)

ContentBlockDocumentExternal.propTypes = {
    title: isTranslationObject,
    file: isTranslationObject,
    fromGallery: PropTypes.bool,
}

export default ContentBlockDocumentExternal
