import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { Card } from '../../../../components/Card'
import { CardLabel } from '../../../../components/Card'
import { Content } from '../../../../components/Content'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Section } from '../../../../components/Section'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'

import { findServiceByType, translation } from '../../../../utils'

import { SERVICE_IDEA_IDEAS } from '../../config'

import { IdeaVote, IdeaDetailsCommentForm, IdeaDetailsComments } from './'

const IdeaDetails = ({ idea, category, service, comments }) => (
    <Fragment>
        <Helmet>
            <title itemProp="name">{idea.title}</title>
            <meta name="description" content={idea.description} />
        </Helmet>
        <HeaderPage
            option={['idea']}
            heading="idea.listing.title"
            icon={service.icon}
            breadcrumb={service && service.name}
            backLink={service && translation(service.slug)}
        />

        <Wrapper>
            <Section>
                <Card option={['paddingLarge']}>
                    <div className={'cardIdeaDetails'}>
                        <Content utility={['textCenter']} option={['maxWidth']}>
                            <CardLabel utility={['blue']}>
                                <Translation value={category.name} />
                            </CardLabel>

                            <h2>{idea.title}</h2>

                            <Content html={idea.description} />

                            <IdeaVote idea={idea} isDetails />
                        </Content>

                        <Wrapper option={['medium', 'noPaddingSmall']}>
                            <Section>
                                <Card option={['paddingLarge']} utility={['textCenter']}>
                                    <IdeaDetailsCommentForm
                                        idea={idea}
                                        backLink={service && translation(service.slug)}
                                    />
                                </Card>
                            </Section>

                            <IdeaDetailsComments comments={comments} />
                        </Wrapper>
                    </div>
                </Card>
            </Section>
        </Wrapper>
    </Fragment>
)

IdeaDetails.Placeholder = () => <ContentLoader></ContentLoader>

IdeaDetails.propTypes = {
    idea: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    service: PropTypes.object,
}

const mapStateToProps = state => ({
    service: findServiceByType(state.hub.services.entities, SERVICE_IDEA_IDEAS),
})

export default connect(mapStateToProps)(IdeaDetails)
