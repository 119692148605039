import img_0 from './img_0.png'
import img_1 from './img_1.png'
import img_2 from './img_2.png'
import img_3 from './img_3.png'
import img_4 from './img_4.png'
import img_5 from './img_5.png'
import img_6 from './img_6.png'
import img_7 from './img_7.png'
import img_8 from './img_8.png'
import img_9 from './img_9.png'
import img_10 from './img_10.png'
import img_11 from './img_11.png'
import img_12 from './img_12.png'
import img_13 from './img_13.png'
import img_14 from './img_14.png'
import img_15 from './img_15.png'
import img_16 from './img_16.png'
import img_17 from './img_17.png'
import img_18 from './img_18.png'
import img_19 from './img_19.png'
import img_20 from './img_20.png'
import img_21 from './img_21.png'
import img_22 from './img_22.png'

export default {
    v: '5.5.10',
    fr: 60,
    ip: 0,
    op: 480,
    w: 1285,
    h: 941,
    nm: 'agora_motion',
    ddd: 0,
    assets: [
        {
            id: 'image_0',
            w: 126,
            h: 105,
            u: '',
            p: img_0,
            e: 0,
        },
        {
            id: 'image_1',
            w: 100,
            h: 100,
            u: '',
            p: img_1,
            e: 0,
        },
        {
            id: 'image_2',
            w: 37,
            h: 32,
            u: '',
            p: img_2,
            e: 0,
        },
        {
            id: 'image_3',
            w: 1002,
            h: 175,
            u: '',
            p: img_3,
            e: 0,
        },
        {
            id: 'image_4',
            w: 299,
            h: 380,
            u: '',
            p: img_4,
            e: 0,
        },
        {
            id: 'image_5',
            w: 63,
            h: 60,
            u: '',
            p: img_5,
            e: 0,
        },
        {
            id: 'image_6',
            w: 200,
            h: 143,
            u: '',
            p: img_6,
            e: 0,
        },
        {
            id: 'image_7',
            w: 190,
            h: 250,
            u: '',
            p: img_7,
            e: 0,
        },
        {
            id: 'image_8',
            w: 38,
            h: 42,
            u: '',
            p: img_8,
            e: 0,
        },
        {
            id: 'image_9',
            w: 71,
            h: 90,
            u: '',
            p: img_9,
            e: 0,
        },
        {
            id: 'image_10',
            w: 73,
            h: 86,
            u: '',
            p: img_10,
            e: 0,
        },
        {
            id: 'image_11',
            w: 199,
            h: 145,
            u: '',
            p: img_11,
            e: 0,
        },
        {
            id: 'image_12',
            w: 138,
            h: 123,
            u: '',
            p: img_12,
            e: 0,
        },
        {
            id: 'image_13',
            w: 142,
            h: 64,
            u: '',
            p: img_13,
            e: 0,
        },
        {
            id: 'image_14',
            w: 70,
            h: 17,
            u: '',
            p: img_14,
            e: 0,
        },
        {
            id: 'image_15',
            w: 61,
            h: 39,
            u: '',
            p: img_15,
            e: 0,
        },
        {
            id: 'image_16',
            w: 60,
            h: 40,
            u: '',
            p: img_16,
            e: 0,
        },
        {
            id: 'image_17',
            w: 44,
            h: 66,
            u: '',
            p: img_17,
            e: 0,
        },
        {
            id: 'image_18',
            w: 203,
            h: 210,
            u: '',
            p: img_18,
            e: 0,
        },
        {
            id: 'image_19',
            w: 136,
            h: 39,
            u: '',
            p: img_19,
            e: 0,
        },
        {
            id: 'image_20',
            w: 69,
            h: 33,
            u: '',
            p: img_20,
            e: 0,
        },
        {
            id: 'image_21',
            w: 456,
            h: 543,
            u: '',
            p: img_21,
            e: 0,
        },
        {
            id: 'image_22',
            w: 1286,
            h: 942,
            u: '',
            p: img_22,
            e: 0,
        },
        {
            id: 'comp_0',
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Shape Layer 1',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 0,
                            k: [642.5, 471, 0],
                            ix: 2,
                        },
                        a: {
                            a: 0,
                            k: [0, 0, 0],
                            ix: 1,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 1,
                                        k: [
                                            {
                                                i: {
                                                    x: 0,
                                                    y: 1,
                                                },
                                                o: {
                                                    x: 0.466,
                                                    y: 0,
                                                },
                                                t: 19,
                                                s: [
                                                    {
                                                        i: [
                                                            [-11.75, 0],
                                                            [-9.812, 10.879],
                                                        ],
                                                        o: [
                                                            [12.25, 0],
                                                            [11.5, -12.75],
                                                        ],
                                                        v: [
                                                            [1.25, -99.75],
                                                            [37, -113.5],
                                                        ],
                                                        c: false,
                                                    },
                                                ],
                                            },
                                            {
                                                i: {
                                                    x: 0.833,
                                                    y: 1,
                                                },
                                                o: {
                                                    x: 0.333,
                                                    y: 0,
                                                },
                                                t: 84,
                                                s: [
                                                    {
                                                        i: [
                                                            [-11.75, 0],
                                                            [-9.5, 4],
                                                        ],
                                                        o: [
                                                            [9.75, 0],
                                                            [15.825, -6.663],
                                                        ],
                                                        v: [
                                                            [1.25, -105.25],
                                                            [34.25, -109.75],
                                                        ],
                                                        c: false,
                                                    },
                                                ],
                                            },
                                            {
                                                i: {
                                                    x: 0,
                                                    y: 1,
                                                },
                                                o: {
                                                    x: 0.336,
                                                    y: 0,
                                                },
                                                t: 234,
                                                s: [
                                                    {
                                                        i: [
                                                            [-11.75, 0],
                                                            [-9.5, 4],
                                                        ],
                                                        o: [
                                                            [9.75, 0],
                                                            [15.825, -6.663],
                                                        ],
                                                        v: [
                                                            [1.25, -105.25],
                                                            [34.25, -109.75],
                                                        ],
                                                        c: false,
                                                    },
                                                ],
                                            },
                                            {
                                                t: 317,
                                                s: [
                                                    {
                                                        i: [
                                                            [-11.75, 0],
                                                            [-9.812, 10.879],
                                                        ],
                                                        o: [
                                                            [12.25, 0],
                                                            [11.5, -12.75],
                                                        ],
                                                        v: [
                                                            [1.25, -99.75],
                                                            [37, -113.5],
                                                        ],
                                                        c: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0.764705942191, 0.345098039216, 0.309803921569, 1],
                                        ix: 3,
                                    },
                                    o: {
                                        a: 0,
                                        k: 100,
                                        ix: 4,
                                    },
                                    w: {
                                        a: 0,
                                        k: 6,
                                        ix: 5,
                                    },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0.611765005074, 0.823528992896, 0.854902020623, 1],
                                        ix: 4,
                                    },
                                    o: {
                                        a: 0,
                                        k: 0,
                                        ix: 5,
                                    },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: {
                                        a: 0,
                                        k: [0, 0],
                                        ix: 2,
                                    },
                                    a: {
                                        a: 0,
                                        k: [0, 0],
                                        ix: 1,
                                    },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: {
                                        a: 0,
                                        k: 0,
                                        ix: 6,
                                    },
                                    o: {
                                        a: 0,
                                        k: 100,
                                        ix: 7,
                                    },
                                    sk: {
                                        a: 0,
                                        k: 0,
                                        ix: 4,
                                    },
                                    sa: {
                                        a: 0,
                                        k: 0,
                                        ix: 5,
                                    },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Shape 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 2,
                    nm: 'lunette',
                    refId: 'image_13',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 0,
                            k: [645.43, 312.136, 0],
                            ix: 2,
                        },
                        a: {
                            a: 0,
                            k: [70.692, 31.991, 0],
                            ix: 1,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 2,
                    nm: 'yeux',
                    refId: 'image_14',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: 0.667,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.333,
                                        y: 0,
                                    },
                                    t: 52,
                                    s: [647.205, 311.1, 0],
                                    to: [1.042, 0, 0],
                                    ti: [-0.042, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0.667,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.333,
                                        y: 0,
                                    },
                                    t: 78,
                                    s: [653.455, 311.1, 0],
                                    to: [0.015, 0, 0],
                                    ti: [0.023, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0.667,
                                        y: 0.8,
                                    },
                                    o: {
                                        x: 0.167,
                                        y: 0,
                                    },
                                    t: 124,
                                    s: [647.455, 311.1, 0],
                                    to: [-1, 0, 0],
                                    ti: [0.941, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0.667,
                                        y: 0.298,
                                    },
                                    o: {
                                        x: 0.321,
                                        y: 0.677,
                                    },
                                    t: 135,
                                    s: [648.823, 311.1, 0],
                                    to: [-0.282, 0, 0],
                                    ti: [0.941, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0.656,
                                        y: 0.901,
                                    },
                                    o: {
                                        x: 0.321,
                                        y: 0.143,
                                    },
                                    t: 150,
                                    s: [648.823, 311.1, 0],
                                    to: [-0.282, 0, 0],
                                    ti: [-0.176, -0.289, 0],
                                },
                                {
                                    i: {
                                        x: 0.755,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.206,
                                        y: 1,
                                    },
                                    t: 169,
                                    s: [653.64, 311.509, 0],
                                    to: [0.084, 0.138, 0],
                                    ti: [-0.203, 0.271, 0],
                                },
                                {
                                    i: {
                                        x: 0.676,
                                        y: 0.822,
                                    },
                                    o: {
                                        x: 0.295,
                                        y: 0.204,
                                    },
                                    t: 194,
                                    s: [653.64, 311.509, 0],
                                    to: [0.084, -0.112, 0],
                                    ti: [0.632, 0, 0],
                                },
                                {
                                    t: 262,
                                    s: [647.542, 311.1, 0],
                                },
                            ],
                            ix: 2,
                        },
                        a: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: 0,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.475,
                                        y: 0,
                                    },
                                    t: 19,
                                    s: [34.975, 8.007, 0],
                                    to: [0, -0.833, 0],
                                    ti: [0, 0.833, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.333,
                                        y: 0.333,
                                    },
                                    t: 84,
                                    s: [34.975, 3.007, 0],
                                    to: [0, 0, 0],
                                    ti: [0, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.502,
                                        y: 0,
                                    },
                                    t: 234,
                                    s: [34.975, 3.007, 0],
                                    to: [0, 0.833, 0],
                                    ti: [0, -0.833, 0],
                                },
                                {
                                    t: 317,
                                    s: [34.975, 8.007, 0],
                                },
                            ],
                            ix: 1,
                        },
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 22,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 25.666,
                                    s: [100, 25, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 33,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 260,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 263.666,
                                    s: [100, 25, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 271,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 384,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.833, 0.833, 0.833],
                                        y: [0.833, 0.833, 0.833],
                                    },
                                    o: {
                                        x: [0.167, 0.167, 0.167],
                                        y: [0.167, 0.167, 0.167],
                                    },
                                    t: 387.666,
                                    s: [100, 25, 100],
                                },
                                {
                                    t: 395,
                                    s: [100, 100, 100],
                                },
                            ],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 2,
                    nm: 'sourcil droit',
                    refId: 'image_15',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: 0,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.438,
                                        y: 0,
                                    },
                                    t: 19,
                                    s: [616.192, 286.704, 0],
                                    to: [0, -0.833, 0],
                                    ti: [0, 0.833, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.333,
                                        y: 0.333,
                                    },
                                    t: 84,
                                    s: [616.192, 281.704, 0],
                                    to: [0, 0, 0],
                                    ti: [0, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 1,
                                    },
                                    o: {
                                        x: 0.502,
                                        y: 0,
                                    },
                                    t: 234,
                                    s: [616.192, 281.704, 0],
                                    to: [0, 0.833, 0],
                                    ti: [0, -0.833, 0],
                                },
                                {
                                    t: 317,
                                    s: [616.192, 286.704, 0],
                                },
                            ],
                            ix: 2,
                        },
                        a: {
                            a: 0,
                            k: [30.004, 19.085, 0],
                            ix: 1,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 2,
                    nm: 'sourcil gauche',
                    refId: 'image_16',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: 0,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.438,
                                        y: 0.438,
                                    },
                                    t: 19,
                                    s: [675.685, 281.25, 0],
                                    to: [0, 0, 0],
                                    ti: [0, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.333,
                                        y: 0.333,
                                    },
                                    t: 84,
                                    s: [675.685, 281.25, 0],
                                    to: [0, 0, 0],
                                    ti: [0, 0, 0],
                                },
                                {
                                    i: {
                                        x: 0,
                                        y: 0,
                                    },
                                    o: {
                                        x: 0.502,
                                        y: 0.502,
                                    },
                                    t: 234,
                                    s: [675.685, 281.25, 0],
                                    to: [0, 0, 0],
                                    ti: [0, 0, 0],
                                },
                                {
                                    t: 317,
                                    s: [675.685, 281.25, 0],
                                },
                            ],
                            ix: 2,
                        },
                        a: {
                            a: 0,
                            k: [29.926, 19.708, 0],
                            ix: 1,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 2,
                    nm: 'nez',
                    refId: 'image_17',
                    sr: 1,
                    ks: {
                        o: {
                            a: 0,
                            k: 100,
                            ix: 11,
                        },
                        r: {
                            a: 0,
                            k: 0,
                            ix: 10,
                        },
                        p: {
                            a: 0,
                            k: [650.079, 328.538, 0],
                            ix: 2,
                        },
                        a: {
                            a: 0,
                            k: [21.749, 32.533, 0],
                            ix: 1,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    ip: 0,
                    op: 1243,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: 'tasse',
            refId: 'image_0',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [481.851, 879.983, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [62.952, 52.372, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: 'glow.png',
            cl: 'png',
            refId: 'image_1',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [952, 865, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [50, 50, 0],
                    ix: 1,
                },
                s: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 0,
                            s: [125, 125, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 61,
                            s: [95, 95, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 120,
                            s: [125, 125, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 181,
                            s: [95, 95, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 241,
                            s: [125, 125, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 302,
                            s: [95, 95, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 360,
                            s: [125, 125, 100],
                        },
                        {
                            i: {
                                x: [0.833, 0.833, 0.833],
                                y: [0.833, 0.833, 0.833],
                            },
                            o: {
                                x: [0.167, 0.167, 0.167],
                                y: [0.167, 0.167, 0.167],
                            },
                            t: 421,
                            s: [95, 95, 100],
                        },
                        {
                            t: 480,
                            s: [125, 125, 100],
                        },
                    ],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: 'rond ordi',
            refId: 'image_2',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [950.297, 862.334, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [18.109, 15.638, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: 'objets',
            refId: 'image_3',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [662.664, 846.181, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [500.772, 87.442, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 2,
            nm: 'plante',
            refId: 'image_4',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.361],
                                y: [1],
                            },
                            o: {
                                x: [0.622],
                                y: [0],
                            },
                            t: 0,
                            s: [3],
                        },
                        {
                            i: {
                                x: [0.406],
                                y: [1],
                            },
                            o: {
                                x: [0.526],
                                y: [0],
                            },
                            t: 240,
                            s: [11],
                        },
                        {
                            t: 479,
                            s: [3],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [245.565, 912.939, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [176.491, 356.605, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 2,
            nm: 'main gauche',
            parent: 7,
            refId: 'image_5',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0],
                                y: [1],
                            },
                            o: {
                                x: [0.56],
                                y: [0],
                            },
                            t: 11,
                            s: [-19],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.333],
                                y: [0],
                            },
                            t: 98,
                            s: [11],
                        },
                        {
                            i: {
                                x: [0],
                                y: [1],
                            },
                            o: {
                                x: [0.289],
                                y: [0],
                            },
                            t: 272,
                            s: [11],
                        },
                        {
                            t: 403,
                            s: [-19],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [21.573, 16.236, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [51.026, 48.901, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 2,
            nm: 'bras gauche',
            refId: 'image_6',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0],
                                y: [1],
                            },
                            o: {
                                x: [0.546],
                                y: [0],
                            },
                            t: 11,
                            s: [0],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 98,
                            s: [-16],
                        },
                        {
                            i: {
                                x: [0],
                                y: [1],
                            },
                            o: {
                                x: [0.289],
                                y: [0],
                            },
                            t: 272,
                            s: [-16],
                        },
                        {
                            t: 403,
                            s: [0],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [847.511, 776.655, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [164.612, 107.136, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 2,
            nm: 'notpad',
            parent: 6,
            refId: 'image_7',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.523,
                                y: 0,
                            },
                            t: 11,
                            s: [91.897, 61.949, 0],
                            to: [0.465, -3.863, 0],
                            ti: [-0.465, 3.863, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 0,
                            },
                            o: {
                                x: 0.333,
                                y: 0.333,
                            },
                            t: 98,
                            s: [94.687, 38.771, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.289,
                                y: 0,
                            },
                            t: 272,
                            s: [94.687, 38.771, 0],
                            to: [-0.465, 3.863, 0],
                            ti: [0.465, -3.863, 0],
                        },
                        {
                            t: 403,
                            s: [91.897, 61.949, 0],
                        },
                    ],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [94.963, 124.828, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 2,
            nm: 'montre',
            parent: 12,
            refId: 'image_8',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [165.997, 19.708, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [18.603, 20.571, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 2,
            nm: 'main droite',
            parent: 12,
            refId: 'image_9',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.22],
                                y: [1],
                            },
                            o: {
                                x: [0.491],
                                y: [0],
                            },
                            t: 25,
                            s: [0],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 116,
                            s: [31],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 138,
                            s: [19],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 155,
                            s: [31],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 176,
                            s: [40],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 194,
                            s: [25],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 207,
                            s: [19],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 224,
                            s: [31],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 245,
                            s: [40],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 263,
                            s: [25],
                        },
                        {
                            i: {
                                x: [0.04],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 280,
                            s: [25],
                        },
                        {
                            t: 401,
                            s: [0],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [178.431, 12.257, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [27.021, 82.087, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 2,
            nm: 'crayon',
            parent: 10,
            refId: 'image_10',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.22],
                                y: [1],
                            },
                            o: {
                                x: [0.491],
                                y: [0],
                            },
                            t: 25,
                            s: [0],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 116,
                            s: [-57],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 138,
                            s: [-44],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 155,
                            s: [-52],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 176,
                            s: [-59],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 194,
                            s: [-43],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 207,
                            s: [-44],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 224,
                            s: [-52],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 245,
                            s: [-59],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 263,
                            s: [-43],
                        },
                        {
                            i: {
                                x: [0.04],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 280,
                            s: [-43],
                        },
                        {
                            t: 401,
                            s: [0],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [37.613, 26.455, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [36.281, 42.84, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 2,
            nm: 'bras droit',
            refId: 'image_11',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.22],
                                y: [1],
                            },
                            o: {
                                x: [0.491],
                                y: [0],
                            },
                            t: 25,
                            s: [0],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 116,
                            s: [14],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 138,
                            s: [15],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 155,
                            s: [14.5],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 176,
                            s: [15],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 194,
                            s: [16],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 207,
                            s: [15],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 224,
                            s: [14.5],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.167],
                                y: [0],
                            },
                            t: 245,
                            s: [15],
                        },
                        {
                            i: {
                                x: [0.833],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 263,
                            s: [16],
                        },
                        {
                            i: {
                                x: [0.04],
                                y: [1],
                            },
                            o: {
                                x: [0.347],
                                y: [0],
                            },
                            t: 280,
                            s: [16],
                        },
                        {
                            t: 401,
                            s: [0],
                        },
                    ],
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [455.519, 771.424, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [36.074, 110.124, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 2,
            nm: 'meche cheveux',
            parent: 15,
            refId: 'image_12',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.436,
                                y: 0,
                            },
                            t: 19,
                            s: [120.457, 75.888, 0],
                            to: [0, 1.5, 0],
                            ti: [0, -1.5, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 0,
                            },
                            o: {
                                x: 0.333,
                                y: 0.333,
                            },
                            t: 84,
                            s: [120.457, 84.888, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.474,
                                y: 0,
                            },
                            t: 234,
                            s: [120.457, 84.888, 0],
                            to: [0, -1.5, 0],
                            ti: [0, 1.5, 0],
                        },
                        {
                            t: 317,
                            s: [120.457, 75.888, 0],
                        },
                    ],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [68.577, 61.375, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 14,
            ty: 0,
            nm: 'face',
            parent: 15,
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.436,
                                y: 0,
                            },
                            t: 19,
                            s: [90.294, 274.523, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 0,
                            },
                            o: {
                                x: 0.333,
                                y: 0.333,
                            },
                            t: 84,
                            s: [90.294, 287.023, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.474,
                                y: 0,
                            },
                            t: 234,
                            s: [90.294, 287.023, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            t: 317,
                            s: [90.294, 274.523, 0],
                        },
                    ],
                    ix: 2,
                },
                a: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0.667,
                                y: 1,
                            },
                            o: {
                                x: 0.333,
                                y: 0,
                            },
                            t: 53,
                            s: [642.5, 470.5, 0],
                            to: [-0.333, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0.667,
                                y: 1,
                            },
                            o: {
                                x: 0.333,
                                y: 0,
                            },
                            t: 84,
                            s: [640.5, 470.5, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0.667,
                                y: 1,
                            },
                            o: {
                                x: 0.333,
                                y: 0,
                            },
                            t: 125,
                            s: [642.5, 470.5, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0.667,
                                y: 1,
                            },
                            o: {
                                x: 0.333,
                                y: 0,
                            },
                            t: 174,
                            s: [640.5, 470.5, 0],
                            to: [0, 0, 0],
                            ti: [-0.333, 0, 0],
                        },
                        {
                            t: 257,
                            s: [642.5, 470.5, 0],
                        },
                    ],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            w: 1285,
            h: 941,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 15,
            ty: 2,
            nm: 'tête',
            refId: 'image_18',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.436,
                                y: 0,
                            },
                            t: 19,
                            s: [653.587, 299.789, 0],
                            to: [0, 1.5, 0],
                            ti: [0, -1.5, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 0,
                            },
                            o: {
                                x: 0.333,
                                y: 0.333,
                            },
                            t: 84,
                            s: [653.587, 308.789, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.474,
                                y: 0,
                            },
                            t: 234,
                            s: [653.587, 308.789, 0],
                            to: [0, -1.5, 0],
                            ti: [0, 1.5, 0],
                        },
                        {
                            t: 317,
                            s: [653.587, 299.789, 0],
                        },
                    ],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [101.381, 104.813, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 16,
            ty: 2,
            nm: 'oreilles',
            parent: 15,
            refId: 'image_19',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.436,
                                y: 0,
                            },
                            t: 19,
                            s: [100.152, 142.277, 0],
                            to: [0, -1.667, 0],
                            ti: [0, 1.667, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 0,
                            },
                            o: {
                                x: 0.333,
                                y: 0.333,
                            },
                            t: 84,
                            s: [100.152, 132.277, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                        },
                        {
                            i: {
                                x: 0,
                                y: 1,
                            },
                            o: {
                                x: 0.474,
                                y: 0,
                            },
                            t: 234,
                            s: [100.152, 132.277, 0],
                            to: [0, 1.667, 0],
                            ti: [0, -1.667, 0],
                        },
                        {
                            t: 317,
                            s: [100.152, 142.277, 0],
                        },
                    ],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [67.968, 19.448, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 17,
            ty: 2,
            nm: 'ombre cou',
            parent: 15,
            refId: 'image_20',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [100.134, 204.74, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [34.093, 16.072, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 18,
            ty: 2,
            nm: 'corps',
            refId: 'image_21',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [651.579, 654.959, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [227.657, 271.314, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 19,
            ty: 2,
            nm: 'back',
            refId: 'image_22',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [642.695, 470.5, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [642.555, 470.75, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            ip: 0,
            op: 1243,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
}
