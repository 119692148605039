import React from 'react'

import { View } from '../../../components/View'

import { WorkDetailsContainer } from '../components/Work'

const WorkDetailsView = ({ location }) => (
    <View>
        <WorkDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default WorkDetailsView
