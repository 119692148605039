import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import axios from 'axios'

import { openSidebar } from '../../../../actions/sidebarActions'

import { Button } from '../../../../components/Button'
import { Form, FormInput, FormItem, FormLabel } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Heading } from '../../../../components/Heading'

import { createIdeaComment } from '../../actions/ideaActions'

import commentImage from '../../../../assets/images/idea_comment.png'
import './_ideaDetailsCommentForm.scss'

const IdeaDetailsCommentForm = ({
    isAuthenticated,
    user,
    form,
    form: { getFieldError, getFieldValue, validateFields },
    idea,
    translate,
    openSidebar,
    backLink = false,
}) => {
    const [respondAs, setRespondAs] = useState(isAuthenticated ? user.id : null)
    const [isFinished, setIsFinished] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setRespondAs(isAuthenticated ? user.id : null)
    }, [isAuthenticated, user])

    const COMMENT_FIELD = 'comment'

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (formErrors || isLoading) {
                return false
            }

            setIsLoading(true)

            const formData = {
                content: formValues[COMMENT_FIELD],
                idea: idea.id,
                user: respondAs,
            }

            await createIdeaComment(formData, { cancelToken: signal.token })
                .catch(err => null)
                .finally(() => setIsLoading(false))

            setIsFinished(true)
        })
    }

    if (isFinished) {
        // window.scrollTo({ top: 0, behavior: 'smooth' })

        return (
            <div className="u-textCenter">
                <img
                    className="ideaDetailsCommentForm_finished_image"
                    src={commentImage}
                    alt={<Translation value="idea.details.comment.finished.title" />}
                />

                <Heading el="h4" option={['h1']}>
                    {/* Message : Commentaire soumis! */}
                    <Translation value="idea.details.comment.finished.title" />
                </Heading>

                {/* Message : Merci votre commentaire va être lu par la ville et publié si il est pertinent. */}
                <p className="ideaDetailsCommentForm_finished_description">
                    <Translation value="idea.details.comment.finished.description" />
                </p>

                {backLink && (
                    <div className="ideaDetailsCommentForm_finished_button">
                        <Button
                            option={['large', 'green', 'icon', 'iconLeft']}
                            icon="arrow-left"
                            href={backLink}
                        >
                            <Translation value={'idea.details.comment.finished.back'} />
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="ideaDetailsCommentForm">
            <Heading el="h3" option={['h3']} utility={['blue']}>
                <Translation value="idea.details.comment.title" />
            </Heading>

            <p>
                <Translation value="idea.details.comment.description" />
            </p>

            <div className="ideaDetailsCommentForm_section">
                <div className="ideaDetailsCommentForm_buttons">
                    <Button
                        onClick={() => setRespondAs(null)}
                        className={
                            'ideaDetailsCommentForm_buttons_item' +
                            (respondAs == null ? ' is-selected' : '')
                        }
                        option={['white', 'interactionBlue']}
                    >
                        <Translation value="idea.details.comment.form.respond.anonymously.title" />
                    </Button>

                    {!isAuthenticated ? (
                        <Button
                            onClick={() => openSidebar()}
                            className={'ideaDetailsCommentForm_buttons_item'}
                            option={['white']}
                        >
                            <Translation value="hub.loginForm.login" />
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setRespondAs(user.id)}
                            className={
                                'ideaDetailsCommentForm_buttons_item' +
                                (respondAs === user.id ? ' is-selected' : '')
                            }
                            option={['white', 'interactionBlue']}
                        >
                            <Translation
                                value="idea.details.comment.form.respond.as_user.title"
                                data={{ user: user.firstname }}
                            />
                        </Button>
                    )}
                </div>
            </div>

            <Form onSubmit={handleSubmit}>
                <FormItem>
                    <FormLabel inputId={COMMENT_FIELD} utility={['textLeft']}>
                        <Translation value="idea.details.comment.form.title" />
                    </FormLabel>
                    <FormInput
                        status={[getFieldError(COMMENT_FIELD) && 'error']}
                        form={form}
                        id={COMMENT_FIELD}
                        name={COMMENT_FIELD}
                        options={{
                            initialValue: '',
                            validate: [
                                {
                                    trigger: 'onBlur',
                                    rules: [
                                        {
                                            required: true,
                                            message: translate(
                                                'idea.details.comment.form.errors.required'
                                            ),
                                        },
                                    ],
                                },
                            ],
                        }}
                        type="textarea"
                        maxLength="140"
                    />
                </FormItem>

                <FormItem option={['noMargin']}>
                    <Button disabled={isLoading} option={['full', 'large']} type="submit">
                        <Translation value="idea.details.comment.form.submit" />
                    </Button>
                </FormItem>
            </Form>
        </div>
    )
}

IdeaDetailsCommentForm.propTypes = {
    idea: PropTypes.object.isRequired,
    form: formShape,
    // lang: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(createForm()(IdeaDetailsCommentForm)))
