import React from 'react'

import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'

import './_link.scss'

const Link = props => {
    const {
        children,
        className,
        href = null,
        route = null,
        external = false,
        onClick = () => {},
        ...classProps
    } = props

    let customClass = 'link'
    customClass = className ? className + ' ' + customClass : customClass
    const classes = renderClasses(customClass, classProps)

    return route !== null ? (
        <RouterLink className={classes} to={renderRoute(route)}>
            {children}
        </RouterLink>
    ) : external ? (
        <a className={classes} href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    ) : href ? (
        <RouterLink className={classes} to={href}>
            {children}
        </RouterLink>
    ) : (
        <button className={classes} type="button" onClick={onClick}>
            {children}
        </button>
    )
}

Link.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default Link
