import React from 'react'

// import PropTypes from 'prop-types'

import { Translation } from '../Translation'
import { Button } from '../Button'

import { renderClasses } from '../../utils'

const FilterButtonsItem = ({ selected, href, label, onClick = () => {}, ...classProps }) => {
    return (
        <div className={renderClasses('filterButtons_item', classProps)}>
            <Button
                option={['white', 'full']}
                status={[selected && 'selected']}
                href={href}
                onClick={onClick}
            >
                <Translation value={label} />
            </Button>
        </div>
    )
}

FilterButtonsItem.propTypes = {}

export default FilterButtonsItem
