import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ALL_BUTTON_CATEGORIES_VALUE } from '../../../../config'
import { Wrapper } from '../../../../components/Wrapper'
// import { Card } from '../../../../components/Card'
// import { CardHeader } from '../../../../components/Card'
import { FilterPicker } from '../../../../components/FilterPicker'
import { Section } from '../../../../components/Section'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { CardFeedback } from '../../../../components/CardFeedback'
import { localizePath, findServiceByType } from '../../../../utils'

import { SERVICE_IDEA_IDEAS_SUBMIT } from '../../config'
import { IdeaCard } from './'

import './_ideaList.scss'

const IdeaList = ({ ideas, categories, serviceIdeasSubmit = false }) => {
    const [selectedCategory, setSelectedCategory] = useState(ALL_BUTTON_CATEGORIES_VALUE)

    if (!ideas.length) {
        return (
            <Wrapper>
                <CardFeedback text="idea.listing.placeholder.description">
                    <Button
                        option={['blue', 'iconLeft']}
                        icon="arrow-left"
                        href={localizePath(serviceIdeasSubmit.slug)}
                    >
                        <Translation value="idea.suggest.form.submit.title" />
                    </Button>
                </CardFeedback>
            </Wrapper>
        )
    }

    const displayedIdeas = ideas.map((idea, index) => {
        const category = _.find(categories, category => category.id === idea.category)

        return (
            <IdeaCard
                idea={idea}
                category={category}
                key={index}
                selectedCategory={selectedCategory}
            />
        )
    })

    // filter categories from existing related question
    const ideasCategoriesIds = ideas.map(idea => idea.category)
    const filteredCategories = _.filter(categories, cat =>
        !_.isNil(_.find(ideasCategoriesIds, ideaCatId => ideaCatId === cat.id)) ? true : false
    )

    return (
        <React.Fragment>
            <Wrapper>
                <Section>
                    <FilterPicker
                        categories={filteredCategories}
                        triggerSelection={categoryId => setSelectedCategory(categoryId)}
                    />
                </Section>
            </Wrapper>

            <Wrapper>
                <div className="u-textCenter u-margin-vertical">
                    <Button href={localizePath(serviceIdeasSubmit.slug)}>
                        <Translation value={serviceIdeasSubmit.name} />
                    </Button>
                </div>
            </Wrapper>

            <TransitionGroup>
                <CSSTransition
                    key={selectedCategory}
                    classNames="has-transition"
                    timeout={300}
                    appear
                >
                    <div className="ideaList">
                        <Wrapper option={['medium']}>{displayedIdeas}</Wrapper>
                    </div>
                </CSSTransition>
            </TransitionGroup>

            <Wrapper>
                <div className="u-textCenter u-margin-top">
                    <Button href={localizePath(serviceIdeasSubmit.slug)}>
                        <Translation value={serviceIdeasSubmit.name} />
                    </Button>
                </div>
            </Wrapper>
        </React.Fragment>
    )
}

IdeaList.propTypes = {
    ideas: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    serviceIdeasSubmit: findServiceByType(state.hub.services.entities, SERVICE_IDEA_IDEAS_SUBMIT),
})

IdeaList.Placeholder = () => <ContentLoader></ContentLoader>

export default connect(mapStateToProps)(IdeaList)
