import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/flash-surveys`

/**
 * Retrieve flash survey details.
 */
export const fetchFlashSurvey = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}

/**
 * Create flash surveu answer.
 */
export const createFlashSurveyAnswer = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}/answers`, formData, config)
}

/**
 * Retrieve flash survey user responses data.
 */
export const fetchFlashSurveyUserSelf = (config = {}) => {
    return apiClient.get(`${baseUrl}/users/self`, config)
}
