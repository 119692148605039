import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors, localizePath } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { fetchSurveyBySlug } from '../../actions/surveyActions'
import { fetchSurveyUserSelf } from '../../actions/surveyUserActions'
import { createSurveyAnswer, logSurveyAnswer } from '../../actions/answerActions'

import { SurveyDetails } from '.'

const SurveyDetailsContainer = ({ slug, loadUserSelfSurveys }) => {
    const useFetchSurvey = useCallback(() => {
        return fetchSurveyBySlug(slug)
    }, [slug])
    const survey = useFetchItems(useFetchSurvey)

    useEffect(() => {
        if (survey.items.isExternal) {
            window.location.href = localizePath(survey.items.externalSurveyUrl)
        }
    }, [survey])

    const useFetchSurveyUserSelf = useCallback(() => {
        if (!loadUserSelfSurveys) {
            // used to dispatch from type and set isLoading to false
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return fetchSurveyUserSelf()
    }, [loadUserSelfSurveys])
    const userSurveys = useFetchItems(useFetchSurveyUserSelf)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, survey, userSurveys, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {survey.requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <SurveyDetails.Placeholder />
                ) : (
                    <SurveyDetails
                        survey={survey.items}
                        userSurveys={userSurveys.items}
                        logSurveyAnswer={logSurveyAnswer}
                        createSurveyAnswer={createSurveyAnswer}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}
const mapStateToProps = state => ({
    loadUserSelfSurveys: state.hub.auth.isAuthenticated ? true : false,
})

export default connect(mapStateToProps)(SurveyDetailsContainer)
