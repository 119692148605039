import _ from 'lodash'

export const parseServices = items => {
    return _.compact(
        _.map(items, item => {
            if (_.isEmpty(item.type)) {
                return null
            }
            const { type } = item
            item.module = type.split('/')[1]
            return item
        })
    )
}
