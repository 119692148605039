import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchSurveys } from '../../actions/surveyActions'
import { fetchCategories } from '../../actions/categoryActions'
import { fetchSurveyUserSelf } from '../../actions/surveyUserActions'

import { SurveyList } from '.'

const SurveyListContainer = ({ filter, loadUserSelfSurveys, url }) => {
    const useFetchSurveys = useCallback(() => {
        return fetchSurveys(filter)
    }, [filter])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const useFetchSurveyUserSelf = useCallback(() => {
        if (!loadUserSelfSurveys) {
            // used to dispatch from type and set isLoading to false
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return fetchSurveyUserSelf()
    }, [loadUserSelfSurveys])

    const surveys = useFetchItems(useFetchSurveys)
    const categories = useFetchItems(useFetchCategories)
    const userSurveys = useFetchItems(useFetchSurveyUserSelf)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, surveys, categories, userSurveys, responseCustomizer)
    useDev(logErrors(result.error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <SurveyList.Placeholder />
                ) : (
                    <SurveyList
                        surveys={surveys.items}
                        categories={categories.items}
                        userSurveys={userSurveys.items}
                        filter={filter}
                        url={url}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    loadUserSelfSurveys: state.hub.auth.isAuthenticated ? true : false,
})

export default connect(mapStateToProps)(SurveyListContainer)
