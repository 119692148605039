import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { isTranslationObject, translation } from '../../utils'

import { Wrapper } from '../Wrapper'
import { Icon } from '../Icon'

import { ContentBlockHeader } from '.'

import './_contentVideo.scss'
import './_contentBlock.scss'

const ContentBlockVideo = ({ title, video: { image, iframe, provider, src }, id }) => {
    const [showVideo, setShowVideo] = useState(false)

    const embed = provider === 'vimeo' ? `${src}&autoplay=1` : `${src}?rel=0&autoplay=1`

    return (
        <div className="contentBlock" id={id}>
            <Wrapper option={['medium']}>
                {title && <ContentBlockHeader title={title} />}

                <div className="contentVideo">
                    {showVideo ? (
                        <div className="contentVideo_embed">
                            <iframe
                                src={embed}
                                allow={'autoplay; fullscreen'}
                                title={title}
                            ></iframe>
                        </div>
                    ) : (
                        <div
                            className="contentVideo_thumb"
                            onClick={() => setShowVideo(!showVideo)}
                        >
                            <button className="contentVideo_button" type="button">
                                <div className="contentVideo_button_play">
                                    <Icon name="play" />
                                </div>
                            </button>

                            {image && (
                                <img
                                    className="contentVideo_image"
                                    src={image}
                                    alt={title && translation(title)}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Wrapper>
        </div>
    )
}

ContentBlockVideo.propTypes = {
    title: isTranslationObject,
    video: PropTypes.shape({
        image: PropTypes.string,
        iframe: PropTypes.string,
        provider: PropTypes.string,
        src: PropTypes.string,
    }).isRequired,
    id: PropTypes.string,
}

export default ContentBlockVideo
