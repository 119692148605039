import React from 'react'
import { ButtonCheckbox } from '../../../../components/Button'
import { FormItem } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { translation } from '../../../../utils'

const QuestionChoiceRadioRowsTwo = ({
    question: { id, choices, required },
    form,
    form: { getFieldValue, getFieldError, setFieldsInitialValue, isFieldTouched },
    formValues = {},
}) => (
    <Wrapper option={['small']}>
        {choices.map(choice => (
            <FormItem option={['medium', 'anim']} key={choice.id}>
                <ButtonCheckbox
                    id={choice.id}
                    type="radio"
                    form={form}
                    status={[getFieldError(id) && 'error']}
                    // status={[(choice.image ? 'image' : '')]}
                    value={choice.id}
                    option={['full']}
                    checked={formValues[id] === choice.id && true}
                    name={id}
                    options={{
                        preserve: getFieldValue(id) ? true : false,
                        validate: [
                            {
                                trigger: 'onChange',
                                rules: [
                                    {
                                        required: required,
                                    },
                                ],
                            },
                        ],
                    }}
                >
                    {choice.image && <img src={'/' + translation(choice.image)} alt="" />}
                    <Translation value={choice.content} isHtml />
                </ButtonCheckbox>
            </FormItem>
        ))}
    </Wrapper>
)

export default QuestionChoiceRadioRowsTwo
