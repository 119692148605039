import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../../../../components/Icon'
import { Translation } from '../../../../components/Translation'

import { isTranslationObject, formatDate } from '../../../../utils'

import './_alertCategory.scss'

const AlertCategory = ({ category, date, featured }) => (
    <div className={'alertCategory' + (featured ? ' -featured' : '')}>
        {date && (
            <div>
                <Translation value={category.name} />
                <br />
                {formatDate(date)}
            </div>
        )}
        <div className="alertCategory_icon">
            <Icon name={'memo-' + category.icon} />
        </div>
    </div>
)

AlertCategory.propTypes = {
    featured: PropTypes.bool,
    category: PropTypes.shape({
        name: isTranslationObject,
        icon: PropTypes.string,
    }),
}

export default AlertCategory
