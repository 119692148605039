import {
    DELETE_USER_SUBSCRIPTION_SELF_REQUEST,
    DELETE_USER_SUBSCRIPTION_SELF_SUCCESS,
    DELETE_USER_SUBSCRIPTION_SELF_FAILURE,
    FETCH_USER_SUBSCRIPTION_SELF_REQUEST,
    FETCH_USER_SUBSCRIPTION_SELF_SUCCESS,
    FETCH_USER_SUBSCRIPTION_SELF_FAILURE,
    UPDATE_USER_SUBSCRIPTION_SELF_REQUEST,
    UPDATE_USER_SUBSCRIPTION_SELF_SUCCESS,
    UPDATE_USER_SUBSCRIPTION_SELF_FAILURE,
} from '../actions/subscriptionActions'

const initialState = null

export function subscription(state = initialState, action) {
    switch (action.type) {
        /**
         * Delete the authenticated user's subscription profile.
         */
        case DELETE_USER_SUBSCRIPTION_SELF_REQUEST:
            return {
                ...state,
            }
        case DELETE_USER_SUBSCRIPTION_SELF_SUCCESS:
            return {
                ...initialState,
            }
        case DELETE_USER_SUBSCRIPTION_SELF_FAILURE:
            return {
                ...state,
            }

        /**
         * Retrieve the authenticated user's subscription profile.
         */
        case FETCH_USER_SUBSCRIPTION_SELF_REQUEST:
            return {
                ...state,
            }
        case FETCH_USER_SUBSCRIPTION_SELF_SUCCESS:
            return action.result.data

        case FETCH_USER_SUBSCRIPTION_SELF_FAILURE:
            return {
                ...initialState,
            }

        /**
         * Update the authenticated user's subscription profile.
         */
        case UPDATE_USER_SUBSCRIPTION_SELF_REQUEST:
            return {
                ...state,
            }
        case UPDATE_USER_SUBSCRIPTION_SELF_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_USER_SUBSCRIPTION_SELF_FAILURE:
            return {
                ...state,
            }

        default:
            return state
    }
}
