import _ from 'lodash'
import PropTypes from 'prop-types'

const languages = process.env.REACT_APP_LOCALE_LIST.split(',')
export const isTranslationObject = (props, propName, componentName) => {
    if (
        !_.isNull(props[propName]) &&
        !languages.some(lang => Object.keys(props[propName]).includes(lang))
    ) {
        return new Error(
            `Invalid prop ${propName} passed to ${componentName}. Expected a valid language object.`
        )
    }
}

export const childrenPropType = (isRequired = false) => {
    const propType = PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.element,
    ])

    return isRequired ? propType.isRequired : propType
}
