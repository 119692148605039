import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'

import { View } from '../../../components/View'
import { deleteSelfSubscription } from '../actions/subscriptionActions'
import { NotFound } from '../../../components/NotFound'
import { openSidebar } from '../../../actions/sidebarActions'

import { fetchSelf } from '../../hub/actions/userActions'

import { HeaderPage } from '../../../components/HeaderPage'
import { Heading } from '../../../components/Heading'
import { Wrapper } from '../../../components/Wrapper'
import { Button } from '../../../components/Button'
import { Translation } from '../../../components/Translation'
import { Card } from '../../../components/Card'
import { Spinner } from '../../../components/Spinner'

import confirmationImage from '../../../assets/images/idea_comment.png'

const MemoUnsubscribeView = ({
    isAuthenticated,
    deleteSelfSubscription,
    hasMemoSubscription,
    fetchSelf,
    openSidebar,
}) => {
    const [isConfirmation, setIsConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleUnsubscribeClick = useCallback(() => {
        setIsLoading(true)
        deleteSelfSubscription().then(() => {
            fetchSelf()
            setIsConfirmation(true)
            setIsLoading(false)
        })
    }, [deleteSelfSubscription, fetchSelf])

    return (
        <View>
            {isAuthenticated && !hasMemoSubscription && !isConfirmation ? (
                <NotFound />
            ) : (
                <div>
                    <HeaderPage
                        heading="memo.unsubscription.title"
                        breadcrumb="memo.subscription.contact.title"
                        icon="subscribe"
                    />
                    <Wrapper>
                        <Card option={['paddingLarge']}>
                            <Wrapper option={['medium']}>
                                <div className="u-textCenter">
                                    <img
                                        className="paymentDetails_finished_image"
                                        src={confirmationImage}
                                        alt={
                                            <Translation value="payment.form.confirmation.title" />
                                        }
                                    />

                                    {isLoading ? (
                                        <Spinner />
                                    ) : (
                                        <React.Fragment>
                                            {isConfirmation ? (
                                                <React.Fragment>
                                                    <div className="u-margin-bottom-small">
                                                        <Heading option={['h2']}>
                                                            <Translation value="memo.unsubscription.success" />
                                                        </Heading>
                                                    </div>

                                                    <Button
                                                        option={[
                                                            'large',
                                                            'green',
                                                            'icon',
                                                            'iconLeft',
                                                        ]}
                                                        icon="arrow-left"
                                                        route="home"
                                                    >
                                                        <Translation value="hub.subscription.edit.button" />
                                                    </Button>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="u-margin-bottom-small">
                                                        <Heading option={['h3']}>
                                                            {isAuthenticated ? (
                                                                <Translation value="memo.unsubscription.description" />
                                                            ) : (
                                                                <Translation value="memo.unsubscription.connect.description" />
                                                            )}
                                                        </Heading>
                                                    </div>
                                                    {isAuthenticated ? (
                                                        <Button
                                                            option={['large', 'red']}
                                                            onClick={handleUnsubscribeClick}
                                                        >
                                                            <Translation value="memo.unsubscription.label" />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            option={['large']}
                                                            onClick={openSidebar}
                                                        >
                                                            <Translation value="memo.unsubscription.connect.label" />
                                                        </Button>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Wrapper>
                        </Card>
                    </Wrapper>
                </div>
            )}
        </View>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
    fetchSelf: () => dispatch(fetchSelf()),
    deleteSelfSubscription: () => dispatch(deleteSelfSubscription()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MemoUnsubscribeView)
