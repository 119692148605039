import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import {
    QuestionChoiceCheckbox,
    QuestionChoiceRadio,
    QuestionChoiceRadioMood,
    QuestionChoiceRadioRowsTwo,
    QuestionTextarea,
} from '../Question'

const FlashSurveyChoiceManager = ({ question, question: { type, view }, form }) => (
    <Fragment>
        {type === 'checkbox' && view === 'default' ? (
            <QuestionChoiceCheckbox question={question} form={form} />
        ) : type === 'radio' && view === 'default' ? (
            <QuestionChoiceRadio question={question} form={form} />
        ) : type === 'radio' && view === 'mood' ? (
            <QuestionChoiceRadioMood color="green" question={question} form={form} />
        ) : type === 'radio' && view === 'row_2' ? (
            <QuestionChoiceRadioRowsTwo question={question} form={form} />
        ) : type === 'textarea' && view === 'default' ? (
            <QuestionTextarea question={question} form={form} />
        ) : null}
    </Fragment>
)

FlashSurveyChoiceManager.propTypes = {
    question: PropTypes.shape({
        choices: PropTypes.array.isRequired,
        view: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),
}

export default FlashSurveyChoiceManager
