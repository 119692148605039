import { connectRouter } from 'connected-react-router'
import { localizeReducer } from 'react-localize-redux'
import { combineReducers } from 'redux'

import { requestStates } from './api'
import { routes } from './routes'
import { sidebarReducer } from './sidebar'
import { lightboxReducer } from './lightbox'
import { navReducer } from './nav'
import HubModule from '../modules/hub'
import MemoModule from '../modules/memo'
import WorkModule from '../modules/project'
import IdeaModule from '../modules/idea'
import ContactModule from '../modules/contact'
import { isServer } from '../utils'

const appState = (state = { isSsr: isServer }, action) => state

const appReducer = history =>
    combineReducers({
        app: appState,
        router: connectRouter(history),
        localize: localizeReducer,
        routes,
        hub: HubModule.reducer,
        memo: MemoModule.reducer,
        work: WorkModule.reducer, // TODO: Refactor Work/Project Module
        idea: IdeaModule.reducer,
        contact: ContactModule.reducer,
        requestStates,
        sidebar: sidebarReducer,
        lightbox: lightboxReducer,
        nav: navReducer,
    })

const rootReducer = history => (state, action) => {
    if (action.type === 'DELETE_USER_LOGIN_SUCCESS') {
        // If you want to keep some data in store on DELETE_USER_LOGIN_SUCCESS,
        // here is where you do it.
        // const { dataToKeep } = state
        // state = { dataToKeep }
    }
    return appReducer(history)(state, action)
}

export default rootReducer
