import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// import { useDev, logErrors } from '../../../../utils'

// import { fetchOnboardingPanels } from '../../actions/onboardingActions'

// import { useFetchItems } from '../../../../utils/containers'

import { Onboarding } from '../Onboarding'
import { LOCALSTORAGE_ONBOARDING_DISPLAYED } from '../../config'

const OnboardingContainer = ({ hasViewedOnboarding, setIsViewed = () => {} }) => {
    /*
    const useFetchOnboardingPanels = useCallback(() => {
        if (isViewed) {
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return fetchOnboardingPanels()
    }, [isViewed])

    const { items, error, isLoading } = useFetchItems(useFetchOnboardingPanels)
    useDev(logErrors(error))
    */

    const error = null
    const isLoading = false

    return (
        <div>
            {isLoading || !_.isNil(error) ? (
                <Onboarding.Placeholder />
            ) : (
                <Onboarding
                    onboardingViewed={() => {
                        setIsViewed(true)
                        localStorage.setItem(LOCALSTORAGE_ONBOARDING_DISPLAYED, true)
                    }}
                />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    hasViewedOnboarding: state.hub.hasViewedOnboarding,
})

export default connect(mapStateToProps)(OnboardingContainer)
