import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/ideas`

/**
 * Retrieve idea listing.
 */
export const fetchIdeas = (filter, config = {}) => {
    if (filter) {
        return apiClient.get(`${baseUrl}?status=${filter}`, config)
    }
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Create idea.
 */
export const createIdea = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}`, formData, config)
}

/**
 * Retrieve idea details by slug.
 */
export const fetchIdeaBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}

/**
 * Retrieve idea details.
 */
export const fetchIdea = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}

/**
 * Retrieve idea's comments listing.
 */
export const fetchIdeaComments = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}/comments`, config)
}

/**
 * Retrieve idea comments by slug.
 */
export const fetchIdeaCommentsBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}/comments?slug=${slug}`, config)
}

/**
 * Create idea's comment.
 */
export const createIdeaComment = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}/comments`, formData, config)
}
