import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { ContentBlockTableRow } from '.'
import { Wrapper } from '../../../components/Wrapper'
import { ContentBlockHeader } from '../'

import { isTranslationObject } from '../../../utils'

import './_contentTable.scss'
import '../_contentBlock.scss'

const ContentBlockTable = ({ title, rows, id }) => (
    <div className="contentBlock" id={id}>
        <Wrapper option={['medium']}>
            {title && <ContentBlockHeader title={title} />}
            <ul className="contentTable">
                {rows.map((row, index) => {
                    const rowProps = _.omit(row, 'type')
                    if (row.type === 'table-row') {
                        return <ContentBlockTableRow key={index} {...rowProps} />
                    }
                    return null
                })}
            </ul>
        </Wrapper>
    </div>
)

ContentBlockTable.propTypes = {
    title: isTranslationObject,
    rows: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default ContentBlockTable
