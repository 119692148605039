import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/zones`

/**
 * Retrieve zones listing.
 */
export const fetchZones = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve zones details.
 */
export const fetchZone = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
