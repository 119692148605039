import React, { useState } from 'react'

import { Translation } from '../../Translation'
import { isTranslationObject } from '../../../utils'

import { Content } from '../../Content'

const ContentBlockAccordionPanel = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <li className={'contentAccordion_row' + (isOpen ? ' is-open' : '')}>
            <button
                className="contentAccordion_row_label"
                onClick={() => setIsOpen(!isOpen)}
                type="button"
            >
                <span className="contentAccordion_row_label_plus"></span>
                {title && <Translation value={title} />}
            </button>

            <div className="contentAccordion_row_content">
                <Content>{description && <Translation isHtml value={description} />}</Content>
            </div>
        </li>
    )
}

ContentBlockAccordionPanel.propTypes = {
    title: isTranslationObject,
    description: isTranslationObject,
}

export default ContentBlockAccordionPanel
