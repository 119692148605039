import moment from 'moment'

import {
    FETCH_SERVICE_CATEGORY_LIST_REQUEST,
    FETCH_SERVICE_CATEGORY_LIST_SUCCESS,
    FETCH_SERVICE_CATEGORY_LIST_FAILURE,
} from '../actions/serviceCategoryActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function serviceCategories(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICE_CATEGORY_LIST_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_SERVICE_CATEGORY_LIST_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_SERVICE_CATEGORY_LIST_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
