import PropTypes from 'prop-types'
import React from 'react'

import { isTranslationObject } from '../../../../utils'

import { Translation } from '../../../../components/Translation'
import { Heading } from '../../../../components/Heading'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Button } from '../../../../components/Button'
import { HeroCharacter } from '../HeroCharacter'

const HeaderHeroGuest = props => {
    const {
        options: { title, description, subscriptionlabel },
    } = props

    return (
        <div className="headerHero">
            <Layout option={['full']}>
                <LayoutItem utility={['1/2@from-small']}>
                    <HeroCharacter {...props} />
                </LayoutItem>
                <LayoutItem utility={['1/2@from-small']}>
                    <div className="headerHero_content">
                        <div className="headerHero_content_wrap">
                            <div className="headerHero_title">
                                <Heading option={['h2']} el="h1">
                                    <Translation value={title} />
                                </Heading>
                            </div>
                            <div className="headerHero_text">
                                <Translation value={description} isHtml />
                            </div>
                            <div className="headerHero_buttons">
                                <Button
                                    option={['large', 'green', 'iconLeft']}
                                    icon="check"
                                    route="hub-subscription"
                                >
                                    <Translation value={subscriptionlabel} />
                                </Button>
                                {/*<Button
                                    option={['small', 'gray', 'iconRight']}
                                    icon="arrow-right"
                                    onClick={openSidebar}
                                >
                                    <Translation value={connectionLabel} />
                                </Button>*/}
                            </div>
                        </div>
                    </div>
                </LayoutItem>
            </Layout>
        </div>
    )
}

HeaderHeroGuest.propTypes = {
    options: PropTypes.shape({
        title: isTranslationObject,
        description: isTranslationObject,
        subscriptionlabel: isTranslationObject,
        greeting: isTranslationObject,
    }).isRequired,
}

export default HeaderHeroGuest
