import React, { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import queryString from 'query-string'
import ContentLoader from 'react-content-loader'

import { createForm, formShape } from 'rc-form'
import _ from 'lodash'
import axios from 'axios'

import { Button, ButtonCheckbox } from '../../../../components/Button'
import { Wrapper } from '../../../../components/Wrapper'
import { Card, CardHeader } from '../../../../components/Card'
import { CardWrapper } from '../../../../components/CardWrapper'
import { CardIcon } from '../../../../components/CardIcon'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Translation } from '../../../../components/Translation'
import { Link } from '../../../../components/Link'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Form, FormError, FormInput, FormItem, FormLabel } from '../../../../components/Form'
import { renderRoute, verifySameField, translation } from '../../../../utils'

import { LOCALSTORAGE_SUBSCRIBED_USERID, LOCALSTORAGE_SUBSCRIBED_USER_EMAIL } from '../../config'
import { createUser, checkDuplicate } from '../../actions/userActions'
import { createSubscription } from '../../../memo/actions/subscriptionActions'

import { SubscriptionAddressForm } from './'

const SubscriptionForm = ({
    lang,
    translate,
    // form props
    form,
    form: { getFieldError, getFieldValue, validateFields },
    // user props
    isAuthenticated,
    user,
    hasMemoSubscription,
    updateSelf,
    fetchSelf,
    termsOfUse,
    privacyPolicy,
    memoSettings: {
        general: { emailEnabled, smsEnabled, voiceEnabled },
    },
    memoCategories,
}) => {

    const LASTNAME_FIELD = 'lastname'
    const FIRSTNAME_FIELD = 'firstname'
    const EMAIL_FIELD = 'email'
    const EMAIL_CONFIRMATION_FIELD = 'emailConfirmation'
    const PASSWORD_FIELD = 'password'
    const PASSWORD_CONFIRMATOIN_FIELD = 'passwordConfirmation'
    const ADDRESS_FIELD = 'address'
    const POSTAL_CODE_FIELD = 'postalCode'

    const SUBSCRIBE_ALERTS_FIELD = 'subscribeAlerts'
    const CONFIRM_FIELD = 'confirm'

    const isMemoSubscriptionBypassed = (
        memoCategories.length <= 1
        && memoCategories[0].required
        && emailEnabled && !smsEnabled && !voiceEnabled
    )

    const [isFinished, setIsFinished] = useState(false)
    const [isFinishedMemoBypassed, setIsFinishedMemoBypassed] = useState(false)
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { search } = useLocation()

    const checkDuplicateEmail = async value => {
        if (isAuthenticated && user.email === value) {
            return true
        }

        const response = await checkDuplicate(
            { email: value },
            { cancelToken: axios.CancelToken.source().token }
        )
            .then(res => !res.data.email)
            .catch(err => false)

        return new Promise((resolve, reject) => {
            if (response) resolve()
            else reject(false)
        })
    }

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (isLoading) {
                return false
            }

            if (formErrors) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                return false
            }

            setIsLoading(true)

            let userFields = _.omit(formValues, [
                EMAIL_CONFIRMATION_FIELD,
                PASSWORD_CONFIRMATOIN_FIELD,
                SUBSCRIBE_ALERTS_FIELD,
                CONFIRM_FIELD,
            ])

            userFields.lang = lang

            if (!isAuthenticated) {
                const parsedParam = queryString.parse(search)
                if (parsedParam.extra) {
                    try {
                        const { subscriptionData } = JSON.parse(parsedParam.extra)
                        userFields.extraSubscriptionData = subscriptionData
                    } catch (e) {}
                }

                const userId = await createUser(userFields, { cancelToken: signal.token })
                    .then(res => res.data.userId)
                    .catch(err => null)

                if (!userId) {
                    return
                }

                // set userId for MemoSubscription
                localStorage.setItem(LOCALSTORAGE_SUBSCRIBED_USERID, userId)
                localStorage.setItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL, formValues[EMAIL_FIELD])

                // By pass to automatically register user to memo alerts subscription
                // Should be executed when only email avaiable
                // and only 1 category enabled (and that category need to be required)
                if (formValues[SUBSCRIBE_ALERTS_FIELD] && isMemoSubscriptionBypassed) {

                    const subscriptionFields = {
                        user: userId,
                        email: formValues[EMAIL_FIELD],
                        categories: [ memoCategories[0].id ],
                        emailEnabled: true,
                        smsEnabled: false,
                        voiceEnabled: false,
                        lang: lang
                    }

                    await createSubscription(subscriptionFields, { cancelToken: signal.token })
                        .then(res => res.data)
                        .catch(err => null)

                    setIsFinishedMemoBypassed(true)
                } else {
                    localStorage.setItem(LOCALSTORAGE_SUBSCRIBED_USERID, userId)
                    localStorage.setItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL, formValues[EMAIL_FIELD])
                }

            } else {
                // By pass to automatically register user to memo alerts subscription
                // Should be executed when only email avaiable
                // and only 1 category enabled (and that category need to be required)
                if (formValues[SUBSCRIBE_ALERTS_FIELD] && isMemoSubscriptionBypassed) {

                    const subscriptionFields = {
                        user: user.id,
                        email: formValues[EMAIL_FIELD],
                        categories: [ memoCategories[0].id ],
                        emailEnabled: true,
                        smsEnabled: false,
                        voiceEnabled: false,
                        lang: lang
                    }

                    await createSubscription(subscriptionFields, { cancelToken: signal.token })
                        .then(res => res.data)
                        .catch(err => null)

                    setIsFinishedMemoBypassed(true)
                }

                userFields.id = user.id
                await updateSelf(userFields)
                // retrieve new user infos
                fetchSelf()
            }

            if (formValues[SUBSCRIBE_ALERTS_FIELD] && !isMemoSubscriptionBypassed) {
                setShouldRedirect(true)
            }

            setIsLoading(false)
            setIsFinished(true)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    return shouldRedirect ? (
        <Redirect to={renderRoute('memo-subscription')} />
    ) : (
        <TransitionGroup>
            <CSSTransition key={isFinished} classNames="has-transition" timeout={300} appear>
                <CardWrapper>
                    {isFinished && !isAuthenticated && !isFinishedMemoBypassed ? (
                        <CardFeedback
                            heading="hub.subscription.success.title"
                            text="hub.subscription.success.description"
                        >
                            <Layout option={['gutterSmall', 'gutterBottom', 'middle']}>
                                <LayoutItem option={['auto']}>
                                    <Button
                                        option={['blue', 'iconLeft']}
                                        icon="arrow-left"
                                        route="home"
                                    >
                                        <Translation value="hub.subscription.success.button" />
                                    </Button>
                                </LayoutItem>
                                <LayoutItem option={['auto']}>
                                    <Button
                                        option={['green', 'iconRight', 'large']}
                                        icon="arrow-right"
                                        route="memo-subscription"
                                    >
                                        <Translation value="hub.subscription.success.button2" />
                                    </Button>
                                </LayoutItem>
                            </Layout>
                        </CardFeedback>
                    ) : isFinished && !isAuthenticated && isFinishedMemoBypassed ? (
                        <CardFeedback
                            heading="hub.subscription.success.title"
                            text="hub.subscription.success.description"
                        >
                            <Layout option={['gutterSmall', 'gutterBottom', 'middle']}>
                                <LayoutItem option={['auto']}>
                                    <Button
                                        option={['blue', 'iconLeft']}
                                        icon="arrow-left"
                                        route="home"
                                    >
                                        <Translation value="hub.subscription.success.button" />
                                    </Button>
                                </LayoutItem>
                            </Layout>
                        </CardFeedback>
                    ) : isFinished && isAuthenticated ? (
                        <CardFeedback heading="hub.subscription.edit.title">
                            <Button option={['blue', 'iconLeft']} icon="arrow-left" route="home">
                                <Translation value="hub.subscription.edit.button" />
                            </Button>
                        </CardFeedback>
                    ) : (
                        <Card option={['paddingLarge']}>
                            <Wrapper option={['small', 'noPaddingSmall']}>
                                {!isAuthenticated && (
                                    <CardHeader
                                        heading="hub.subscription.info.heading"
                                        description="hub.subscription.info.description"
                                    />
                                )}
                                <Form onSubmit={handleSubmit}>
                                    <FormError>{getFieldError(EMAIL_FIELD)}</FormError>
                                    <FormError>{getFieldError(EMAIL_CONFIRMATION_FIELD)}</FormError>
                                    <FormError>
                                        {getFieldError(PASSWORD_CONFIRMATOIN_FIELD)}
                                    </FormError>
                                    <FormError>{getFieldError(ADDRESS_FIELD)}</FormError>
                                    <FormError>{getFieldError(POSTAL_CODE_FIELD)}</FormError>

                                    <FormItem>
                                        <FormLabel inputId={FIRSTNAME_FIELD}>
                                            <Translation value="hub.subscription.firstname.title" />
                                        </FormLabel>
                                        <FormInput
                                            status={[getFieldError(FIRSTNAME_FIELD) && 'error']}
                                            form={form}
                                            id={FIRSTNAME_FIELD}
                                            name={FIRSTNAME_FIELD}
                                            options={{
                                                initialValue: isAuthenticated ? user.firstname : '',
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: translate(
                                                                    'hub.subscription.errors.required'
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                            type="text"
                                            placeholder={translate(
                                                'hub.subscription.firstname.placeholder'
                                            )}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <FormLabel inputId={LASTNAME_FIELD}>
                                            <Translation value="hub.subscription.lastname.title" />
                                        </FormLabel>
                                        <FormInput
                                            status={[getFieldError(LASTNAME_FIELD) && 'error']}
                                            form={form}
                                            id={LASTNAME_FIELD}
                                            name={LASTNAME_FIELD}
                                            options={{
                                                initialValue: isAuthenticated ? user.lastname : '',
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: translate(
                                                                    'hub.subscription.errors.required'
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                            type="text"
                                            placeholder={translate(
                                                'hub.subscription.lastname.placeholder'
                                            )}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <FormLabel inputId={EMAIL_FIELD}>
                                            <Translation value="hub.subscription.email.title" />
                                        </FormLabel>
                                        <FormInput
                                            status={[getFieldError(EMAIL_FIELD) && 'error']}
                                            form={form}
                                            id={EMAIL_FIELD}
                                            name={EMAIL_FIELD}
                                            options={{
                                                initialValue: isAuthenticated ? user.email : '',
                                                validateFirst: true,
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                type: 'email',
                                                                required: true,
                                                                message: translate(
                                                                    'hub.subscription.email.errorValid'
                                                                ),
                                                            },
                                                            {
                                                                asyncValidator: (rule, value) =>
                                                                    checkDuplicateEmail(value),
                                                                message: translate(
                                                                    'hub.subscription.email.errorDuplicate'
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                            type="email"
                                            placeholder={translate(
                                                'hub.subscription.email.placeholder'
                                            )}
                                        />
                                    </FormItem>

                                    {!isAuthenticated && (
                                        <FormItem>
                                            <FormLabel inputId={EMAIL_CONFIRMATION_FIELD}>
                                                <Translation value="hub.subscription.email.confirmation" />
                                            </FormLabel>
                                            <FormInput
                                                status={[
                                                    getFieldError(EMAIL_CONFIRMATION_FIELD) &&
                                                        'error',
                                                ]}
                                                form={form}
                                                id={EMAIL_CONFIRMATION_FIELD}
                                                name={EMAIL_CONFIRMATION_FIELD}
                                                options={{
                                                    initialValue: '',
                                                    validate: [
                                                        {
                                                            trigger: 'onBlur',
                                                            rules: [
                                                                {
                                                                    message: translate(
                                                                        'hub.subscription.email.errorSame'
                                                                    ),
                                                                    validator: (rule, value) =>
                                                                        verifySameField(
                                                                            value,
                                                                            getFieldValue(
                                                                                EMAIL_FIELD
                                                                            )
                                                                        ),
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                }}
                                                type="email"
                                                placeholder={translate(
                                                    'hub.subscription.email.placeholderConfirmation'
                                                )}
                                            />
                                        </FormItem>
                                    )}

                                    <FormItem>
                                        <FormLabel inputId={PASSWORD_FIELD}>
                                            <Translation value="hub.subscription.password.title" />
                                        </FormLabel>
                                        <FormInput
                                            status={[getFieldError(PASSWORD_FIELD) && 'error']}
                                            form={form}
                                            id={PASSWORD_FIELD}
                                            name={PASSWORD_FIELD}
                                            options={{
                                                initialValue: '',
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: translate(
                                                                    'hub.subscription.errors.required'
                                                                ),
                                                            },
                                                            {
                                                                min: 8,
                                                                message: translate(
                                                                    'hub.subscription.errors.required'
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                            type="password"
                                            autocomplete="new-password"
                                            placeholder={translate(
                                                'hub.subscription.password.placeholder'
                                            )}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <FormLabel inputId={PASSWORD_CONFIRMATOIN_FIELD}>
                                            <Translation value="hub.subscription.password.confirmation" />
                                        </FormLabel>
                                        <FormInput
                                            status={[
                                                getFieldError(PASSWORD_CONFIRMATOIN_FIELD) &&
                                                    'error',
                                            ]}
                                            form={form}
                                            id={PASSWORD_CONFIRMATOIN_FIELD}
                                            name={PASSWORD_CONFIRMATOIN_FIELD}
                                            options={{
                                                initialValue: '',
                                                validate: [
                                                    {
                                                        trigger: 'onBlur',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: translate(
                                                                    'hub.subscription.password.errorSame'
                                                                ),
                                                                validator: (rule, value) =>
                                                                    verifySameField(
                                                                        value,
                                                                        getFieldValue(
                                                                            PASSWORD_FIELD
                                                                        )
                                                                    ),
                                                            },
                                                            {
                                                                min: 8,
                                                                message: translate(
                                                                    'hub.subscription.errors.required'
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                            type="password"
                                            placeholder={translate(
                                                'hub.subscription.password.placeholderConfirmation'
                                            )}
                                        />
                                    </FormItem>

                                    <SubscriptionAddressForm
                                        form={form}
                                        translate={translate}
                                        addressField={ADDRESS_FIELD}
                                        postalCodeField={POSTAL_CODE_FIELD}
                                    />

                                    {!hasMemoSubscription && (
                                        <FormItem>
                                            <CardIcon
                                                icon="citizen-alerts"
                                                heading="hub.subscription.alerts.title"
                                                description="hub.subscription.alerts.description"
                                            >
                                                <ButtonCheckbox
                                                    status={[
                                                        getFieldError(SUBSCRIBE_ALERTS_FIELD) &&
                                                            'error',
                                                    ]}
                                                    form={form}
                                                    id={SUBSCRIBE_ALERTS_FIELD}
                                                    name={SUBSCRIBE_ALERTS_FIELD}
                                                    checked={true}
                                                    options={{
                                                        initialValue: true,
                                                    }}
                                                    type="checkbox"
                                                >
                                                    <Translation value="hub.subscription.alerts.label" />
                                                </ButtonCheckbox>
                                            </CardIcon>
                                        </FormItem>
                                    )}

                                    {!isAuthenticated && (
                                        <FormItem>
                                            <FormInput
                                                status={[getFieldError(CONFIRM_FIELD) && 'error']}
                                                form={form}
                                                id={CONFIRM_FIELD}
                                                name={CONFIRM_FIELD}
                                                options={{
                                                    initialValue: false,
                                                    validate: [
                                                        {
                                                            rules: [
                                                                {
                                                                    validator: (rule, value) =>
                                                                        verifySameField(
                                                                            value,
                                                                            true
                                                                        ),
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                }}
                                                type="checkbox"
                                            >
                                                {termsOfUse && privacyPolicy ? (
                                                    <Translation
                                                        value="hub.subscription.confirm"
                                                        data={{
                                                            link1: termsOfUse && (
                                                                <Link
                                                                    href={`/${translation(
                                                                        termsOfUse.slug
                                                                    )}`}
                                                                    utility={['baseline']}
                                                                    external
                                                                >
                                                                    <Translation
                                                                        value={termsOfUse.title}
                                                                    />
                                                                </Link>
                                                            ),
                                                            link2: privacyPolicy && (
                                                                <Link
                                                                    href={`/${translation(
                                                                        privacyPolicy.slug
                                                                    )}`}
                                                                    utility={['baseline']}
                                                                    external
                                                                >
                                                                    <Translation
                                                                        value={privacyPolicy.title}
                                                                    />
                                                                </Link>
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <Translation value="hub.subscription.placeholder.confirm" />
                                                )}
                                            </FormInput>
                                        </FormItem>
                                    )}

                                    <FormItem>
                                        <Button
                                            option={['green', 'full', 'large', 'iconRight']}
                                            icon="arrow-right"
                                            type="submit"
                                        >
                                            {isAuthenticated ? (
                                                <Translation value="hub.subscription.update.title" />
                                            ) : (
                                                <Translation value="hub.subscription.subscribe.title" />
                                            )}
                                        </Button>
                                    </FormItem>

                                    {isAuthenticated && (
                                        <div className="u-textCenter">
                                            <Link
                                                option={['red', 'underline']}
                                                route={'hub-delete-profile'}
                                            >
                                                <Translation value="hub.deleteProfile.label" />
                                            </Link>
                                        </div>
                                    )}
                                </Form>
                            </Wrapper>
                        </Card>
                    )}
                </CardWrapper>
            </CSSTransition>
        </TransitionGroup>
    )
}

SubscriptionForm.propTypes = {
    form: formShape,
    lang: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    updateSelf: PropTypes.func,
    fetchSelf: PropTypes.func,
}

SubscriptionForm.Placeholder = () => <ContentLoader></ContentLoader>

export default withLocalize(createForm()(SubscriptionForm))
