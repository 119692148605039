import { apiClient } from '../../../actions'
import { API_URL } from '../config'

const baseUrl = `${API_URL}/services`

/**
 * Retrieve a list of services.
 */
export const FETCH_SERVICE_LIST_REQUEST = 'FETCH_SERVICE_LIST_REQUEST'
export const FETCH_SERVICE_LIST_SUCCESS = 'FETCH_SERVICE_LIST_SUCCESS'
export const FETCH_SERVICE_LIST_FAILURE = 'FETCH_SERVICE_LIST_FAILURE'
export const FETCH_SERVICE_LIST_PENDING = 'FETCH_SERVICE_LIST_PENDING'
export const fetchServices = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_SERVICE_LIST_REQUEST,
            FETCH_SERVICE_LIST_SUCCESS,
            FETCH_SERVICE_LIST_FAILURE,
            FETCH_SERVICE_LIST_PENDING,
        ],
    }
}
/**
 * Retrieve service by ID.
 */
export const fetchService = (serviceId, config = {}) => {
    return apiClient.get(`${baseUrl}/${serviceId}`, config)
}
