import React from 'react'

import PropTypes from 'prop-types'

import { Icon } from '../Icon'

const DragAndDropItem = ({
    i,
    children,
    id,
    name,
    value,
    options = {},
    isDragged,
    ...classProps
}) => {
    const dragAndDrop = (
        <div>
            <span className="dragAndDrop_item_count">{i + 1}</span>

            <div className="dragAndDrop_item_inner">
                <span
                    className="dragAndDrop_item_inner_icon"
                    data-movable-handle
                    style={{ cursor: isDragged ? 'grabbing' : 'grab' }}
                >
                    <Icon name="arrow-up-down" />
                </span>

                <div className="dragAndDrop_item_label">{children}</div>
            </div>
        </div>
    )
    return dragAndDrop
}

DragAndDropItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    options: PropTypes.object,
}

export default DragAndDropItem
