import { apiClient } from '../../../../actions'

import { WORK_API_URL } from '../../config'

const baseUrl = `${WORK_API_URL}/categories`

/**
 * Retrieve project categories listing.
 */
export const fetchCategories = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve project category.
 */
export const fetchCategory = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
