import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { createForm, formShape } from 'rc-form'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { openSidebar } from '../../../../actions/sidebarActions'
import { CardAlert } from '../../../../components/CardAlert'
import { Wrapper } from '../../../../components/Wrapper'
import { Form, FormInput, FormItem, FormLabel } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Button, ButtonCheckbox } from '../../../../components/Button'
import { CardFeedback } from '../../../../components/CardFeedback'
import { ContentBlockHeader } from '../../../../components/ContentBlock'
import { Spinner } from '../../../../components/Spinner'
import { Card } from '../../../../components/Card'
import { Link } from '../../../../components/Link'
import { localizePath } from '../../../../utils'
import './_workSubscriptionForm.scss'

import {
    LOCALSTORAGE_SUBSCRIBED_USERID,
    LOCALSTORAGE_SUBSCRIBED_USER_EMAIL,
} from '../../../hub/config'

import {
    fetchSelf,
    createSubscription,
    updateSubscription,
    deleteSubscription,
} from '../../actions/work/subscriptionActions'

const WorkSubscriptionForm = ({
    lang,
    form,
    form: { getFieldError, getFieldValue, isFieldTouched, validateFields },
    isAuthenticated,
    workSubscription,
    work,
    workSettings: {
        general: { emailEnabled, smsEnabled, voiceEnabled },
        subscription: { defaultEmailEnabled, defaultSmsEnabled, defaultVoiceEnabled },
    },
    translate,
    user: { email },
    hasMemoSubscription,
    memo,
    userEmail, // default local storage user email from subscription
    userId,
    createSubscription,
    updateSubscription,
    deleteSubscription,
    fetchSelfSubscription,
    hubSubscription,
    openSidebar,
    onProfilePage = false,
}) => {
    //////////////////
    // Constantes
    //////////////////
    const EMAIL_FIELD = 'email'
    const EMAIL_CHECKED_FIELD = 'emailEnabled'
    const SMS_FIELD = 'smsPhone'
    const SMS_CHECKED_FIELD = 'smsEnabled'
    const VOICE_FIELD = 'voicePhone'
    const VOICE_CHECKED_FIELD = 'voiceEnabled'

    //////////////////
    //  Data
    //////////////////

    const isEmailChecked =
        isFieldTouched(EMAIL_CHECKED_FIELD) && !_.isEmpty(getFieldValue(EMAIL_CHECKED_FIELD))
            ? getFieldValue(EMAIL_CHECKED_FIELD)
            : workSubscription && !_.isUndefined(workSubscription[EMAIL_CHECKED_FIELD])
            ? workSubscription[EMAIL_CHECKED_FIELD]
            : defaultEmailEnabled

    const isSmsChecked =
        isFieldTouched(SMS_CHECKED_FIELD) && !_.isEmpty(getFieldValue(SMS_CHECKED_FIELD))
            ? getFieldValue(SMS_CHECKED_FIELD)
            : workSubscription && !_.isUndefined(workSubscription[SMS_CHECKED_FIELD])
            ? workSubscription[SMS_CHECKED_FIELD]
            : defaultSmsEnabled

    const isVoiceChecked =
        isFieldTouched(VOICE_CHECKED_FIELD) && !_.isEmpty(getFieldValue(VOICE_CHECKED_FIELD))
            ? getFieldValue(VOICE_CHECKED_FIELD)
            : workSubscription && !_.isUndefined(workSubscription[VOICE_CHECKED_FIELD])
            ? workSubscription[VOICE_CHECKED_FIELD]
            : defaultVoiceEnabled

    const [hasEmail, setHasEmail] = useState(false)
    const [hasSms, setHasSms] = useState(false)
    const [hasVoice, setHasVoice] = useState(false)
    const [formValues] = useState({})
    const [hasBeenCreated, setHasBeenCreated] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setHasEmail(isEmailChecked)
        setHasSms(isSmsChecked)
        setHasVoice(isVoiceChecked)
    }, [isSmsChecked, isEmailChecked, isVoiceChecked])

    const subscriptionDataObj = {
        subscriptionData: {
            type: 'work',
            id: work.id,
        },
    }
    const subscriptionUrlEncoded = `${localizePath(
        hubSubscription.path
    )}?extra=${encodeURIComponent(JSON.stringify(subscriptionDataObj))}`

    const signal = axios.CancelToken.source()

    const blockHeaderTitle = onProfilePage ? work.title : 'work.subscription.title'

    const hasOnlyEmailSubscription = !smsEnabled && !voiceEnabled

    //////////////////
    // Methods
    //////////////////

    // Submit
    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, currentFormValues) => {
            if (formErrors) {
                return false
            }
            setIsLoading(true)

            let subscriptionFields = { ...formValues, ...currentFormValues }
            subscriptionFields.user = userId
            subscriptionFields.work = work.id
            subscriptionFields.lang = lang

            // force email enabled if only email avaiable because checkbox button is hidden
            if (hasOnlyEmailSubscription) {
                subscriptionFields.emailEnabled = true
            }

            if (workSubscription) {
                subscriptionFields.id = workSubscription.id
                updateSubscription(subscriptionFields, { cancelToken: signal.token })
                setHasBeenCreated(false)
            } else {
                createSubscription(subscriptionFields, { cancelToken: signal.token })
                setHasBeenCreated(true)
            }

            setIsLoading(false)
            setIsFinished(true)
            // window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    // Delete subscription
    const handleDelete = event => {
        event.preventDefault()
        setIsLoading(true)
        deleteSubscription(workSubscription.id, { cancelToken: signal.token })
            .then(() => fetchSelfSubscription())
            .then(() => setIsLoading(false))
    }

    // Modify preferences enable form
    const handleEnableForm = event => {
        event.preventDefault()
        setIsFinished(false)
    }

    ////////////////////////////////////
    // Template
    ////////////////////////////////////
    return (
        <div>
            <ContentBlockHeader id="subscription" title={blockHeaderTitle} option={['border']} />
            <TransitionGroup>
                <CSSTransition classNames="has-transition" timeout={300} appear>
                    <>
                        {// Is Loading
                        isLoading ? (
                            <Card option={['paddingLarge']}>
                                <Spinner />
                            </Card>
                        ) : isFinished ? (
                            <CardFeedback
                                heading={
                                    hasBeenCreated
                                        ? 'work.subscription.success.title'
                                        : 'work.subscription.update.title'
                                }
                            >
                                <Button
                                    option={['blue', 'iconLeft']}
                                    icon="arrow-left"
                                    route="home"
                                >
                                    <Translation
                                        value={
                                            hasBeenCreated
                                                ? 'work.subscription.success.button'
                                                : 'work.subscription.update.button'
                                        }
                                    />
                                </Button>
                                <div className="u-margin-top-small">
                                    <Button
                                        option={['small', 'gray', 'iconLeft']}
                                        icon="arrow-left"
                                        onClick={handleEnableForm}
                                    >
                                        <Translation value="work.subscription.success.update" />
                                    </Button>
                                </div>
                            </CardFeedback>
                        ) : (
                            // Default
                            <Form onSubmit={handleSubmit}>
                                <TransitionGroup>
                                    <CSSTransition classNames="has-transition" timeout={300} appear>
                                        <div className="workSubscriptionForm_alerts">
                                            <div className="workSubscriptionForm_message">
                                                {!isAuthenticated ? (
                                                    <Translation
                                                        value="work.subscription.contact.subscribe.message"
                                                        data={{
                                                            subscribeLink: (
                                                                <Link
                                                                    href={subscriptionUrlEncoded}
                                                                    utility={['baseline']}
                                                                >
                                                                    <Translation value="work.subscription.contact.subscribe.subscribeLink.label" />
                                                                </Link>
                                                            ),
                                                            loginLink: (
                                                                <Link
                                                                    onClick={() => openSidebar()}
                                                                    utility={['baseline']}
                                                                >
                                                                    <Translation value="work.subscription.contact.subscribe.loginLink.label" />
                                                                </Link>
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <Translation value="work.subscription.contact.update.message" />
                                                )}
                                            </div>
                                            {/* Email */}
                                            {emailEnabled && (
                                                <div className="workSubscriptionForm_alerts_item">
                                                    <CardAlert
                                                        icon="email"
                                                        heading="work.subscription.contact.email.title"
                                                        description="work.subscription.contact.email.description"
                                                    >
                                                        {isAuthenticated && (
                                                            <>
                                                            {!hasOnlyEmailSubscription && (
                                                                <ButtonCheckbox
                                                                    status={[
                                                                        getFieldError(
                                                                            EMAIL_CHECKED_FIELD
                                                                        ) && 'error',
                                                                    ]}
                                                                    form={form}
                                                                    id={EMAIL_CHECKED_FIELD}
                                                                    name={EMAIL_CHECKED_FIELD}
                                                                    checked={isEmailChecked && true}
                                                                    value={isEmailChecked}
                                                                    onChange={() =>
                                                                        setHasEmail(!hasEmail)
                                                                    }
                                                                    options={{
                                                                        initialValue: isEmailChecked,
                                                                    }}
                                                                    type="checkbox"
                                                                    disabled
                                                                >
                                                                    <Translation value="work.subscription.contact.email.checkedOption" />
                                                                </ButtonCheckbox>
                                                            )}
                                                                <FormItem
                                                                    option={['marginTop']}
                                                                    status={[
                                                                        hasEmail ? '' : 'hidden',
                                                                    ]}
                                                                >
                                                                    <FormLabel
                                                                        inputId={EMAIL_FIELD}
                                                                    >
                                                                        <Translation value="work.subscription.contact.email.label" />
                                                                    </FormLabel>
                                                                    <FormInput
                                                                        option={['center']}
                                                                        status={[
                                                                            getFieldError(
                                                                                EMAIL_FIELD
                                                                            ) && 'error',
                                                                        ]}
                                                                        form={form}
                                                                        id={EMAIL_FIELD}
                                                                        name={EMAIL_FIELD}
                                                                        options={{
                                                                            initialValue:
                                                                                isFieldTouched(
                                                                                    EMAIL_FIELD
                                                                                ) &&
                                                                                !_.isEmpty(
                                                                                    getFieldValue(
                                                                                        EMAIL_FIELD
                                                                                    )
                                                                                )
                                                                                    ? getFieldValue(
                                                                                          EMAIL_FIELD
                                                                                      )
                                                                                    : formValues[
                                                                                          EMAIL_FIELD
                                                                                      ]
                                                                                    ? formValues[
                                                                                          EMAIL_FIELD
                                                                                      ]
                                                                                    : isAuthenticated &&
                                                                                      workSubscription[
                                                                                          EMAIL_FIELD
                                                                                      ]
                                                                                    ? workSubscription[
                                                                                          EMAIL_FIELD
                                                                                      ]
                                                                                    : isAuthenticated
                                                                                    ? email
                                                                                    : !_.isNil(
                                                                                          userEmail
                                                                                      )
                                                                                    ? userEmail
                                                                                    : '',
                                                                            validateFirst: true,
                                                                            validate: [
                                                                                {
                                                                                    trigger:
                                                                                        'onBlur',
                                                                                    rules: [
                                                                                        {
                                                                                            required: isFieldTouched(
                                                                                                EMAIL_CHECKED_FIELD
                                                                                            )
                                                                                                ? getFieldValue(
                                                                                                      EMAIL_CHECKED_FIELD
                                                                                                  )
                                                                                                : formValues[
                                                                                                      EMAIL_FIELD
                                                                                                  ]
                                                                                                ? formValues[
                                                                                                      EMAIL_FIELD
                                                                                                  ]
                                                                                                : defaultEmailEnabled
                                                                                                ? true
                                                                                                : false,
                                                                                            message: translate(
                                                                                                'work.subscription.errors.required'
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            type:
                                                                                                'email',
                                                                                            message: translate(
                                                                                                'work.subscription.email.errorValid'
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        }}
                                                                        type="text"
                                                                        placeholder={translate(
                                                                            'work.subscription.contact.email.placeholder'
                                                                        )}
                                                                        disabled={true}
                                                                    />
                                                                </FormItem>
                                                            </>
                                                        )}
                                                    </CardAlert>
                                                </div>
                                            )}
                                            {/* SMS */}
                                            {smsEnabled && (
                                                <div className="workSubscriptionForm_alerts_item">
                                                    <CardAlert
                                                        icon="sms"
                                                        heading="work.subscription.contact.sms.title"
                                                        description="work.subscription.contact.sms.description"
                                                    >
                                                        {isAuthenticated && (
                                                            <>
                                                                <ButtonCheckbox
                                                                    status={[
                                                                        getFieldError(
                                                                            SMS_CHECKED_FIELD
                                                                        ) && 'error',
                                                                    ]}
                                                                    form={form}
                                                                    id={SMS_CHECKED_FIELD}
                                                                    name={SMS_CHECKED_FIELD}
                                                                    checked={isSmsChecked && true}
                                                                    onChange={() =>
                                                                        setHasSms(!hasSms)
                                                                    }
                                                                    value={isSmsChecked}
                                                                    options={{
                                                                        initialValue: isSmsChecked,
                                                                    }}
                                                                    type="checkbox"
                                                                >
                                                                    <Translation value="work.subscription.contact.sms.checkedOption" />
                                                                </ButtonCheckbox>
                                                                <FormItem
                                                                    option={['marginTop']}
                                                                    status={[
                                                                        hasSms ? '' : 'hidden',
                                                                    ]}
                                                                >
                                                                    <FormLabel inputId={SMS_FIELD}>
                                                                        <Translation value="work.subscription.contact.sms.label" />
                                                                    </FormLabel>
                                                                    <FormInput
                                                                        option={['center']}
                                                                        status={[
                                                                            getFieldError(
                                                                                SMS_FIELD
                                                                            ) && 'error',
                                                                        ]}
                                                                        form={form}
                                                                        id={SMS_FIELD}
                                                                        name={SMS_FIELD}
                                                                        options={{
                                                                            initialValue:
                                                                                isFieldTouched(
                                                                                    SMS_FIELD
                                                                                ) &&
                                                                                !_.isEmpty(
                                                                                    getFieldValue(
                                                                                        SMS_FIELD
                                                                                    )
                                                                                )
                                                                                    ? getFieldValue(
                                                                                          SMS_FIELD
                                                                                      )
                                                                                    : formValues[
                                                                                          SMS_FIELD
                                                                                      ]
                                                                                    ? formValues[
                                                                                          SMS_FIELD
                                                                                      ]
                                                                                    : isAuthenticated &&
                                                                                      workSubscription[
                                                                                          SMS_FIELD
                                                                                      ]
                                                                                    ? workSubscription[
                                                                                          SMS_FIELD
                                                                                      ]
                                                                                    : hasMemoSubscription &&
                                                                                      !_.isEmpty(
                                                                                          memo.smsPhone
                                                                                      )
                                                                                    ? memo.smsPhone
                                                                                    : '',
                                                                            validateFirst: true,
                                                                            validate: [
                                                                                {
                                                                                    trigger:
                                                                                        'onBlur',
                                                                                    rules: [
                                                                                        {
                                                                                            required: isFieldTouched(
                                                                                                SMS_CHECKED_FIELD
                                                                                            )
                                                                                                ? getFieldValue(
                                                                                                      SMS_CHECKED_FIELD
                                                                                                  )
                                                                                                : isAuthenticated &&
                                                                                                  !_.isUndefined(
                                                                                                      workSubscription[
                                                                                                          SMS_FIELD
                                                                                                      ]
                                                                                                  ) &&
                                                                                                  workSubscription[
                                                                                                      SMS_FIELD
                                                                                                  ]
                                                                                                ? workSubscription[
                                                                                                      SMS_FIELD
                                                                                                  ]
                                                                                                : defaultSmsEnabled
                                                                                                ? true
                                                                                                : false,
                                                                                            message: translate(
                                                                                                'work.subscription.errors.required'
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                                                            message: translate(
                                                                                                'work.subscription.contact.sms.errorValid'
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        }}
                                                                        type="tel"
                                                                        placeholder={translate(
                                                                            'work.subscription.contact.sms.placeholder'
                                                                        )}
                                                                    />
                                                                </FormItem>
                                                            </>
                                                        )}
                                                    </CardAlert>
                                                </div>
                                            )}
                                            {/* Voice */}
                                            {voiceEnabled && (
                                                <div className="workSubscriptionForm_alerts_item">
                                                    <CardAlert
                                                        icon="voice"
                                                        heading="work.subscription.contact.voice.title"
                                                        description="work.subscription.contact.voice.description"
                                                    >
                                                        {isAuthenticated && (
                                                            <>
                                                                <ButtonCheckbox
                                                                    status={[
                                                                        getFieldError(
                                                                            VOICE_CHECKED_FIELD
                                                                        ) && 'error',
                                                                    ]}
                                                                    form={form}
                                                                    id={VOICE_CHECKED_FIELD}
                                                                    name={VOICE_CHECKED_FIELD}
                                                                    checked={isVoiceChecked && true}
                                                                    onChange={() =>
                                                                        setHasVoice(!hasVoice)
                                                                    }
                                                                    value={isVoiceChecked}
                                                                    options={{
                                                                        initialValue: isVoiceChecked,
                                                                    }}
                                                                    type="checkbox"
                                                                >
                                                                    <Translation value="work.subscription.contact.voice.checkedOption" />
                                                                </ButtonCheckbox>
                                                                <FormItem
                                                                    option={['marginTop']}
                                                                    status={[
                                                                        hasVoice ? '' : 'hidden',
                                                                    ]}
                                                                >
                                                                    <FormLabel
                                                                        inputId={VOICE_FIELD}
                                                                    >
                                                                        <Translation value="work.subscription.contact.voice.label" />
                                                                    </FormLabel>
                                                                    <FormInput
                                                                        option={['center']}
                                                                        status={[
                                                                            getFieldError(
                                                                                VOICE_FIELD
                                                                            ) && 'error',
                                                                        ]}
                                                                        form={form}
                                                                        id={VOICE_FIELD}
                                                                        name={VOICE_FIELD}
                                                                        options={{
                                                                            initialValue:
                                                                                isFieldTouched(
                                                                                    VOICE_FIELD
                                                                                ) &&
                                                                                !_.isEmpty(
                                                                                    getFieldValue(
                                                                                        VOICE_FIELD
                                                                                    )
                                                                                )
                                                                                    ? getFieldValue(
                                                                                          VOICE_FIELD
                                                                                      )
                                                                                    : formValues[
                                                                                          VOICE_FIELD
                                                                                      ]
                                                                                    ? formValues[
                                                                                          VOICE_FIELD
                                                                                      ]
                                                                                    : isAuthenticated &&
                                                                                      workSubscription[
                                                                                          VOICE_FIELD
                                                                                      ]
                                                                                    ? workSubscription[
                                                                                          VOICE_FIELD
                                                                                      ]
                                                                                    : hasMemoSubscription &&
                                                                                      !_.isEmpty(
                                                                                          memo.voicePhone
                                                                                      )
                                                                                    ? memo.voicePhone
                                                                                    : '',
                                                                            validateFirst: true,
                                                                            validate: [
                                                                                {
                                                                                    trigger:
                                                                                        'onBlur',
                                                                                    rules: [
                                                                                        {
                                                                                            required: isFieldTouched(
                                                                                                VOICE_CHECKED_FIELD
                                                                                            )
                                                                                                ? getFieldValue(
                                                                                                      VOICE_CHECKED_FIELD
                                                                                                  )
                                                                                                : isAuthenticated &&
                                                                                                  !_.isUndefined(
                                                                                                      workSubscription[
                                                                                                          VOICE_FIELD
                                                                                                      ]
                                                                                                  ) &&
                                                                                                  workSubscription[
                                                                                                      VOICE_FIELD
                                                                                                  ]
                                                                                                ? workSubscription[
                                                                                                      VOICE_FIELD
                                                                                                  ]
                                                                                                : defaultVoiceEnabled
                                                                                                ? true
                                                                                                : false,
                                                                                            message: translate(
                                                                                                'work.subscription.errors.required'
                                                                                            ),
                                                                                        },
                                                                                        {
                                                                                            pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                                                            message: translate(
                                                                                                'work.subscription.contact.voice.errorValid'
                                                                                            ),
                                                                                        },
                                                                                    ],
                                                                                },
                                                                            ],
                                                                        }}
                                                                        type="tel"
                                                                        placeholder={translate(
                                                                            'work.subscription.contact.voice.placeholder'
                                                                        )}
                                                                    />
                                                                </FormItem>
                                                            </>
                                                        )}
                                                    </CardAlert>
                                                </div>
                                            )}
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>

                                {/* Submit */}
                                <Wrapper option={['noPaddingSmall']}>
                                    {isAuthenticated && (
                                        <div className="workSubscriptionForm_buttons">
                                            {(!hasOnlyEmailSubscription || !workSubscription) && (
                                                <FormItem option={['medium']}>
                                                    <Button
                                                        option={['green', 'full', 'large']}
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                               <Translation
                                                                value={
                                                                    workSubscription
                                                                        ? 'work.subscription.contact.button.update'
                                                                        : 'work.subscription.contact.button.save'
                                                                }
                                                            />
                                                    </Button>
                                                </FormItem>
                                            )}
                                        </div>
                                    )}
                                    {workSubscription && (
                                        <div className="workSubscriptionForm_buttons">
                                            <FormItem option={['medium']}>
                                                <Button
                                                    option={['red', 'full']}
                                                    type="submit"
                                                    onClick={handleDelete}
                                                >
                                                    <Translation value="work.subscription.delete.button" />
                                                </Button>
                                            </FormItem>
                                        </div>
                                    )}
                                </Wrapper>
                            </Form>
                        )}
                    </>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}

WorkSubscriptionForm.propTypes = {
    form: formShape,
    isAuthenticated: PropTypes.bool.isRequired,
    userId: PropTypes.string,
    workSubscription: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    workSettings: PropTypes.object,
    translate: PropTypes.func,
}

WorkSubscriptionForm.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = (state, ownProps) => ({
    lang: getActiveLanguage(state.localize).code,
    userId: state.hub.auth.isAuthenticated
        ? state.hub.auth.user.id
        : localStorage.getItem(LOCALSTORAGE_SUBSCRIBED_USERID) || null,
    userEmail: localStorage.getItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL) || null,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    workSubscription:
        state.hub.auth.isAuthenticated &&
        state.work.subscriptions?.entities?.length &&
        _.isObject(state.work.subscriptions.entities.find(work => work.work === ownProps.work.id))
            ? state.work.subscriptions.entities.find(work => work.work === ownProps.work.id)
            : false,
    hubSubscription: state.routes.find(route => route.id === 'hub-subscription'),
})

const mapDispatchToProps = dispatch => ({
    createSubscription: (data, config) => dispatch(createSubscription(data, config)),
    updateSubscription: (data, config) => dispatch(updateSubscription(data, config)),
    deleteSubscription: (data, config) => dispatch(deleteSubscription(data, config)),
    fetchSelfSubscription: config => dispatch(fetchSelf(config)),
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(createForm()(WorkSubscriptionForm)))
