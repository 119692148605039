import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'

import { Heading } from '../../../../components/Heading'
import { Wrapper } from '../../../../components/Wrapper'
import { Card } from '../../../../components/Card'
import { Section } from '../../../../components/Section'

import { AlertTableRow } from '.'

const AlertTable = ({ alerts, categories, name = false }) => {
    const renderedAlerts = alerts.map(alert => {
        const category = _.find(categories, category => category.id === alert.category)
        return <AlertTableRow key={alert.id} {...alert} category={category} />
    })

    const count = alerts.length

    return (
        <Section>
            <Wrapper>
                {name ? (
                    <Heading option={['h3']} el="h2">
                        {name}
                        <sup>{count}</sup>
                    </Heading>
                ) : (
                    ''
                )}
                <Card option={['noPadding']}>{renderedAlerts}</Card>
            </Wrapper>
        </Section>
    )
}

AlertTable.Placeholder = () => <ContentLoader></ContentLoader>

AlertTable.propTypes = {
    name: PropTypes.object,
    alerts: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

export default AlertTable
