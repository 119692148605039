import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { isTranslationObject } from '../../../../utils'

import { Translation } from '../../../../components/Translation'
import { HeroCharacter } from '../HeroCharacter'
import { Heading } from '../../../../components/Heading'
import { Layout, LayoutItem } from '../../../../components/Layout'

import './_headerHero.scss'

const HeaderHero = props => {
    const {
        options: { title, description /*showWeather*/ },
        user,
    } = props

    if (_.isNull(user)) {
        return null
    }

    return (
        <div className="headerHero">
            <Layout option={['full']}>
                <LayoutItem utility={['1/2@from-small']}>
                    <HeroCharacter {...props} />
                </LayoutItem>
                <LayoutItem utility={['1/2@from-small']}>
                    <div className="headerHero_content">
                        <div className="headerHero_content_wrap">
                            <div className="headerHero_title">
                                <Heading option={['h2']} el="h1">
                                    <Translation value={title} /> <br />
                                    {user.firstname}!
                                </Heading>
                            </div>
                            <div className="headerHero_text">
                                <Translation value={description} isHtml />
                            </div>
                        </div>
                    </div>
                </LayoutItem>
            </Layout>
            {/*<div>
                <HeroCharacter {...props} />
                <div>
                    {showWeather ? 'METEO' : ''}
                    <p>
                        <Translation value={title} /> {user.firstname}!
                    </p>
                    <Translation value={description} />
                </div>
            </div>*/}
        </div>
    )
}

HeaderHero.propTypes = {
    options: PropTypes.shape({
        title: isTranslationObject,
        description: isTranslationObject,
        showWeather: PropTypes.bool,
    }).isRequired,
}

const mapStateToProps = state => ({
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : null,
})

export default connect(mapStateToProps)(HeaderHero)
