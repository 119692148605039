import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { childrenPropType, renderClasses, renderRoute, normalizePath } from '../../utils'

import { Icon } from '../Icon'

import './_button.scss'

const Button = ({
    children,
    href = null,
    route = null,
    external = false,
    icon = null,
    onClick = () => {},
    type = 'button',
    disabled = false,
    ...classProps
}) => {
    const classes = renderClasses('button', classProps)
    const hasCircleIcon = classProps.option ? classProps.option.includes('circleIcon') : false

    const iconEl =
        icon !== null ? (
            <span className={'button_icon' + (hasCircleIcon ? ' button -circle -white' : '')}>
                <Icon name={icon} />
            </span>
        ) : null

    return route !== null ? (
        <RouterLink className={classes} to={renderRoute(route)}>
            {iconEl}
            {children}
        </RouterLink>
    ) : external ? (
        <a className={classes} href={href} target="_blank" rel="noopener noreferrer">
            {iconEl}
            {children}
        </a>
    ) : href !== null ? (
        <RouterLink className={classes} to={normalizePath(href)}>
            {iconEl}
            {children}
        </RouterLink>
    ) : (
        <button
            type={type}
            className={classes}
            onClick={onClick}
            {...(disabled && { disabled: true })}
        >
            {iconEl}
            {children}
        </button>
    )
}

Button.propTypes = {
    /**
     * The button's content.
     */
    children: childrenPropType(),
    href: PropTypes.string,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    external: PropTypes.bool,
    /**
     * A function to execute on button click.
     */
    onClick: PropTypes.func,
    /**
     * A route to be rendered by renderRoute (either a route data object or a route ID).
     */
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /**
     * Only used when rendering a button element.
     */
    type: PropTypes.oneOf(['button', 'submit']),
}

export default Button
