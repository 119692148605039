import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import { createForm, formShape } from 'rc-form'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Elements, StripeProvider } from 'react-stripe-elements'

import { Card } from '../../../../components/Card'
import { CardHeader } from '../../../../components/Card'
import { Wrapper } from '../../../../components/Wrapper'
import { Heading } from '../../../../components/Heading'
import { Form, /*FormError,*/ FormInput, FormItem, FormLabel } from '../../../../components/Form'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Translation } from '../../../../components/Translation'
import { Spinner } from '../../../../components/Spinner'
import { PaymentDetailsConfirmation } from './'

import { useScript, isTranslationObject } from '../../../../utils'
import { stripeDependencies } from '../../utils'

import { StripeCheckout } from '../Stripe'

const PaymentDetails = ({
    headerPage,
    translate,
    form,
    form: { getFieldError, getFieldValue, validateFields },
    cardInfo,
    payment,
    provider = 'stripe',
    lang,
}) => {
    const LASTNAME_FIELD = 'lastname'
    const FIRSTNAME_FIELD = 'firstname'
    const EMAIL_FIELD = 'email'

    const state = useScript(stripeDependencies)
    const hasErrors = !_.isUndefined(_.find(state, stateItem => stateItem.error))
    const isFinished = !_.isUndefined(_.find(state, stateItem => stateItem.loaded))
    const [isConfirmation, setIsConfirmation] = useState(false)

    if (!isFinished) {
        return <Spinner />
    }

    if (hasErrors) {
        return 'impossible to load Stripe'
    }

    const stripe = window.AgoraStripe

    const handleSubmit = () => {
        validateFields(async (formErrors, formValues) => {
            if (formErrors) {
                return
            }
            // setIsConfirmation(true)
            return true
        })
    }

    const submitPayment = () => {
        setIsConfirmation(true)
    }

    if (isConfirmation) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <React.Fragment>
            <HeaderPage
                heading={headerPage.heading}
                breadcrumb={headerPage.breadcrumb}
                icon={headerPage.icon}
            />

            <Wrapper>
                <Card option={['paddingLarge']}>
                    <Wrapper option={['small', 'noPaddingSmall']}>
                        {payment.status === 'completed' ? (
                            <PaymentDetailsConfirmation
                                confirmationTitle="payment.form.alreadyPaid.title"
                                confirmationDescription="payment.form.alreadyPaid.description"
                            />
                        ) : isConfirmation ? (
                            <PaymentDetailsConfirmation />
                        ) : (
                            <React.Fragment>
                                <CardHeader
                                    heading={cardInfo.title}
                                    description={cardInfo.description}
                                />

                                <Heading el="p" option={['h2']} utility={['textCenter', 'blue']}>
                                    <Translation value="payment.form.pay.title" /> {payment.amount}$
                                </Heading>

                                <StripeProvider stripe={stripe}>
                                    <Elements locale={lang}>
                                        <Form onSubmit={handleSubmit}>
                                            <FormItem>
                                                <FormLabel inputId={LASTNAME_FIELD}>
                                                    <Translation value="hub.subscription.lastname.title" />
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(LASTNAME_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={LASTNAME_FIELD}
                                                    name={LASTNAME_FIELD}
                                                    options={{
                                                        initialValue: payment.lastname || '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: translate(
                                                                            'hub.subscription.errors.required'
                                                                        ),
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="text"
                                                />
                                                {/* <FormError>{getFieldError(LASTNAME_FIELD)}</FormError> */}
                                            </FormItem>

                                            <FormItem>
                                                <FormLabel inputId={FIRSTNAME_FIELD}>
                                                    <Translation value="hub.subscription.firstname.title" />
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(FIRSTNAME_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={FIRSTNAME_FIELD}
                                                    name={FIRSTNAME_FIELD}
                                                    options={{
                                                        initialValue: payment.firstname || '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: translate(
                                                                            'hub.subscription.errors.required'
                                                                        ),
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="text"
                                                />
                                                {/* <FormError>{getFieldError(FIRSTNAME_FIELD)}</FormError> */}
                                            </FormItem>

                                            <FormItem>
                                                <FormLabel inputId={EMAIL_FIELD}>
                                                    <Translation value="hub.subscription.email.title" />
                                                </FormLabel>
                                                <FormInput
                                                    status={[getFieldError(EMAIL_FIELD) && 'error']}
                                                    form={form}
                                                    id={EMAIL_FIELD}
                                                    name={EMAIL_FIELD}
                                                    options={{
                                                        initialValue: payment.email || '',
                                                        validateFirst: true,
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: translate(
                                                                            'hub.subscription.errors.required'
                                                                        ),
                                                                    },
                                                                    {
                                                                        type: 'email',
                                                                        message: translate(
                                                                            'hub.subscription.email.errorValid'
                                                                        ),
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="email"
                                                />
                                                {/* <FormError>{getFieldError(EMAIL_FIELD)}</FormError> */}
                                            </FormItem>

                                            <StripeCheckout
                                                submitPayment={handleSubmit}
                                                payment={payment}
                                                stripe={stripe}
                                                setPaymentSuccess={submitPayment}
                                            />
                                        </Form>
                                    </Elements>
                                </StripeProvider>
                            </React.Fragment>
                        )}
                    </Wrapper>
                </Card>
            </Wrapper>
        </React.Fragment>
    )
}

PaymentDetails.propTypes = {
    headerPage: PropTypes.shape({
        heading: PropTypes.oneOfType([PropTypes.string, isTranslationObject]).isRequired,
        breadcrumb: PropTypes.oneOfType([PropTypes.string, isTranslationObject]),
        icon: PropTypes.string,
    }),
    form: formShape,
    cardInfo: PropTypes.object.isRequired,
    provider: PropTypes.string,
    payment: PropTypes.object.isRequired,
}

PaymentDetails.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
})

export default withLocalize(createForm()(connect(mapStateToProps)(PaymentDetails)))
