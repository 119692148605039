import PropTypes from 'prop-types'
import React from 'react'
import { Translate, withLocalize } from 'react-localize-redux'

const onMissingTranslation = ({ translationId /*, languageCode*/ }) => {
    return translationId
}

const Translation = ({ activeLanguage, data = {}, htmlEl = 'span', isHtml = false, value }) => {
    if (typeof activeLanguage === 'undefined') {
        return null
    }

    if (typeof value === 'object') {
        value = value[activeLanguage.code]

        if (isHtml) {
            const El = htmlEl
            return <El dangerouslySetInnerHTML={{ __html: value }}></El>
        } else {
            return value
        }
    } else {
        return (
            <Translate
                id={value}
                options={{ onMissingTranslation, renderInnerHtml: isHtml }}
                data={data}
            />
        )
    }
}

Translation.propTypes = {
    data: PropTypes.object,
    htmlEl: PropTypes.oneOf(['div', 'span']),
    isHtml: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default withLocalize(Translation)
