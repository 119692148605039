import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../components/Card'
import { Heading } from '../../../../components/Heading'
import { AlertCategory } from '.'

import { Translation } from '../../../../components/Translation'
import { isTranslationObject, localizePath } from '../../../../utils'

import './_alertCard.scss'

const AlertCard = ({ title, slug, featured, category, startDate }) => (
    <article className="alertCard">
        <Card href={localizePath(slug)}>
            <Heading option={['h4']} el="h3">
                <Translation value={title} />
            </Heading>

            {category && (
                <div className="alertCard_category">
                    <AlertCategory category={category} date={startDate} featured={featured} />
                </div>
            )}
        </Card>
    </article>
)

AlertCard.propTypes = {
    title: isTranslationObject,
    featured: PropTypes.bool,
    slug: isTranslationObject,
    category: PropTypes.shape({
        name: isTranslationObject,
        color: PropTypes.string,
        icon: PropTypes.string,
    }),
}

export default AlertCard
