import { apiClient } from '../../../../actions'
import { FINISHED_CONTENT } from '../../../../config'
import { translation } from '../../../../utils'

import { PROJECT_API_URL } from '../../config'

const baseUrl = `${PROJECT_API_URL}/projects`

/**
 * Retrieve project listing.
 */
export const fetchProjects = (filter, config = {}) => {
    if (filter === 'ongoing') {
        return apiClient.get(`${baseUrl}?status=ongoing`, config)
    }
    if (filter === translation(FINISHED_CONTENT)) {
        return apiClient.get(`${baseUrl}?status=finished`, config)
    }

    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve project details by slug.
 */
export const fetchProjectBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}

/**
 * Retrieve project details.
 */
export const fetchProject = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
