import React from 'react'
import PropTypes from 'prop-types'

import { QuestionComponents } from '.'

const QuestionChoiceManager = props => {
    const { type, view } = props.question
    const QuestionComponent = QuestionComponents[type][view] ?? null
    return QuestionComponent && <QuestionComponent {...props} />
}

QuestionChoiceManager.propTypes = {
    question: PropTypes.shape({
        choices: PropTypes.array.isRequired,
        view: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
    }),
}

export default QuestionChoiceManager
