import React from 'react'

import { View } from '../../../components/View'
import { PaymentDetailsContainer } from '../components/Payment'

const PaymentDetailsView = ({ match }) => (
    <View>
        <PaymentDetailsContainer id={match.params.id} />
    </View>
)

export default PaymentDetailsView
