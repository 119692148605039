import React from 'react'
import { connect } from 'react-redux'
import { getLanguages } from 'react-localize-redux'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

import PropTypes from 'prop-types'

import ContentLoader from 'react-content-loader'

import { translation, resolveContentStatus } from '../../../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { SurveyCardContainer } from '../../../survey/components/Survey'
import { FlashSurveyContainer } from '../../../survey/components/FlashSurvey'
import { ProjectList, ProjectHeader } from '.'
import { ProjectNewsCardList } from '../ProjectNews'
import { ContentBlocks } from '../../../../components/ContentBlock'
import { ContentBlockHeader } from '../../../../components/ContentBlock'

const ProjectDetails = ({ project, categories, locales }) => {
    const currentCategory = _.find(categories, category => category.id === project.category)
    const status = resolveContentStatus(project.startDate, project.endDate)
    let extraNavContent = []

    if (!_.isEmpty(project.news)) {
        extraNavContent.push({
            id: translation('project.details.news.anchor'),
            title: 'project.details.news.title',
        })
    }

    if (project.survey || project.flashSurvey) {
        extraNavContent.push({
            id: translation('survey.listing.anchor'),
            title: 'survey.listing.title',
        })
    }

    project.attachments.push(...extraNavContent)

    return (
        <div>
            <Helmet>
                <title itemProp="name">{translation(project.title)}</title>
                {project.description && (
                    <meta name="description" content={translation(project.description)} />
                )}
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={project.startDate} />
                <meta property="article:modified_time" content={project.lastModified} />
                <meta property="article:expiration_time" content={project.endDate} />
                {locales.map(locale => (
                    <link
                        rel="alternate"
                        href={`${window.location.origin}/${project.slug[locale.code]}`}
                        hrefLang={locale.code}
                        key={locale.code}
                    />
                ))}
            </Helmet>
            <article className="Project">
                <ProjectHeader project={project} category={currentCategory} />
                <ContentBlocks blocks={project.attachments} showNav={true} />

                {!_.isEmpty(project.news) && (
                    <div
                        id={translation('project.details.news.anchor')}
                        className="u-margin-bottom"
                    >
                        <Wrapper option={['medium']}>
                            <ProjectNewsCardList news={project.news} />
                        </Wrapper>
                    </div>
                )}

                {(project.survey || project.flashSurvey) && (
                    <div className="contentBlock" id={translation('survey.listing.anchor')}>
                        <Wrapper option={['medium']}>
                            <ContentBlockHeader
                                id="survey"
                                title={'survey.listing.title'}
                                option={['border']}
                            />

                            {project.survey && (
                                <div id={project.survey.id} className="u-margin-bottom">
                                    <SurveyCardContainer survey={project.survey} />
                                </div>
                            )}
                        </Wrapper>
                    </div>
                )}

                {project.flashSurvey && (
                    <div id={project.flashSurvey.id}>
                        <FlashSurveyContainer flashsurvey={project.flashSurvey} offset={false} />
                    </div>
                )}

                {!_.isEmpty(project.relatedProjects) && (
                    <ProjectList
                        projects={project.relatedProjects}
                        categories={categories}
                        filter={status}
                    />
                )}
            </article>
        </div>
    )
}

ProjectDetails.propTypes = {
    project: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    locales: PropTypes.array,
}

const mapStateToProps = state => ({
    locales: getLanguages(state.localize),
})

ProjectDetails.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    ></ContentLoader>
)

export default connect(mapStateToProps)(ProjectDetails)
