import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useFetchItems, responseCustomizer, useDev, logErrors } from '../../../../utils'

import { fetchCategory } from '../../actions/categoryActions'
import { fetchSurveyUserSelf } from '../../actions/surveyUserActions'

import { hasUserAlreadyParticipated } from '../../utils'

import { SurveyCard } from '.'

const SurveyCardContainer = ({ survey, loadUserSelfSurveys }) => {
    const useFetchSurveyUserSelf = useCallback(() => {
        if (!loadUserSelfSurveys) {
            // used to dispatch from type and set isLoading to false
            return () => {
                return { type: 'NO_FETCH' }
            }
        }
        return fetchSurveyUserSelf()
    }, [loadUserSelfSurveys])

    const userSurveys = useFetchItems(useFetchSurveyUserSelf)

    const useFetchCategory = useCallback(() => {
        if (_.isNil(survey.category)) {
            return () => {}
        }
        return fetchCategory(survey.category)
    }, [survey.category])

    const category = useFetchItems(useFetchCategory)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, userSurveys, category, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <SurveyCard.Placeholder />
                ) : (
                    <SurveyCard
                        survey={survey}
                        category={category.items}
                        isCompleted={hasUserAlreadyParticipated(userSurveys.items, survey.id)}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    loadUserSelfSurveys: state.hub.auth.isAuthenticated ? true : false,
})

export default connect(mapStateToProps)(SurveyCardContainer)
