import { AlertSliderContainer } from '../components/Alert'
import { AlertListView } from '../views'
import { SERVICE_MEMO_ALERTS } from '.'

export const componentMap = {
    'city/memo/widget/home/alert-slider': AlertSliderContainer,
}
export const viewMap = {
    'city/memo/service/alerts': AlertListView,
}

export const routeServiceMap = {
    'alert-details': SERVICE_MEMO_ALERTS,
    'memo-subscription': SERVICE_MEMO_ALERTS,
}
