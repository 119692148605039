import React, { useState } from 'react'

import { DragAndDrop, DragAndDropItem } from '../../../../components/DragAndDrop'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { Content } from '../../../../components/Content'
import { List, arrayMove } from 'react-movable'
import { FormInput } from '../../../../components/Form'
import _ from 'lodash'

const QuestionChoicePriorityDragAndDrop = ({
    question: { id, choices, required },
    form,
    form: {
        getFieldValue,
        getFieldError,
        setFieldsInitialValue,
        isFieldTouched,
        setFieldsValue,
        setFields,
    },
    formValues = {},
    color,
    allowSkip,
}) => {
    // Allow skip if the field is not required. (Not sure if it's better to handle this higher in the chain)
    allowSkip(!required)
    const [items] = useState(choices)

    let sortItems = [...items]

    const field = getFieldValue(id)
    if (getFieldValue(id) && !_.isEmpty(getFieldValue(id))) {
        sortItems.sort((a, b) => Math.sign(field.indexOf(a.id) - field.indexOf(b.id)))
    }

    return (
        <Wrapper option={['smallMedium']}>
            <Content utility={['margin-bottom-small', 'textCenter']}>
                <p>
                    <Translation value="survey.details.handle-instructions.title" />
                </p>
            </Content>

            <DragAndDrop>
                {
                    <>
                        <FormInput
                            id={id}
                            form={form}
                            status={[getFieldError(id) && 'error']}
                            name={id}
                            type="hidden"
                            value={getFieldValue(id)}
                            options={{
                                preserve: !!getFieldValue(id),
                                initialValue: items.map(item => item.id),
                                validate: [
                                    {
                                        trigger: 'onChange',
                                        rules: [
                                            {
                                                required: required,
                                            },
                                        ],
                                    },
                                ],
                            }}
                        />

                        <List
                            values={sortItems}
                            onChange={({ oldIndex, newIndex }) => {
                                setFieldsValue({
                                    [id]: arrayMove(sortItems, oldIndex, newIndex).map(
                                        item => item.id
                                    ),
                                })
                            }}
                            renderList={({ children, props }) => <ul {...props}>{children}</ul>}
                            renderItem={({ value, props, index, isDragged }) => {
                                return (
                                    <li
                                        {...props}
                                        className={`dragAndDrop_item ${
                                            isDragged ? '-grabbing' : ''
                                        }`}
                                    >
                                        <DragAndDropItem
                                            id={value.id}
                                            i={index}
                                            name={value.id}
                                            isDragged={isDragged}
                                        >
                                            <Translation value={value.content} isHtml />
                                        </DragAndDropItem>
                                    </li>
                                )
                            }}
                        />
                    </>
                }
            </DragAndDrop>
        </Wrapper>
    )
}

export default QuestionChoicePriorityDragAndDrop
