import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../components/Card'
import { Button } from '../../../../components/Button'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'

import { isTranslationObject, translation /*resolveContentStatus*/ } from '../../../../utils'

import './_projectCard.scss'

const ProjectCard = ({
    project: { title, thumbnail, slug, startDate, endDate },
    category,
    isLarge = false,
}) => (
    <article className="u-full">
        <Card option={['noPadding']} href={`/${translation(slug)}`}>
            <div className={'projectCard' + (isLarge ? ' -large' : '')}>
                <div className="projectCard_image">
                    <img src={thumbnail} alt={translation(title)} />
                </div>
                <div className="projectCard_content">
                    <p className="projectCard_category">
                        {category && <Translation value={category.name} />}
                    </p>
                    <Heading el="h2" option={isLarge ? ['h2'] : ['h3']}>
                        <Translation value={title} />
                    </Heading>
                    <div className="projectCard_button">
                        <Button>
                            <Translation value="project.listing.label" />
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    </article>
)

ProjectCard.propTypes = {
    project: PropTypes.shape({
        title: isTranslationObject,
        category: PropTypes.string,
        startDate: PropTypes.string,
        enDate: PropTypes.string,
        thumbnail: PropTypes.string,
        slug: isTranslationObject,
    }).isRequired,
    category: PropTypes.shape({
        name: isTranslationObject,
        description: isTranslationObject,
        icon: PropTypes.string,
    }),
    isLarge: PropTypes.bool,
}

export default ProjectCard
