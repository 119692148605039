import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { getActiveLanguage, getLanguages } from 'react-localize-redux'

import { translation } from '../../utils'

const ServiceMetatags = ({ data, lang, locales }) => (
    <Helmet>
        <title itemProp="name" lang={lang}>
            {translation(data.name)}
        </title>
        {locales.map(locale => (
            <link
                rel="alternate"
                href={`${window.location.origin}/${data.slug[locale.code]}`}
                hrefLang={locale.code}
                key={locale.code}
            />
        ))}
        {data.description && <meta name="description" content={translation(data.description)} />}
    </Helmet>
)

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    locales: getLanguages(state.localize),
})

export default connect(mapStateToProps)(ServiceMetatags)
