import React from 'react'

import { Wrapper } from '../Wrapper'
import { Heading } from '../Heading'
import { Translation } from '../Translation'
import { Button } from '../Button'

import './_notFound.scss'

const NotFound = () => (
    <div className="notFound">
        <Wrapper className="notFound_wrapper">
            <div>
                <Heading el="h1" option={['h1']} utility={['white']}>
                    <Translation value="notFound.title" />
                </Heading>

                <Button option={['green', 'iconLeft']} icon="arrow-left" route="home">
                    <Translation value="notFound.button" />
                </Button>
            </div>
        </Wrapper>
    </div>
)

export default NotFound
