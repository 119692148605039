import React, { /*useState,*/ Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { useLocation } from 'react-router-dom'

import { Card } from '../../../../components/Card'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Wrapper } from '../../../../components/Wrapper'
import { Translation } from '../../../../components/Translation'
import { GoogleMap } from '../../../../components/GoogleMap'
import { Section } from '../../../../components/Section'

import { findServiceBySlug } from '../../../../utils'

import './_parkingCiviliaList.scss'

const ParkingCivilia = ({
    data: {
        statut_15min,
        statut_30min,
        statut_60min,
        heure,
        statut_operation,
        places_occupees,
        capacite,
    },
    services,
}) => {
    const retrieveParkingData = () => (
        <ul className="parkingCiviliaList">
            <li className="parkingCiviliaList_item">
                <div className="parkingCiviliaList_label">
                    <Translation value="parking.civilia.now" />
                </div>
                {places_occupees < capacite ? (
                    <span className="parkingCiviliaList_label -open">
                        <Translation value="parking.civilia.free" />
                    </span>
                ) : (
                    <span className="parkingCiviliaList_label -closed">
                        <Translation value="parking.civilia.full" />
                    </span>
                )}
            </li>
            <li className="parkingCiviliaList_item">
                <div className="parkingCiviliaList_label">15 min</div>
                {retrieveSpeculationData(statut_15min)}
            </li>
            <li className="parkingCiviliaList_item">
                <div className="parkingCiviliaList_label">30 min</div>
                {retrieveSpeculationData(statut_30min)}
            </li>
            <li className="parkingCiviliaList_item">
                <div className="parkingCiviliaList_label">60 min</div>
                {retrieveSpeculationData(statut_60min)}
            </li>
        </ul>
    )

    const retrieveSpeculationData = (speculation = 'na') => {
        switch (speculation.toLowerCase()) {
            case 'libre':
                return (
                    <span className="parkingCiviliaList_label -open">
                        <Translation value="parking.civilia.free" />
                    </span>
                )

            case 'plein':
                return (
                    <span className="parkingCiviliaList_label -closed">
                        <Translation value="parking.civilia.full" />
                    </span>
                )

            case 'na':
            case 'n/a':
            default:
                return (
                    <span className="parkingCiviliaList_label -closed">
                        Aucune prédiction possible
                    </span>
                )
        }
    }

    const retrieveParkingDataError = (type = 'Error') => (
        <li className="parkingCiviliaList_item">
            <span className="parkingCiviliaList_label -closed">
                <Translation value={type} />
            </span>
        </li>
    )

    let parkingData
    // 1 = en opération U&R
    // 2 = anomalie de circulation
    // 3 = perte de signal
    // 4 = maintenance en cours
    // statut_operation = 4
    switch (statut_operation) {
        case 1:
            parkingData = retrieveParkingData()
            break

        case 2:
            parkingData = retrieveParkingDataError('parking.civilia.anomalyCirculation')
            break

        case 3:
            parkingData = retrieveParkingDataError('parking.civilia.signalLost')
            break

        case 4:
        default:
            parkingData = retrieveParkingDataError('parking.civilia.maintenance')
            break
    }

    const { pathname } = useLocation()
    const currentService = findServiceBySlug(services, pathname)
    const { title, description } = currentService.options

    return (
        <Fragment>
            <Section>
                <Wrapper>
                    {title && (
                        <h3 className="u-textCenter">
                            <Translation value={title} />
                        </h3>
                    )}
                    {description && <Translation value={description} isHtml />}
                </Wrapper>
            </Section>

            <Section>
                <Card>
                    <Layout option={['flex', 'gutterSmall']}>
                        <LayoutItem option={['delay2']} utility={['1/2@from-medium', 'anim']}>
                            <div className={'parkingCiviliaList_map'}>
                                <GoogleMap
                                    mapCenter={[45.393922, -73.516664]}
                                    points={[[45.393922, -73.516664]]}
                                    zoom={17}
                                    defaultFitBounds={false}
                                />
                            </div>
                        </LayoutItem>
                        <LayoutItem option={['delay2']} utility={['1/2@from-medium', 'anim']}>
                            <div>{parkingData}</div>
                        </LayoutItem>
                    </Layout>
                </Card>
            </Section>
        </Fragment>
    )
}

ParkingCivilia.propTypes = {
    data: PropTypes.object.isRequired,
    services: PropTypes.array.isRequired,
}

ParkingCivilia.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    services: state.hub.services.entities,
})

export default connect(mapStateToProps)(ParkingCivilia)
