import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchCategory } from '../../actions/categoryActions'

import { PermitRequestDetails } from './'

const PermitRequestDetailsContainer = ({ serviceData }) => {
    const useFetchCategory = useCallback(() => {
        return fetchCategory(serviceData.options.category)
    }, [serviceData.options.category])

    const { items, isLoading, error } = useFetchItems(useFetchCategory)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <PermitRequestDetails.Placeholder />
                ) : (
                    <PermitRequestDetails category={items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default PermitRequestDetailsContainer
