import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/stripe`

/**
 * Create Stripe payment.
 */
export const createStripePayment = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}/verify`, formData, config)
}
