import React, { useEffect, useCallback } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
// import { getActiveLanguage } from 'react-localize-redux'
import { SwitchTransition } from 'react-transition-group'

import { Fade } from '../../../../components/Fade'
import { useDev, logErrors, useFetchItems, responseCustomizer } from '../../../../utils'

import { fetchWorkSettings } from '../../actions/work/settingActions'
import { fetchSelfSubscription as fetchSelfSubscriptionMemo } from '../../../memo/actions/subscriptionActions'
import { fetchSelf as fetchSelfSubscriptionWork } from '../../actions/work/subscriptionActions'
import { fetchCategories } from '../../../memo/actions/categoryActions'

import WorkSubscriptionForm from './WorkSubscriptionForm'

const WorkSubscriptionFormContainer = props => {
    const {
        isAuthenticated,
        hasMemoSubscription,
        fetchSelfSubscriptionMemo,
        fetchSelfSubscriptionWork,
        fetchWorkSettings,
        workSettings,
    } = props

    useEffect(() => {
        fetchWorkSettings()

        if (!isAuthenticated) {
            return () => ({ type: 'NO_FETCH' })
        }

        fetchSelfSubscriptionWork()

        if (!hasMemoSubscription) {
            return () => ({ type: 'NO_FETCH' })
        }
        fetchSelfSubscriptionMemo()
    }, [
        hasMemoSubscription,
        isAuthenticated,
        fetchWorkSettings,
        fetchSelfSubscriptionMemo,
        fetchSelfSubscriptionWork,
    ])

    // /!\ SAMPLE
    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])
    const categories = useFetchItems(useFetchCategories)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, categories, responseCustomizer)
    useDev(logErrors(error))
    // /!\ END SAMPLE

    const { general } = workSettings
    const displaySubscriptionForm =
        !_.isNil(general) && (general.emailEnabled || general.smsEnabled || general.voiceEnabled)

    if (!displaySubscriptionForm) {
        return null
    }

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <WorkSubscriptionForm.Placeholder />
                ) : (
                    <WorkSubscriptionForm {...props} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    memo:
        state.hub.auth.isAuthenticated && state.memo.subscription ? state.memo.subscription : false,
    memoSettings: state.memo.settings.entities,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
    workSettings: state.work.settings.entities,
})

const mapDispatchToProps = dispatch => ({
    fetchSelfSubscriptionMemo: config => dispatch(fetchSelfSubscriptionMemo(config)),
    fetchSelfSubscriptionWork: config => dispatch(fetchSelfSubscriptionWork(config)),
    fetchWorkSettings: config => dispatch(fetchWorkSettings(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkSubscriptionFormContainer)
