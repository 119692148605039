import React, { useCallback } from 'react'
import { connect } from 'react-redux'
// import { SwitchTransition } from 'react-transition-group'
import _ from 'lodash'

// import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchHomeDashboard } from '../../actions/homeDashboardActions'

import { useFetchItems } from '../../../../utils/containers'

import { HomeDashboard } from '../HomeDashboard'

const HomeDashboardContainer = ({ dashboardId, setIsLoaded = () => {} }) => {
    const useFetchHomeDashboard = useCallback(() => {
        if (!dashboardId) {
            return () => ({
                type: 'NO_FETCH',
            })
        } else {
            return fetchHomeDashboard(dashboardId)
        }
    }, [dashboardId])

    const { items, error, isLoading } = useFetchItems(useFetchHomeDashboard)
    useDev(logErrors(error))

    return isLoading || !_.isNil(error) ? (
        <HomeDashboard.Placeholder />
    ) : (
        <HomeDashboard dashboard={items} dashboardLoaded={() => setIsLoaded(true)} />
    )
}

const mapStateToProps = state => {
    if (_.isEmpty(state.hub.hubSettings.entities)) {
        console.error(`Hub settings could not be found.`)
        return {
            dashboardId: false,
        }
    }

    return {
        dashboardId: state.hub.auth.isAuthenticated
            ? state.hub.hubSettings.entities.homeDashboardAuthenticated
            : state.hub.hubSettings.entities.homeDashboardPublic,
    }
}

export default connect(mapStateToProps)(HomeDashboardContainer)
