import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { Translation } from '../../../../components/Translation'
import { confirmUser } from '../../actions/userActions'

const ConfirmUser = ({ token }) => {
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let didCancel = false
        const confirmUserAction = async () => {
            setIsLoading(true)
            try {
                const result = await confirmUser({ emailToken: token })
                if (!didCancel && !_.isNil(result.data)) {
                    setIsLoading(false)
                }
            } catch (error) {
                if (!didCancel) {
                    const errorMessage =
                        error.response.status === 404
                            ? 'hub.subscription.confirmation.invalidToken.title'
                            : error.response.status === 405
                            ? 'hub.subscription.confirmation.alreadyActive.title'
                            : 'hub.error.title'

                    setError(errorMessage)
                    setIsLoading(false)
                }
            }
        }

        confirmUserAction()

        return () => {
            didCancel = true
        }
    }, [token])

    if (isLoading) {
        return 'is loading'
    }

    if (error) {
        return <Translation value={error} />
    }

    return <Translation value="hub.subscription.confirmation.description" />
}

export default ConfirmUser
