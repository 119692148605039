import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import _ from 'lodash'

import { openSidebar } from '../../../../actions/sidebarActions'
import { isServer, useScript, renderRoute } from '../../../../utils'
import { createRequest } from '../../actions/requestActions'
import { formIoI18n, formIoDependencies } from '../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { Card } from '../../../../components/Card'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'

import './_formIo.scss'

// import { Form as FormIo } from 'react-formio'
const FormIo = dynamic(() => import('react-formio').then(module => module.Form), { ssr: isServer })

const PermitRequestDetails = ({
    form: { validateFields },
    lang,
    isAuthenticated,
    user,
    category,
}) => {
    const [paymentId, setPaymentId] = useState(false)
    const [isFinished, setIsFinished] = useState(false)

    const signal = axios.CancelToken.source()

    const handleSubmit = submission => {
        const dataValues = {
            user: isAuthenticated ? user.id : null,
            lang: lang,
            category: category.id,
            form: category.form.id,
            submission: submission.data,
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                await createRequest(dataValues, { cancelToken: signal.token }).then(response => {
                    // reponse 201 : no payment -> display submit message
                    // reponse 200 : payment -> redirect
                    if (response.status === 200) {
                        setPaymentId(response.data.payment)
                    }
                    setIsFinished(true)
                })
            }
        })
    }

    const state = useScript(formIoDependencies)
    const hasErrors = !_.isUndefined(_.find(state, stateItem => stateItem.error))

    if (hasErrors) {
        console.error('error loading scripts')
    }

    return paymentId ? (
        <Redirect
            to={renderRoute({
                id: 'payment-details',
                bind: 'id',
                param: paymentId,
            })}
        />
    ) : isFinished ? (
        <Wrapper>
            <CardFeedback heading="permit.success.title">
                <Button option={['blue', 'iconLeft']} icon="arrow-left" route="home">
                    <Translation value="memo.subscription.update.button" />
                </Button>
            </CardFeedback>
        </Wrapper>
    ) : (
        <Wrapper>
            <Card option={['paddingLarge']}>
                <Wrapper option={['small', 'noPaddingSmall']}>
                    <FormIo
                        form={category.form.schema}
                        onSubmit={handleSubmit}
                        options={{ i18n: formIoI18n, language: lang }}
                    />
                </Wrapper>
            </Card>
        </Wrapper>
    )
}

PermitRequestDetails.propTypes = {
    form: formShape,
    category: PropTypes.object.isRequired,
}

PermitRequestDetails.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(createForm()(PermitRequestDetails)))
