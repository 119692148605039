import React from 'react'

import PropTypes from 'prop-types'
import { Translation } from '../Translation'
import { Card } from '../Card'
import { Icon } from '../Icon'
import { Heading } from '../Heading'

import { renderClasses } from '../../utils'

import './_cardIcon.scss'

const CardIcon = ({ children, icon, heading, description, ...classProps }) => {
    return (
        <div className={renderClasses('cardIcon', classProps)}>
            <Card option={['paddingBottom', 'margin']}>
                <div className="cardIcon_wrapper">
                    <div className="cardIcon_icon">
                        <Icon name={icon} />
                    </div>
                    <Heading option={['h3']}>
                        <Translation value={heading} />
                    </Heading>
                    <p className="cardIcon_description">
                        <Translation value={description} />
                    </p>
                    {children}
                </div>
            </Card>
        </div>
    )
}

CardIcon.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default CardIcon
