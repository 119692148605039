import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import { withLocalize } from 'react-localize-redux'
import axios from 'axios'
import _ from 'lodash'

import { Button } from '../../../../components/Button'
import { Heading } from '../../../../components/Heading'
import { Spinner } from '../../../../components/Spinner'
import {
    Form,
    FormInput,
    FormItem,
    FormLabel,
    FormNotifications,
} from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { formatFormNotifications } from '../../../../utils'

import { createLostPasswordToken } from '../../actions/userActions'

const ForgotPasswordForm = ({ translate, form, form: { getFieldError, validateFields } }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (formErrors) {
                return false
            }

            setErrors([])
            setIsLoading(true)

            await createLostPasswordToken(formValues, { cancelToken: signal.token })
                .then(response => {
                    setErrors([])
                    /** Load the authenticated user's details. */
                    setIsLoading(false)
                    setIsFinished(true)
                })
                .catch(error => {
                    setErrors([{ message: "Une erreur s'est produite." }])
                    setIsLoading(false)
                })
            return false
        })
    }

    if (isLoading) {
        return <Spinner />
    }

    if (isFinished) {
        return (
            <Heading el="p" option={['h3']}>
                <Translation value="hub.forgotPassword.submit.confirm.title" />
            </Heading>
        )
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []

    return (
        <Form onSubmit={handleSubmit}>
            {!_.isEmpty(notifications) && (
                <FormItem>
                    <FormNotifications
                        hasErrors={hasErrors}
                        hasSuccess={isFinished}
                        items={notifications}
                    />
                </FormItem>
            )}
            <FormItem option={['small']}>
                <FormLabel inputId="forgot-pwd-email">
                    <Translation value="hub.loginForm.email" />
                </FormLabel>
                <FormInput
                    status={[getFieldError('email') && 'error']}
                    form={form}
                    id="forgot-pwd-email"
                    name="email"
                    options={{
                        initialValue: '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: translate('hub.loginForm.emailMissing'),
                                    },
                                ],
                            },
                        ],
                    }}
                    type="email"
                />
            </FormItem>
            <FormItem option={['button', 'small']}>
                <Button type="submit">
                    <Translation value="hub.forgotPassword.submit.title" />
                </Button>
            </FormItem>
        </Form>
    )
}

ForgotPasswordForm.propTypes = {
    translate: PropTypes.func.isRequired,
    form: formShape,
}

export default withLocalize(createForm()(ForgotPasswordForm))
