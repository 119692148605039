import _ from 'lodash'
import axios from 'axios'

import { translation } from '.'
import { fetchAddresses } from '../modules/hub/actions/addressActions'

let cancelAddressRequest
export const searchAddresses = value => {
    if (cancelAddressRequest !== undefined) {
        cancelAddressRequest()
    }

    if (_.isEmpty(value) || value.length < 2) {
        return []
    }

    return fetchAddresses(value, {
        // Executor function
        cancelToken: new axios.CancelToken(cancelFunc => {
            cancelAddressRequest = cancelFunc
        }),
    })
        .then(res => res.data)
        .then(data =>
            data.map(address => {
                return {
                    id: address.id,
                    postalCode: address.postalCode,
                    value: translation(address.fullAddress)
                        .toLowerCase()
                        .split(' ')
                        .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
                        .join(' '),
                }
            })
        )
        .catch(err => {
            return []
        })
}
