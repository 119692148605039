import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { View } from '../../../components/View'
import { Section } from '../../../components/Section'
import { CardFeedback } from '../../../components/CardFeedback'
import { Button } from '../../../components/Button'
import { Layout, LayoutItem } from '../../../components/Layout'
import { HeaderPage } from '../../../components/HeaderPage'
import { translation } from '../../../utils'
import { Wrapper } from '../../../components/Wrapper'
import { Translation } from '../../../components/Translation'

import { openSidebar } from '../../../actions/sidebarActions'

import { ConfirmUser } from '../components/ConfirmUser'

const HubConfirmUserView = ({
    match: {
        params: { tokenId },
    },
    handleClickOpenSidebar,
}) => (
    <View>
        <Helmet>
            <title itemProp="name">{translation('hub.subscription.title')}</title>
            <meta name="description" content={translation('hub.subscription.info.description')} />
        </Helmet>
        <HeaderPage
            heading="hub.subscription.title"
            breadcrumb="app.espace_citoyen.title"
            icon="subscribe"
        />
        <Wrapper>
            <CardFeedback heading="hub.subscription.confirmation.title">
                <Section option={['marginSmall']}>
                    <ConfirmUser token={tokenId} />
                </Section>
                <Layout option={['gutterSmall', 'gutterBottom', 'middle']}>
                    <LayoutItem option={['auto']}>
                        <Button option={['blue', 'iconLeft']} icon="arrow-left" route="home">
                            <Translation value="hub.subscription.confirmation.button" />
                        </Button>
                    </LayoutItem>
                    <LayoutItem option={['auto']}>
                        <Button
                            option={['green', 'iconLeft', 'large']}
                            icon="check"
                            onClick={handleClickOpenSidebar}
                        >
                            <Translation value="hub.subscription.confirmation.button2" />
                        </Button>
                    </LayoutItem>
                </Layout>
            </CardFeedback>
        </Wrapper>
    </View>
)

const mapDispatchToProps = dispatch => ({
    handleClickOpenSidebar: () => dispatch(openSidebar()),
})

export default connect(null, mapDispatchToProps)(HubConfirmUserView)
