import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { Icon } from '../Icon'

import { renderClasses } from '../../utils'

import './_formSelect.scss'

const FormSelect = props => {
    const { children, id, form, name, onChange = () => {}, options = {}, ...classProps } = props

    const select = (
        <div className={renderClasses('formSelect', classProps)}>
            <select className="formSelect_input" id={id} name={name} onChange={onChange}>
                {children.map((child, index) => {
                    const { children, value } = child.props

                    return (
                        <option value={value} key={index}>
                            {children}
                        </option>
                    )
                })}
            </select>
            <div className="formSelect_icon">
                <Icon name="arrow-down" />
            </div>
        </div>
    )

    if (form) {
        return form.getFieldDecorator(name, options)(select)
    } else {
        return select
    }
}

FormSelect.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object)]),
    form: formShape,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    type: PropTypes.string,
}

export default FormSelect
