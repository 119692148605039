import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { Icon } from '../Icon'

import { renderClasses } from '../../utils'

import './_buttonCheckbox.scss'

const ButtonCheckbox = ({
    children,
    type = 'checkbox',
    id,
    name,
    form,
    checked,
    value = 'on',
    onChange,
    disabled,
    options = {},
    onClick = () => {},
    ...classProps
}) => {
    const button = (
        <div className="buttonCheckbox">
            <input
                className="buttonCheckbox_input"
                type={type}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                defaultChecked={checked}
            />
            <label
                className={renderClasses(
                    'buttonCheckbox_label button -white -iconLeft',
                    classProps
                )}
                htmlFor={id}
                onClick={onClick}
            >
                <span className="buttonCheckbox_icon button_icon">
                    <Icon name="check" />
                </span>
                {children}
            </label>
        </div>
    )

    if (form) {
        return form.getFieldDecorator(name, options)(button)
    } else {
        return button
    }
}

ButtonCheckbox.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    form: formShape,
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.object,
    type: PropTypes.string,
}

export default ButtonCheckbox
