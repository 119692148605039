import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { useFetchItems } from '../../../../utils'

import { logout } from '../../actions/userActions'

import { fetchSelfSubscription } from '../../../memo/actions/subscriptionActions'
import { fetchMemoSettings } from '../../../memo/actions/settingActions'
import { fetchCategories as fetchMemoCategories} from '../../../memo/actions/categoryActions'

import { Profile } from '../Profile'

const ProfileContainer = props => {

    const { isHidden, fetchMemoSettings, memoSettings } = props

    useEffect(() => {
        if (!_.isEmpty(memoSettings) || isHidden) {
            return () => ({ type: 'NO_FETCH' })
        }
        fetchMemoSettings()
    }, [fetchMemoSettings, memoSettings, isHidden])

    const useFetchMemoCategories = useCallback(() => {
        return fetchMemoCategories()
    }, [])
    const memoCategories = useFetchItems(useFetchMemoCategories)

     if (isHidden) {
        return null
    }

    if (_.isEmpty(memoCategories.items) || _.isEmpty(memoSettings)) {
        return null
    }

    return <Profile memoCategories={memoCategories.items} {...props} />
}

const mapStateToProps = state => ({
    isHidden: state.hub.auth.user === null,
    user: state.hub.auth.user,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
    memoSettings: state.memo.settings.entities,
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    fetchSelfSubscription: config => dispatch(fetchSelfSubscription(config)),
    fetchMemoSettings: config => dispatch(fetchMemoSettings(config)),
})

// const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
