import { useState, useEffect } from 'react'

export const useScript = scriptObjs => {
    // Keeping track of scripts error state
    const [state, setState] = useState(
        scriptObjs.map(script => ({
            loaded: false,
            error: false,
        }))
    )

    useEffect(() => {
        scriptObjs.map((obj, index) => {
            let script
            switch (obj.type) {
                case 'link':
                    script = document.createElement('link')
                    script.rel = 'stylesheet'
                    script.href = obj.src
                    break

                case 'script':
                default:
                    script = document.createElement('script')
                    script.type = 'text/javascript'
                    script.src = obj.src
                    script.async = true
                    break
            }

            if (obj.attribute) {
                script.setAttribute(obj.attribute, 'true')
            }

            if (obj.id) {
                script.id = obj.id
            }

            // Script event listener callbacks for load and error
            const onScriptLoad = () => {
                if (typeof obj.onLoad === 'function') {
                    obj.onLoad()
                }

                setState(prevState => {
                    prevState[index].loaded = true
                    prevState[index].error = false
                    return [...prevState]
                })
            }

            const onScriptError = () => {
                setState(prevState => {
                    prevState[index].loaded = true
                    prevState[index].error = true
                    return [...prevState]
                })
            }

            script.addEventListener('load', onScriptLoad)
            script.addEventListener('error', onScriptError)

            // Add script to document body
            document.body.appendChild(script)

            // Remove event listeners on cleanup
            return () => {
                script.removeEventListener('load', onScriptLoad)
                script.removeEventListener('error', onScriptError)
            }
        })
    }, [scriptObjs])

    return state
}
