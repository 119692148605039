import React from 'react'

import { Content } from '../../../components/Content'
import { Heading } from '../../../components/Heading'
import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'

const GenericView = () => (
    <View>
        <Wrapper>
            <Heading el="h1" option={['delay1']} utility={['anim']}>
                Generic View
            </Heading>
            <Content>
                <p>Insert content here please.</p>
            </Content>
        </Wrapper>
    </View>
)

export default GenericView
