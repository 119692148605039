import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_heading.scss'

const Heading = ({ children, el = 'p', datetime, ...classProps }) => {
    const El = el
    return (
        <El className={renderClasses('heading', classProps)} dateTime={datetime}>
            {children}
        </El>
    )
}

Heading.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    el: PropTypes.string,
    datetime: PropTypes.string,
}

export default Heading
