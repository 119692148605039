import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_form.scss'

const Form = ({ children, onSubmit, ...classProps }) => (
    <form className={renderClasses('form', classProps)} onSubmit={onSubmit}>
        {children}
    </form>
)

Form.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default Form
