import React from 'react'

import { View } from '../../../components/View'

import { ProjectDetailsContainer } from '../components/Project'

const ProjectDetailsView = ({ location }) => (
    <View>
        <ProjectDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default ProjectDetailsView
