import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { openSidebar } from '../../../../actions/sidebarActions'

import { Card, CardLabel } from '../../../../components/Card'
import { Button } from '../../../../components/Button'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'
import { Icon } from '../../../../components/Icon'

import { renderRoute } from '../../../../utils'

import { FINISHED_CONTENT } from '../../../../config'
import {
    isTranslationObject,
    localizePath,
    resolveContentStatus,
    translation,
} from '../../../../utils'

import './_surveyCard.scss'

const SurveyCard = ({ survey, category, isCompleted = false, isAuthenticated, openSidebar }) => {
    const status = resolveContentStatus(survey.startDate, survey.endDate)
    const { isExternal } = survey

    const labelNumQuestions =
        survey.numQuestions > 1 ? (
            <Translation value="survey.details.questions.title" />
        ) : (
            <Translation value="survey.details.question.title" />
        )

    let surveyBtn
    if (isExternal) {
        const surveyUrl = _.isObject(survey.externalSurveyUrl)
            ? localizePath(survey.externalSurveyUrl)
            : ''
        const surveyResultUrl = _.isObject(survey.externalSurveyResultsUrl)
            ? localizePath(survey.externalSurveyResultsUrl)
            : ''
        surveyBtn =
            status === translation(FINISHED_CONTENT) && surveyResultUrl ? (
                <Button
                    option={['green', 'iconRight']}
                    icon="external"
                    status={['completed']}
                    external={true}
                    href={surveyResultUrl}
                >
                    <Translate id="survey.details.see_results.title" />
                </Button>
            ) : status !== translation(FINISHED_CONTENT) && surveyUrl ? (
                <Button option={['iconRight']} icon="external" external={true} href={surveyUrl}>
                    <Translate id="survey.details.participate.title" />
                </Button>
            ) : null
    } else {
        surveyBtn =
            status === translation(FINISHED_CONTENT) ? (
                <Button
                    option={['green']}
                    href={renderRoute({
                        id: 'survey-result-details',
                        bind: 'slug',
                        param: localizePath(survey.slug)
                            .split('/')
                            .splice(3)
                            .join('/'),
                    })}
                >
                    <Translate id="survey.details.see_results.title" />
                </Button>
            ) : isCompleted ? (
                <Button option={['white', 'iconRight']} status={['completed']} icon="check">
                    <Translate id="survey.details.already_completed.title" />
                </Button>
            ) : isAuthenticated || survey.allowAnonymous ? (
                <Button href={localizePath(survey.slug)}>
                    <Translate id="survey.details.participate.title" />
                </Button>
            ) : (
                <Button onClick={openSidebar}>
                    <Translation value="survey.details.login.title" />
                </Button>
            )
    }

    return (
        <Card option={['marginSmall']} status={[isCompleted ? 'completed' : '']}>
            <div className="surveyCard">
                <CardLabel option={['blue']}>
                    <Translation value={category.name} />
                </CardLabel>
                <div className="surveyCard_heading">
                    <Heading el="h3" option={['h2']}>
                        <Translation value={survey.title} />
                    </Heading>
                </div>
                <div className="surveyCard_footer">
                    {surveyBtn}
                    {status !== translation(FINISHED_CONTENT) && !isCompleted && (
                        <div className="surveyCard_footer_info">
                            {!survey.isExternal && survey.numQuestions !== 0 && (
                                <div>
                                    <strong>{survey.numQuestions} </strong>
                                    {labelNumQuestions} <br />
                                </div>
                            )}
                            {survey.duration && (
                                <div>
                                    <Icon name="time" />
                                    {survey.duration} min
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    )
}

SurveyCard.Placeholder = () => <ContentLoader></ContentLoader>

SurveyCard.propTypes = {
    survey: PropTypes.shape({
        allowAnonymous: PropTypes.bool,
        title: isTranslationObject,
        startDate: PropTypes.string,
        enDate: PropTypes.string,
        numQuestions: PropTypes.number,
        slug: isTranslationObject,
    }).isRequired,
    category: PropTypes.shape({
        name: isTranslationObject,
    }),
    isCompleted: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    openSidebar: PropTypes.func,
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCard)
