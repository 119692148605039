import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchIdeas } from '../../actions/ideaActions'
import { fetchCategories } from '../../actions/categoryActions'
import { fetchSelf, fetchAnonymousVotes } from '../../actions/voteActions'
// import { fetchService } from '../../../hub/actions/serviceActions'

import { IdeaList } from './'

const IdeaListContainer = ({ isAuthenticated, fetchSelf, fetchAnonymousVotes }) => {
    /*
    const useFetchService = useCallback(() => {
        return fetchService(serviceData.id)
    }, [serviceData.id])
    */

    useEffect(() => {
        if (!isAuthenticated) {
            // return () => false
            fetchAnonymousVotes()
        } else {
            fetchSelf()
        }
    }, [isAuthenticated, fetchSelf, fetchAnonymousVotes])

    const useFetchIdeas = useCallback(() => {
        return fetchIdeas()
    }, [])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    // const service = useFetchItems(useFetchService)
    const ideas = useFetchItems(useFetchIdeas)
    const categories = useFetchItems(useFetchCategories)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, ideas, categories, responseCustomizer)
    useDev(logErrors(result.error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <IdeaList.Placeholder />
                ) : (
                    <IdeaList
                        ideas={ideas.items}
                        categories={categories.items}
                        // flashsurvey={service.items.flashsurvey}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
})

const mapDispatchToProps = dispatch => ({
    fetchSelf: config => dispatch(fetchSelf(config)),
    fetchAnonymousVotes: () => dispatch(fetchAnonymousVotes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdeaListContainer)
