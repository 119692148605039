import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import { Heading } from '../../../components/Heading'
import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'
import { HeaderPage } from '../../../components/HeaderPage'
import { Button } from '../../../components/Button'
import { Translation } from '../../../components/Translation'
import { NotFound } from '../../../components/NotFound'
import { Card } from '../../../components/Card'
import { Spinner } from '../../../components/Spinner'

import confirmationImage from '../../../assets/images/idea_comment.png'

import { deleteUser, logout } from '../actions/userActions'
import { LOCALSTORAGE_SUBSCRIBED_USERID, LOCALSTORAGE_SUBSCRIBED_USER_EMAIL } from '../config'

const HubDeleteProfileView = ({ isAuthenticated, logout }) => {
    const [isConfirmation, setIsConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleDeleteClick = useCallback(() => {
        setIsLoading(true)
        deleteUser().then(() => {
            setIsConfirmation(true)
            setIsLoading(false)
            // be sure to remove all data if user is deleted
            localStorage.removeItem(LOCALSTORAGE_SUBSCRIBED_USERID)
            localStorage.removeItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL)
            logout()
        })
    }, [logout])

    return (
        <View>
            {!isAuthenticated && !isConfirmation ? (
                <NotFound />
            ) : (
                <div>
                    <HeaderPage
                        heading="hub.deleteProfile.title"
                        breadcrumb="app.espace_citoyen.title"
                        icon="subscribe"
                    />

                    <Wrapper>
                        <Card option={['paddingLarge']}>
                            <Wrapper option={['medium']}>
                                <div className="u-textCenter">
                                    <img
                                        className="paymentDetails_finished_image"
                                        src={confirmationImage}
                                        alt={
                                            <Translation value="payment.form.confirmation.title" />
                                        }
                                    />

                                    {isLoading ? (
                                        <Spinner />
                                    ) : (
                                        <React.Fragment>
                                            {isConfirmation ? (
                                                <React.Fragment>
                                                    <div className="u-margin-bottom-small">
                                                        <Heading option={['h2']}>
                                                            <Translation value="hub.deleteProfile.success" />
                                                        </Heading>
                                                    </div>

                                                    <Button
                                                        option={[
                                                            'large',
                                                            'green',
                                                            'icon',
                                                            'iconLeft',
                                                        ]}
                                                        icon="arrow-left"
                                                        route="home"
                                                    >
                                                        <Translation value="hub.subscription.edit.button" />
                                                    </Button>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="u-margin-bottom-small">
                                                        <Heading option={['h3']}>
                                                            <Translation value="hub.deleteProfile.description" />
                                                        </Heading>
                                                    </div>

                                                    <Button
                                                        option={['large', 'red']}
                                                        onClick={handleDeleteClick}
                                                    >
                                                        <Translation value="hub.deleteProfile.label" />
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Wrapper>
                        </Card>
                    </Wrapper>
                </div>
            )}
        </View>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HubDeleteProfileView)
