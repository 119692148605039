import { WORK_API_URL } from '../../config'

const baseUrl = `${WORK_API_URL}/subscriptions`

/**
 * Create a new work's subscription.
 */
export const CREATE_WORK_SUBSCRIPTION_REQUEST = 'CREATE_WORK_SUBSCRIPTION_REQUEST'
export const CREATE_WORK_SUBSCRIPTION_SUCCESS = 'CREATE_WORK_SUBSCRIPTION_SUCCESS'
export const CREATE_WORK_SUBSCRIPTION_FAILURE = 'CREATE_WORK_SUBSCRIPTION_FAILURE'
export const createSubscription = (formData = {}, config = {}) => {
    return {
        promise: client => client.post(`${baseUrl}`, formData, config),
        types: [
            CREATE_WORK_SUBSCRIPTION_REQUEST,
            CREATE_WORK_SUBSCRIPTION_SUCCESS,
            CREATE_WORK_SUBSCRIPTION_FAILURE,
        ],
    }
}

/**
 * Retrieve the authenticated user's work's subscriptions.
 */
export const FETCH_WORK_SUBSCRIPTION_SELF_REQUEST = 'FETCH_WORK_SUBSCRIPTION_SELF_REQUEST'
export const FETCH_WORK_SUBSCRIPTION_SELF_SUCCESS = 'FETCH_WORK_SUBSCRIPTION_SELF_SUCCESS'
export const FETCH_WORK_SUBSCRIPTION_SELF_FAILURE = 'FETCH_WORK_SUBSCRIPTION_SELF_FAILURE'
export const fetchSelf = (config = {}) => {
    return {
        promise: client => client.get(`${baseUrl}/self`, config),
        types: [
            FETCH_WORK_SUBSCRIPTION_SELF_REQUEST,
            FETCH_WORK_SUBSCRIPTION_SELF_SUCCESS,
            FETCH_WORK_SUBSCRIPTION_SELF_FAILURE,
        ],
    }
}

/**
 * Update an exisiting work's subscription.
 */
export const UPDATE_WORK_SUBSCRIPTION_REQUEST = 'UPDATE_WORK_SUBSCRIPTION_REQUEST'
export const UPDATE_WORK_SUBSCRIPTION_SUCCESS = 'UPDATE_WORK_SUBSCRIPTION_SUCCESS'
export const UPDATE_WORK_SUBSCRIPTION_FAILURE = 'UPDATE_WORK_SUBSCRIPTION_FAILURE'
export const UPDATE_WORK_SUBSCRIPTION_PENDING = 'UPDATE_WORK_SUBSCRIPTION_PENDING'
export const updateSubscription = (formData = {}, config = {}) => {
    return {
        promise: client => client.patch(`${baseUrl}/self`, formData, config),
        types: [
            UPDATE_WORK_SUBSCRIPTION_REQUEST,
            UPDATE_WORK_SUBSCRIPTION_SUCCESS,
            UPDATE_WORK_SUBSCRIPTION_FAILURE,
            UPDATE_WORK_SUBSCRIPTION_PENDING,
        ],
    }
}

/**
 * Delete a login session, unauthenticating the authenticated user.
 */
export const DELETE_WORK_SUBSCRIPTION_REQUEST = 'DELETE_WORK_SUBSCRIPTION_REQUEST'
export const DELETE_WORK_SUBSCRIPTION_SUCCESS = 'DELETE_WORK_SUBSCRIPTION_SUCCESS'
export const DELETE_WORK_SUBSCRIPTION_FAILURE = 'DELETE_WORK_SUBSCRIPTION_FAILURE'
export const DELETE_WORK_SUBSCRIPTION_PENDING = 'DELETE_WORK_SUBSCRIPTION_PENDING'
export const deleteSubscription = (subscriptionId, config = {}) => {
    return {
        promise: client => client.delete(`${baseUrl}/self/${subscriptionId}`, config),
        types: [
            DELETE_WORK_SUBSCRIPTION_REQUEST,
            DELETE_WORK_SUBSCRIPTION_SUCCESS,
            DELETE_WORK_SUBSCRIPTION_FAILURE,
            DELETE_WORK_SUBSCRIPTION_PENDING,
        ],
    }
}
