import IdeaListView from '../views/IdeaListView'
import IdeaSubmitView from '../views/IdeaSubmitView'
import { SERVICE_IDEA_IDEAS } from '.'

export const componentMap = {}
export const viewMap = {
    'city/idea/service/ideas': IdeaListView,
    'city/idea/service/ideas/submit': IdeaSubmitView,
}

export const routeServiceMap = {
    'idea-details': SERVICE_IDEA_IDEAS,
}
