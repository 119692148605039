import React from 'react'

import { FormItem, FormLabel, FormInput } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'

const QuestionTextarea = ({
    question: { id, choices, required },
    form,
    form: { getFieldValue, getFieldError },
    formValues = {},
}) => (
    <Wrapper option={['small']}>
        <FormItem option={['anim']}>
            <FormLabel inputId="textarea">
                <Translation value="survey.details.answer.textarea.title" />
            </FormLabel>

            <FormInput
                id={id}
                type="textarea"
                form={form}
                status={[getFieldError(id) && 'error']}
                name={id}
                options={{
                    preserve: getFieldValue(id) ? true : false,
                    initialValue: formValues[id] ? formValues[id] : '',
                    validate: [
                        {
                            trigger: 'onChange',
                            rules: [
                                {
                                    required: required,
                                },
                            ],
                        },
                    ],
                }}
            />
        </FormItem>
    </Wrapper>
)
export default QuestionTextarea
