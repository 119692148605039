import React from 'react'

import { Transition } from 'react-transition-group'

const defaultStyle = (duration, state) => {
    return {
        transition: `opacity ${duration}ms ${easing[state]}`,
        opacity: 0,
    }
}

const easing = {
    entering: 'ease-in',
    entered: 'ease-in',
    exiting: 'ease-out',
    exited: 'ease-out',
}

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
}

const Fade = props => (
    <Transition {...props} timeout={props.timeout || 250}>
        {state => (
            <div
                style={{
                    ...defaultStyle(props.timeout, state),
                    ...transitionStyles[state],
                }}
            >
                {props.children}
            </div>
        )}
    </Transition>
)

export default Fade
