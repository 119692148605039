import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FormSelect } from '../Form'
import { FilterButtons } from '../FilterButtons'
import { FilterButtonsItem } from '../FilterButtons'

import { ALL_BUTTON_CATEGORIES_VALUE } from '../../config'
import { translation } from '../../utils/'

import './_filterPicker.scss'

const FilterPicker = ({ categories, triggerSelection, translate, option = [''] }) => {
    const [categorySelected, setCategorySelected] = useState(ALL_BUTTON_CATEGORIES_VALUE)

    useEffect(() => {
        triggerSelection(categorySelected)
    }, [triggerSelection, categorySelected])

    //@TODO refactor unshift all button flickering without this condition
    if (categories[0].id !== ALL_BUTTON_CATEGORIES_VALUE) {
        categories.unshift({
            id: ALL_BUTTON_CATEGORIES_VALUE,
            name: 'content.faq.listing.categories.all.title',
        })
    }

    return (
        <React.Fragment>
            <div className="u-none@to-medium">
                <FilterButtons option={option}>
                    {categories.map((category, index) => (
                        <FilterButtonsItem
                            selected={categorySelected === category.id}
                            onClick={() => setCategorySelected(category.id)}
                            key={index}
                            label={category.name}
                        />
                    ))}
                </FilterButtons>
            </div>

            <div className="u-none@from-medium">
                <div className="filterPicker">
                    <FormSelect
                        name="category"
                        id="filterPicker"
                        onChange={e => setCategorySelected(e.target.value)}
                    >
                        {categories.map((category, index) => (
                            <option key={index} value={category.id}>
                                {translation(category.name)}
                            </option>
                        ))}
                    </FormSelect>
                </div>
            </div>
        </React.Fragment>
    )
}

FilterPicker.propTypes = {
    categories: PropTypes.array.isRequired,
    triggerSelection: PropTypes.func.isRequired,
}

export default FilterPicker
