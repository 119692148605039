import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Button } from '../../../../components/Button'
import { Wrapper } from '../../../../components/Wrapper'
import { Heading } from '../../../../components/Heading'
import { Section } from '../../../../components/Section'

import {
    isTranslationObject,
    findServiceByType,
    resolveContentStatus,
    translation,
} from '../../../../utils'

import { SERVICE_WORK_WORKS } from '../../config'
import { FINISHED_CONTENT } from '../../../../config'

import './_workHeader.scss'

const WorkHeader = ({
    work: { title, image, description, startDate, endDate, externalLink },
    category,
    service,
}) => {
    let status = resolveContentStatus(startDate, endDate)
    if (status === translation(FINISHED_CONTENT)) {
        status = 'finished'
    }
    const statusTranslation = `work.details.${status}.title`

    return (
        <div className="workHeader">
            <HeaderPage
                option={['generic']}
                heading={title}
                subheading={
                    <Fragment>
                        {category && <Translation value={category.name} />} |{' '}
                        <Translation value={statusTranslation} />
                    </Fragment>
                }
                breadcrumb={service && service.name}
                backLink={service && translation(service.slug)}
            />

            <Section>
                <div className="u-relative">
                    {externalLink && (
                        <div className="workHeader_live">
                            <Button
                                option={['white', 'circleIcon', 'iconRight']}
                                external={true}
                                href={translation(externalLink)}
                                icon={'camera'}
                            >
                                <Translation value="work.details.header.live.title" />
                            </Button>
                        </div>
                    )}

                    {image && (
                        <div className="workHeader_image">
                            <img src={image} alt={translation(title)} />
                        </div>
                    )}
                </div>
            </Section>

            <div className="contentBlock">
                <Wrapper option={['medium']}>
                    <Heading el="p" option={['h2']}>
                        <>{description && <Translation isHtml value={description} />}</>
                    </Heading>
                </Wrapper>
            </div>
        </div>
    )
}

WorkHeader.propTypes = {
    work: PropTypes.shape({
        title: isTranslationObject,
        image: PropTypes.string,
        description: isTranslationObject,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }).isRequired,
    category: PropTypes.shape({
        name: PropTypes.object,
    }).isRequired,
}

const mapStateToProps = state => ({
    service: findServiceByType(state.hub.services.entities, SERVICE_WORK_WORKS),
})

export default connect(mapStateToProps)(WorkHeader)
