import React from 'react'
import { connect } from 'react-redux'
import { getLanguages } from 'react-localize-redux'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

import PropTypes from 'prop-types'

import ContentLoader from 'react-content-loader'

import { ONGOING_CONTENT } from '../../../../config'
import { translation, resolveContentStatus } from '../../../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { SurveyCardContainer } from '../../../survey/components/Survey'
import { FlashSurveyContainer } from '../../../survey/components/FlashSurvey'
import { WorkList, WorkHeader } from '.'
import { WorkNewsCardList } from '../WorkNews'
import { ContentNav, ContentBlocks } from '../../../../components/ContentBlock'
import { ContentBlockHeader } from '../../../../components/ContentBlock'
import WorkSubscriptionFormContainer from './WorkSubscriptionFormContainer'

const WorkDetails = ({ work, categories, locales, workSettings }) => {
    const currentCategory = _.find(categories, category => category.id === work.category)
    const status = resolveContentStatus(work.startDate, work.endDate)
    let extraNavContent = []

    if (!_.isEmpty(work.news)) {
        extraNavContent.push({
            id: translation('work.details.news.anchor'),
            title: 'work.details.news.title',
        })
    }

    if (work.survey || work.flashSurvey) {
        extraNavContent.push({
            id: translation('survey.listing.anchor'),
            title: 'survey.listing.title',
        })
    }

    const isSubscriptionEnabled = status === ONGOING_CONTENT

    work.attachments.unshift(...extraNavContent)
    if (isSubscriptionEnabled) {
        const subscriptionNavContent = [
            {
                id: translation('work.details.subscription.anchor'),
                title: 'work.subscription.title',
            },
        ]
        work.attachments.unshift(...subscriptionNavContent)
    }

    return (
        <div>
            <Helmet>
                <title itemProp="name">{translation(work.title)}</title>
                {work.description && (
                    <meta name="description" content={translation(work.description)} />
                )}
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={work.startDate} />
                <meta property="article:modified_time" content={work.lastModified} />
                <meta property="article:expiration_time" content={work.endDate} />
                {locales.map(locale => (
                    <link
                        rel="alternate"
                        href={`${window.location.origin}/${work.slug[locale.code]}`}
                        hrefLang={locale.code}
                        key={locale.code}
                    />
                ))}
            </Helmet>
            <article className="Work">
                <WorkHeader work={work} category={currentCategory} />

                <ContentNav blocks={work.attachments} />
                {isSubscriptionEnabled && (
                    <div
                        className="contentBlock"
                        id={translation('work.details.subscription.anchor')}
                    >
                        <Wrapper option={['medium']}>
                            <WorkSubscriptionFormContainer work={work} />
                        </Wrapper>
                    </div>
                )}
                {!_.isEmpty(work.news) && (
                    <div id={translation('work.details.news.anchor')} className="u-margin-bottom">
                        <Wrapper option={['medium']}>
                            <WorkNewsCardList news={work.news} />
                        </Wrapper>
                    </div>
                )}
                <ContentBlocks blocks={work.attachments} showNav={false} />

                {(work.survey || work.flashSurvey) && (
                    <div className="contentBlock" id={translation('survey.listing.anchor')}>
                        <Wrapper option={['medium']}>
                            <ContentBlockHeader
                                id="survey"
                                title={'survey.listing.title'}
                                option={['border']}
                            />

                            {work.survey && (
                                <div id={work.survey.id} className="u-margin-bottom">
                                    <SurveyCardContainer survey={work.survey} />
                                </div>
                            )}
                        </Wrapper>
                    </div>
                )}

                {work.flashSurvey && (
                    <div id={work.flashSurvey.id}>
                        <FlashSurveyContainer flashsurvey={work.flashSurvey} offset={false} />
                    </div>
                )}

                {!_.isEmpty(work.relatedWorks) && (
                    <WorkList
                        works={work.relatedWorks}
                        categories={categories}
                        filter={status}
                        viewFilter="related"
                    />
                )}
            </article>
        </div>
    )
}

WorkDetails.propTypes = {
    work: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    locales: PropTypes.array,
}

const mapStateToProps = state => ({
    locales: getLanguages(state.localize),
})

WorkDetails.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    ></ContentLoader>
)

export default connect(mapStateToProps)(WorkDetails)
