import _ from 'lodash'
import React, { useCallback } from 'react'
import { SwitchTransition } from 'react-transition-group'

import { logErrors, responseCustomizer, useDev, useFetchItems } from '../../../../utils'
import { fetchAlertBySlug } from '../../actions/alertActions'
import { fetchCategory } from '../../actions/categoryActions'
import { AlertDetails } from '../Alert'
import { Fade } from '../../../../components/Fade'
import { NotFound } from '../../../../components/NotFound'

const AlertDetailsContainer = ({ slug }) => {
    const useFetchAlert = useCallback(() => {
        return fetchAlertBySlug(slug)
    }, [slug])

    const alert = useFetchItems(useFetchAlert)

    const useFetchCategory = useCallback(() => {
        if (_.isNil(alert.items.category)) {
            return () => {}
        }
        return fetchCategory(alert.items.category)
    }, [alert.items.category])

    const category = useFetchItems(useFetchCategory)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, alert, category, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {alert.requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <AlertDetails.Placeholder />
                ) : (
                    <AlertDetails alert={alert.items} category={category.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default AlertDetailsContainer
