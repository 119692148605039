import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { formShape } from 'rc-form'

import { Wrapper } from '../../../../components/Wrapper'
import { Card, CardHeader } from '../../../../components/Card'
import { FormInput, FormItem, FormError } from '../../../../components/Form'

import { SubscriptionFormZoneDetails } from '.'

const SubscriptionFormZoneList = ({
    memoSettings: {
        general: { categoryParking, categorySnow, categoryWaste },
    },
    memo: { parkingZones, snowZones, wasteZones },
    form: { getFieldValue, getFieldError, setFieldsValue },
    form,
    translate,
    categories,
    formValues,
    zones,
}) => {
    const [selectedParkingCategories, setSelectedParkingCategories] = useState(parkingZones || [])
    const [selectedSnowCategories, setSelectedSnowCategories] = useState(snowZones || [])
    const [selectedWasteCategories, setSelectedWasteCategories] = useState(wasteZones || [])

    const retrieveSelectedZones = (type, zones) => {
        const selectedZones = _.map(
            _.filter(zones, zone => zone.selected),
            zone => zone.id
        )

        if (type === 'parking') {
            setSelectedParkingCategories(selectedZones)
            setFieldsValue({ parkingZones: selectedZones })
        } else if (type === 'snow') {
            setSelectedSnowCategories(selectedZones)
            setFieldsValue({ snowZones: selectedZones })
        } else if (type === 'waste') {
            setSelectedWasteCategories(selectedZones)
            setFieldsValue({ wasteZones: selectedZones })
        }
    }

    return (
        <div>
            <Wrapper option={['small', 'noPaddingSmall']}>
                <CardHeader
                    heading="memo.subscription.zones.title"
                    description="memo.subscription.zones.description"
                />
            </Wrapper>
            <Wrapper option={['large', 'noPaddingSmall']}>
                {formValues.categories.map(catId => {
                    if (catId === categoryParking) {
                        return (
                            <Card option={['noPadding', 'margin']} key={catId}>
                                <CardHeader
                                    option={['small']}
                                    heading="memo.subscription.zones.parking.title"
                                />
                                <FormItem option={['noMargin']}>
                                    <FormInput
                                        option={['hidden']}
                                        form={form}
                                        id="parkingZones"
                                        name="parkingZones"
                                        value={selectedParkingCategories}
                                        options={{
                                            initialValue: selectedParkingCategories,
                                            validateFirst: true,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: translate(
                                                        'memo.subscription.zones.parking.error.required'
                                                    ),
                                                },
                                            ],
                                        }}
                                        type="hidden"
                                    />
                                </FormItem>
                                <FormError>{getFieldError('parkingZones')}</FormError>
                                <SubscriptionFormZoneDetails
                                    data={zones.parking}
                                    defaultSelectedZones={selectedParkingCategories}
                                    sendZones={data => retrieveSelectedZones('parking', data)}
                                />
                            </Card>
                        )
                    } else if (catId === categorySnow) {
                        return (
                            <Card option={['noPadding', 'margin']} key={catId}>
                                <CardHeader
                                    option={['small']}
                                    heading="memo.subscription.zones.snow.title"
                                />
                                <FormItem option={['noMargin']}>
                                    <FormInput
                                        option={['hidden']}
                                        form={form}
                                        id="snowZones"
                                        name="snowZones"
                                        value={selectedSnowCategories}
                                        options={{
                                            initialValue: selectedSnowCategories,
                                            validateFirst: true,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: translate(
                                                        'memo.subscription.zones.snow.error.required'
                                                    ),
                                                },
                                            ],
                                        }}
                                        type="hidden"
                                    />
                                </FormItem>
                                <FormError>{getFieldError('snowZones')}</FormError>
                                <SubscriptionFormZoneDetails
                                    data={zones.snow}
                                    defaultSelectedZones={selectedSnowCategories}
                                    sendZones={data => retrieveSelectedZones('snow', data)}
                                />
                            </Card>
                        )
                    } else if (catId === categoryWaste) {
                        return (
                            <Card option={['noPadding', 'margin']} key={catId}>
                                <CardHeader
                                    option={['small']}
                                    heading="memo.subscription.zones.waste.title"
                                />
                                <FormItem option={['noMargin']}>
                                    <FormInput
                                        option={['hidden']}
                                        form={form}
                                        id="wasteZones"
                                        name="wasteZones"
                                        value={selectedWasteCategories}
                                        options={{
                                            initialValue: selectedWasteCategories,
                                            validateFirst: true,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: translate(
                                                        'memo.subscription.zones.waste.error.required'
                                                    ),
                                                },
                                            ],
                                        }}
                                        type="hidden"
                                    />
                                </FormItem>
                                <FormError>{getFieldError('wasteZones')}</FormError>
                                <SubscriptionFormZoneDetails
                                    data={zones.waste}
                                    defaultSelectedZones={selectedWasteCategories}
                                    sendZones={data => retrieveSelectedZones('waste', data)}
                                />
                            </Card>
                        )
                    }
                    return null
                })}
            </Wrapper>
        </div>
    )
}

SubscriptionFormZoneList.propTypes = {
    categories: PropTypes.array.isRequired,
    form: formShape,
    formValues: PropTypes.object,
    lang: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    memo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    memoSettings: PropTypes.object,
    translate: PropTypes.func,
    zones: PropTypes.object.isRequired,
}

export default SubscriptionFormZoneList
