import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'
import { CardHeader } from '../../../../components/Card'

import './_paymentDetails.scss'

import confirmationImage from '../../../../assets/images/idea_comment.png'

const PaymentDetailsConfirmation = ({
    confirmationTitle = 'payment.form.confirmation.title',
    confirmationDescription = 'payment.form.confirmation.description',
}) => (
    <div className="u-textCenter">
        <img
            className="paymentDetails_finished_image"
            src={confirmationImage}
            alt={<Translation value="payment.form.confirmation.title" />}
        />

        <CardHeader heading={confirmationTitle} description={confirmationDescription} />

        <div className="paymentDetails_finished_button">
            <Button option={['large', 'green', 'icon', 'iconLeft']} icon="arrow-left" route="home">
                <Translation value="payment.confirmation.button" />
            </Button>
        </div>
    </div>
)

PaymentDetailsConfirmation.propTypes = {
    confirmationTitle: PropTypes.string,
    confirmationDescription: PropTypes.string,
}

export default PaymentDetailsConfirmation
