import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Button } from '../../../../components/Button'
import { Content } from '../../../../components/Content'
import { Translation } from '../../../../components/Translation'

const SurveyCategoryList = ({ categories }) => {
    if (!categories.length) {
        return null
    }

    const renderedCategories = categories.map(category => (
        <Button key={category.id}>
            <Translation key={category.id} value={category.name} />
        </Button>
    ))

    return (
        <React.Fragment>
            <Content>{renderedCategories}</Content>
        </React.Fragment>
    )
}

SurveyCategoryList.propTypes = {
    categories: PropTypes.array.isRequired,
}

SurveyCategoryList.Placeholder = () => <ContentLoader></ContentLoader>

export default SurveyCategoryList
