import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import { Wrapper } from '../Wrapper'
import { Translation } from '../Translation'

import './_progressBar.scss'

const ProgressBar = ({ step, total, data, ...classProps }) => {
    const steps = total || data.length
    const title = typeof data === 'string' ? data : data[step]
    const progress = ((step + 1) / steps) * 100
    const progressContent = `${step + 1} / ${steps}`

    return (
        <div className={renderClasses('progressBar', classProps)}>
            <Wrapper>
                <div className="progressBar_content">
                    <Translation value={title} />
                    <span>{progressContent}</span>
                </div>
            </Wrapper>
            <div className="progressBar_bar">
                <div className="progressBar_thumb" style={{ width: progress + '%' }}></div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    step: PropTypes.number.isRequired,
}

export default ProgressBar
