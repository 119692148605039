// import React from 'react'
import { connect } from 'react-redux'

import { openSidebar } from '../../../../actions/sidebarActions'
import ForgotPasswordResetForm from './ForgotPasswordResetForm'

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

const ForgotPasswordResetContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordResetForm)

export default ForgotPasswordResetContainer
