import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Slider from 'react-slick'

import { ContentBlockHeader } from '.'
import { Wrapper } from '../Wrapper'

import { Translation } from '../Translation'
import { Button } from '../Button'
import { isTranslationObject, translation } from '../../utils'

import './_slick.min.scss'
import './_contentGallery.scss'

const ContentBlockGallery = ({ title, images, id }) => {
    const GalleryNext = props => {
        const { className, onClick } = props
        return (
            <div className={'alertSlider_arrow -next ' + className}>
                <Button
                    option={['circle', 'white', 'small']}
                    icon="arrow-right"
                    onClick={onClick}
                ></Button>
            </div>
        )
    }

    const GalleryPrev = props => {
        const { className, onClick } = props
        return (
            <div className={'alertSlider_arrow -prev ' + className}>
                <Button
                    option={['circle', 'white', 'small']}
                    icon="arrow-left"
                    onClick={onClick}
                ></Button>
            </div>
        )
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <GalleryNext />,
        prevArrow: <GalleryPrev />,
    }

    return (
        <div className={`contentBlock contentGallery`} id={id}>
            {title && (
                <Wrapper option={['medium']}>
                    <ContentBlockHeader title={title} />
                </Wrapper>
            )}

            <Wrapper option={['medium']}>
                <Slider className="contentGallery_slider" {...settings}>
                    {images.map((image, index) => {
                        const imageProps = _.omit(image, 'type')
                        if (image.type === 'image' && imageProps.image) {
                            return (
                                <div key={index} className="contentGallery_slide">
                                    <img
                                        src={translation(imageProps.image)}
                                        alt={imageProps.caption && translation(imageProps.caption)}
                                        className="contentImage_image"
                                    />

                                    <span className="contentImage_caption">
                                        {imageProps.caption && (
                                            <Translation value={imageProps.caption} />
                                        )}
                                    </span>
                                </div>
                            )
                        }
                        return null
                    })}
                </Slider>
            </Wrapper>
        </div>
    )
}

ContentBlockGallery.propTypes = {
    title: isTranslationObject,
    images: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default ContentBlockGallery
