import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import _ from 'lodash'

import { Translation } from '../../components/Translation'
import { renderClasses } from '../../utils'

import './_formAutocomplete.scss'

// @TODO: Replace div autocomplete listing with data-list HTML5 (currently not really working with FF)
const FormAutocomplete = ({
    id,
    form,
    form: { setFieldsValue, validateFields },
    name,
    items = [],
    // default options does not work
    options = {
        validate: [
            {
                rules: [
                    {
                        message: 'Invalid',
                        validator: (rule, value) => {
                            if (_.isEmpty(items)) return true
                            return _.isEmpty(items.filter(item => item.value === value))
                                ? false
                                : true
                        },
                    },
                ],
            },
        ],
    },
    placeholder = '',
    autoComplete = 'new-password',
    asyncData = false,
    ...classProps
}) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState(items)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const [userInput, setUserInput] = useState(options.initialValue || '')
    const [hasClickOption, setHasClickOption] = useState(false)
    const [isChange, setIsChange] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
        // trigger validation
        if (hasClickOption) {
            inputRef.current.blur()
            // trigger user input on login/logout user
        } else {
            setUserInput(options.initialValue || '')
        }
    }, [hasClickOption, options.initialValue])

    const onChange = async event => {
        setUserInput(event.currentTarget.value)
        setHasClickOption(false)
        setIsChange(true)
        if (asyncData) {
            const data = await asyncData(event)
            if (_.isEmpty(data)) {
                setShowSuggestions(false)
                return null
            }
            setFilteredSuggestions(data)
        } else {
            const filteredSuggestions = items.filter(
                suggestion => suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            )
            setFilteredSuggestions(filteredSuggestions)
        }
    }

    const onKeyDown = e => {
        setShowSuggestions(true)
        setHasClickOption(false)

        if (e.keyCode === 13) {
            e.preventDefault()

            if (_.isEmpty(filteredSuggestions)) {
                return null
            }

            setActiveSuggestion(0)
            setShowSuggestions(false)
            setUserInput(filteredSuggestions[activeSuggestion].value)
            setFilteredSuggestions([])
            setHasClickOption(true)
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return null
            }
            setActiveSuggestion(activeSuggestion - 1)
        } else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return null
            }
            if (!_.isNil(filteredSuggestions[activeSuggestion + 1])) {
                setActiveSuggestion(activeSuggestion + 1)
            }
        }
    }

    const onClick = index => {
        setActiveSuggestion(index)
        setFilteredSuggestions([])
        setShowSuggestions(false)
        setUserInput(filteredSuggestions[index].value)
        inputRef.current.focus()
        setHasClickOption(true)
    }

    const suggestionsList = filteredSuggestions.map((suggestion, index) => {
        if (!showSuggestions) {
            return null
        }

        return (
            <div
                key={index}
                onClick={e => onClick(index)}
                className={`form_autocomplete_item ${
                    index === activeSuggestion ? 'is-active' : ''
                }`}
            >
                {suggestion.value}
            </div>
        )
    })

    const input = (
        <div className="form_wrap">
            <input
                id={id}
                name={name}
                className={renderClasses('form_input form_autocomplete', classProps)}
                type="text"
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                value={userInput}
                ref={inputRef}
            />
            <div
                className={`form_autocomplete_items${
                    isChange && !hasClickOption ? ' is-change' : ''
                }`}
            >
                {showSuggestions ? (
                    suggestionsList
                ) : (
                    <span className="form_autocomplete_text">
                        <Translation value="hub.subscription.autocomplete.feedback" />
                    </span>
                )}
            </div>
        </div>
    )

    if (form) {
        return form.getFieldDecorator(name, options)(input)
    } else {
        return input
    }
}

FormAutocomplete.propTypes = {
    asyncData: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            value: PropTypes.string.isRequired,
        })
    ),
    form: formShape,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    placeholder: PropTypes.string,
}

export default FormAutocomplete
