import React from 'react'

import { View } from '../../../components/View'

import { SurveyDetailsContainer } from '../components/Survey'

const SurveyDetailsView = ({ location }) => (
    <View option={['survey']}>
        <SurveyDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default SurveyDetailsView
