import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { fetchWorkBySlug } from '../../actions/work/workActions'
import { fetchCategories } from '../../actions/work/categoryActions'

import { WorkDetails /*WorkSubscriptionForm */ } from './'

const WorkDetailsContainer = ({ slug }) => {
    // prepare function to retrieve work by slug
    const useFetchWork = useCallback(() => {
        return fetchWorkBySlug(slug)
    }, [slug])

    const work = useFetchItems(useFetchWork)

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const categories = useFetchItems(useFetchCategories)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, work, categories, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {work.requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <WorkDetails.Placeholder />
                ) : (
                    <WorkDetails work={work.items} categories={categories.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default WorkDetailsContainer
