import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/surveys/users`

/**
 * Retrieve the authenticated user's answered surveys.
 */
// export const FETCH_SURVEY_USER_SELF_REQUEST = 'FETCH_SURVEY_USER_SELF_REQUEST'
// export const FETCH_SURVEY_USER_SELF_SUCCESS = 'FETCH_SURVEY_USER_SELF_SUCCESS'
// export const FETCH_SURVEY_USER_SELF_FAILURE = 'FETCH_SURVEY_USER_SELF_FAILURE'
export const fetchSurveyUserSelf = (config = {}) => {
    return apiClient.get(`${baseUrl}/self`, config)
}
