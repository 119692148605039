import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Translation } from '../Translation'

import { Wrapper } from '../../components/Wrapper'

import './_contentBlock.scss'
import './_contentNav.scss'

const ContentNav = ({ blocks }) => {
    const handleClick = useCallback(event => {
        event.preventDefault()

        const el = event.currentTarget
        const href = el.getAttribute('href')

        document.getElementById(href.replace('#', '')).scrollIntoView({ behavior: 'smooth' })
    }, [])

    if (_.isEmpty(blocks) || blocks.length === 1) {
        return null
    }

    return (
        <div className="contentBlock">
            <Wrapper option={['medium']}>
                <ul className="contentNav">
                    {blocks.map((block, index) => {
                        if (block.title) {
                            return (
                                <li key={index} className="contentNav_item">
                                    <a
                                        href={`#${block.id}`}
                                        className="contentNav_item_link"
                                        onClick={handleClick}
                                    >
                                        <Translation value={block.title} />
                                    </a>
                                </li>
                            )
                        }
                        return null
                    })}
                </ul>
            </Wrapper>
        </div>
    )
}

ContentNav.propTypes = {
    blocks: PropTypes.array,
}

export default ContentNav
