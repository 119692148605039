import moment from 'moment'

import { LOCALSTORAGE_ANONYMOUS_VOTES } from '../config'

import {
    FETCH_IDEA_VOTE_SELF_REQUEST,
    FETCH_IDEA_VOTE_SELF_SUCCESS,
    FETCH_IDEA_VOTE_SELF_FAILURE,
    CREATE_IDEA_VOTE_REQUEST,
    CREATE_IDEA_VOTE_SUCCESS,
    CREATE_IDEA_VOTE_FAILURE,
    UPDATE_IDEA_VOTE_REQUEST,
    UPDATE_IDEA_VOTE_SUCCESS,
    UPDATE_IDEA_VOTE_FAILURE,
    UPDATE_IDEA_VOTE_PENDING,
    FETCH_IDEA_VOTE_ANONYMOUS_REQUEST,
} from '../actions/voteActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function votes(state = initialState, action) {
    switch (action.type) {
        /**
         * Retrieve the authenticated user's idea's votes.
         */
        case FETCH_IDEA_VOTE_SELF_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_IDEA_VOTE_SELF_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_IDEA_VOTE_SELF_FAILURE:
            return {
                entities: [],
            }

        /**
         * Create a new ideas's vote
         */
        case CREATE_IDEA_VOTE_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case CREATE_IDEA_VOTE_SUCCESS:
            // update local storage for non-authenticated users
            if (!action.result.data.user) {
                const anonymousVotes =
                    JSON.parse(localStorage.getItem(LOCALSTORAGE_ANONYMOUS_VOTES)) || []

                localStorage.setItem(
                    LOCALSTORAGE_ANONYMOUS_VOTES,
                    JSON.stringify([...anonymousVotes, action.result.data])
                )
            }

            // update entities by append vote to existing vote entities
            return {
                ...state,
                entities: [...state.entities, action.result.data],
            }
        case CREATE_IDEA_VOTE_FAILURE:
            return {
                ...state,
            }

        /**
         * Update the existing idea's vote
         */
        case UPDATE_IDEA_VOTE_REQUEST:
            return {
                ...state,
            }
        case UPDATE_IDEA_VOTE_SUCCESS:
            // update entities by replacing vote object in existing vote entities
            const votes = state.entities.map(entity => {
                if (entity.id === action.result.data.id) {
                    return action.result.data
                }
                return entity
            })

            // update local storage for non-authenticated users
            if (!action.result.data.user) {
                localStorage.setItem(LOCALSTORAGE_ANONYMOUS_VOTES, JSON.stringify(votes))
            }

            return {
                ...state,
                entities: votes,
            }
        case UPDATE_IDEA_VOTE_FAILURE:
        case UPDATE_IDEA_VOTE_PENDING:
            return {
                ...state,
            }

        /**
         * Retrieve the anonymous user's idea's votes from localStorage.
         */
        case FETCH_IDEA_VOTE_ANONYMOUS_REQUEST:
            return {
                ...state,
                entities: JSON.parse(localStorage.getItem(LOCALSTORAGE_ANONYMOUS_VOTES)) || [],
            }

        default:
            return state
    }
}
