import React from 'react'

import queryString from 'query-string'

import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'
import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { translation } from '../../../utils'

import {
    SurveyFilterPicker,
    // SurveyCategoryListContainer,
    SurveyListContainer,
} from '../components/Survey'
import { ONGOING_CONTENT, FINISHED_CONTENT } from '../../../config'

const SurveyListView = ({ location, serviceData, serviceCategoryData }) => {
    // Retrieve GET parameter in order to set the survey's filter
    const params = queryString.parse(location.search)
    let type = ONGOING_CONTENT
    if (params.status && params.status === translation(FINISHED_CONTENT)) {
        type = translation(FINISHED_CONTENT)
    }

    return (
        <View>
            <ServiceMetatags data={serviceData} />
            <HeaderPage
                heading={serviceData.name}
                breadcrumb={serviceCategoryData.name}
                icon={serviceData.icon}
                intro={serviceData.introBox}
            />
            <Wrapper>
                {/*<Card option={['paddingLarge', 'margin']}>
                    <Wrapper option={['small', 'noPaddingSmall']}>
                        <CardHeader
                            option={['noMargin']}
                            heading="survey.listing.info.heading"
                            description="survey.listing.info.description"
                        />
                    </Wrapper>
                </Card>*/}
                <SurveyFilterPicker baseUrl={serviceData.slug} selected={type} />
                {/* <SurveyCategoryListContainer /> */}
                <SurveyListContainer filter={type} url={serviceData.slug} />
            </Wrapper>
        </View>
    )
}

export default SurveyListView
