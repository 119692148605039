import moment from 'moment'
import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/addresses`

/**
 * Retrieve addresses.
 */
export const fetchAddresses = (address, config = {}) => {
    const locale = moment.locale()
    return apiClient.get(`${baseUrl}?searchQuery=${address}&lang=${locale}`, config)
}
