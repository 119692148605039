import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import _ from 'lodash'

import { Button } from '../Button'
import { Translation } from '../Translation'

import './_formFile.scss'

const FormFile = ({
    id,
    name,
    multiple = false,
    acceptedMimetypes = [],
    maxFilesize = 1000000,
    setFiles,
    setErrors,
}) => {
    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            let errorsRejectedFiles = rejectedFiles.map(({ name, size }) => {
                const errorType =
                    size > maxFilesize ? 'form.file.errors.fileSize' : 'form.file.errors.fileType'
                return {
                    type: errorType,
                    value: name,
                }
            })

            const totalFilesSizes = _.reduce(
                acceptedFiles,
                (totalSize, file) => file.size + totalSize,
                0
            )

            if (totalFilesSizes > maxFilesize) {
                errorsRejectedFiles = [
                    { type: 'form.file.errors.totalMaxFileSize' },
                    ...errorsRejectedFiles,
                ]
            } else {
                setFiles(acceptedFiles)
            }
            setErrors(errorsRejectedFiles)
        },
        [maxFilesize, setErrors, setFiles]
    )

    const { getRootProps, getInputProps, acceptedFiles, isDragActive, inputRef } = useDropzone({
        // filter by Mimetypes
        accept: acceptedMimetypes.map(fileType => fileType).join(', '),
        maxSize: maxFilesize,
        multiple: multiple,
        onDrop,
    })

    let text
    if (acceptedFiles.length) {
        // filter by mimeTypes
        //NEED FEEDBACK
        /*
        _.remove(acceptedFiles, file =>
            acceptedMimetypes.indexOf(file.type) === -1 ? true : false
        )
        */

        // total file size
        //NEED FEEDBACK
        const totalFilesSizes = _.reduce(
            acceptedFiles,
            (totalSize, file) => file.size + totalSize,
            0
        )
        if (totalFilesSizes > maxFilesize) {
            // acceptedFiles = []
        }

        const files = acceptedFiles.map(({ name }) => name).join(', ')

        isDragActive
            ? (text = (
                  <p>
                      <Translation value="form.file.replace" />
                  </p>
              ))
            : (text = <p>{files}</p>)
    } else {
        isDragActive
            ? (text = (
                  <p>
                      <Translation value="form.file.drag" />
                  </p>
              ))
            : (text = (
                  <p>
                      <Translation value="form.file.default" />
                  </p>
              ))
    }

    const removeAll = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        inputRef.current.value = ''

        setFiles([])
    }

    return (
        <div
            className={'formFile form_input -center' + (acceptedFiles.length ? ' -has-files' : '')}
        >
            <div className="formFile_dots u-buttonHover" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="formFile_button">
                    <Button>
                        <Translation value="form.file.button" />
                    </Button>
                </div>
                {text}
            </div>

            {acceptedFiles.length > 0 && (
                <button
                    className={'button -small -red formFile_remove'}
                    onClick={removeAll}
                    type="button"
                >
                    <Translation value="form.file.remove" />
                </button>
            )}
        </div>
    )
}

export default FormFile
