import React from 'react'
import ContentLoader from 'react-content-loader'

import { ProjectNewsHeader } from '.'
import { ContentBlocks } from '../../../../components/ContentBlock'

const ProjectNewsDetails = ({ projectNews }) => {
    return (
        <React.Fragment>
            <ProjectNewsHeader projectNews={projectNews} />
            <ContentBlocks blocks={projectNews.attachments} showNav={false} />
        </React.Fragment>
    )
}

ProjectNewsDetails.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    ></ContentLoader>
)

export default ProjectNewsDetails
