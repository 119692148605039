import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'

import { StatisticAnswer } from '.'

const StatisticQuestion = ({ data: { id, answers, type } }) => {
    const totalAnswers = _.reduce(answers, (sum, statAnswer) => statAnswer.value + sum, 0)

    const statAnswers = answers.map(answer => {
        const percentage = totalAnswers !== 0 ? _.round((answer.value * 100) / totalAnswers) : 0
        return <StatisticAnswer key={answer.id} statistic={{ ...answer, percentage }} />
    })

    return (
        <div className="question">
            <Wrapper option={['small']}>
                <TransitionGroup>
                    <CSSTransition classNames="has-transition" key={id} timeout={300} appear exit>
                        <div className="form_group -anim">
                            {type === 'textarea' ? (
                                <div className="statisticAnswers">
                                    <div className="statisticAnswers_item -center">
                                        <Translation value="survey.details.results.textarea" />
                                    </div>
                                </div>
                            ) : (
                                <div className="statisticAnswers">{statAnswers}</div>
                            )}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Wrapper>
        </div>
    )
}

StatisticQuestion.propTypes = {
    data: PropTypes.object.isRequired,
}

export default StatisticQuestion
