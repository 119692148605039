import React from 'react'

import { View } from '../../../components/View'

import { ProjectNewsDetailsContainer } from '../components/ProjectNews'

const ProjectNewsDetailsView = ({ location }) => (
    <View>
        <ProjectNewsDetailsContainer slug={location.pathname.substr(1).replace(/\/$/, '')} />
    </View>
)

export default ProjectNewsDetailsView
