import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getActiveLanguage, withLocalize } from 'react-localize-redux'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'
import { createForm, formShape } from 'rc-form'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { openSidebar } from '../../../../actions/sidebarActions'

import { FINISHED_CONTENT } from '../../../../config'
import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'
import { Section } from '../../../../components/Section'
import { Spinner } from '../../../../components/Spinner'
import { Wrapper } from '../../../../components/Wrapper'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { resolveContentStatus, translation } from '../../../../utils'
import { ANONYMOUS_ROLE, USER_ROLE } from '../../config'
import { hasUserAlreadyParticipated } from '../../utils'

import { FlashSurveyChoiceManager } from '.'
import { StatisticQuestion } from '../Statistic'

import avatar from '../../../../assets/images/avatar.svg'

import './_flashSurvey.scss'

const FlashSurvey = ({
    flashsurvey,
    form,
    userSurveys,
    lang,
    user,
    createFlashSurveyAnswer,
    openSidebar,
    offset,
    refreshData,
}) => {
    const hasAlreadyParticipated = hasUserAlreadyParticipated(userSurveys, flashsurvey.id)
    const status = resolveContentStatus(flashsurvey.startDate, flashsurvey.endDate)
    const section = useRef(null)

    const [showStats, setShowStats] = useState(
        hasAlreadyParticipated || status === translation(FINISHED_CONTENT) ? true : false
    )
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setShowStats(
            hasAlreadyParticipated || status === translation(FINISHED_CONTENT) ? true : false
        )
        return () => setShowStats(false)
    }, [hasAlreadyParticipated, status])

    const submitByRole = role => {
        form.validateFields(async (formErrors, currentFormValues) => {
            if (formErrors) {
                return false
            }

            const respondAsAnonymous = role === ANONYMOUS_ROLE ? true : false
            let data = {
                lang: lang,
                survey: flashsurvey.id,
            }

            if (flashsurvey.type === 'radio' || flashsurvey.type === 'checkbox') {
                data.choices = currentFormValues[flashsurvey.id]
            } else {
                data.content = currentFormValues[flashsurvey.id]
            }

            if (!respondAsAnonymous && user) {
                data.user = user.id
            }

            // add spinner
            setIsLoading(true)

            // create answer
            await createFlashSurveyAnswer(data)

            // refresh flash survey data for statistics
            await refreshData(flashsurvey.id)

            // remove spinner and show stats
            setShowStats(true)
            setIsLoading(false)

            window.scrollTo({ top: section.current.offsetTop, behavior: 'smooth' })
        })
    }

    const isDisabled =
        !form.getFieldValue(flashsurvey.id) || _.isEmpty(form.getFieldValue(flashsurvey.id))
            ? true
            : false

    return (
        <Section option={['green', 'paddingLarge', offset ? 'offset' : '']}>
            <div className="flashSurvey" ref={section}>
                <div className="question -flash">
                    <Wrapper option={['small']}>
                        <p className="question_title">
                            <Translation value="survey.details.flash.title" />
                        </p>
                        <div className="question_bubble">
                            <div className="question_avatar">
                                <img src={avatar} alt="" />
                            </div>
                            <Translation value={flashsurvey.content} isHtml />
                        </div>
                    </Wrapper>
                </div>
                <TransitionGroup>
                    <CSSTransition key={showStats} classNames="has-transition" timeout={300} appear>
                        <div className="form_group -anim">
                            {showStats ? (
                                <StatisticQuestion data={flashsurvey.statistics} kind="flash" />
                            ) : isLoading ? (
                                <Spinner />
                            ) : (
                                <div>
                                    <FlashSurveyChoiceManager question={flashsurvey} form={form} />
                                    <div className="question_buttons -flash">
                                        <Layout option={['gutterTiny', 'gutterBottom']}>
                                            <LayoutItem option={['auto']}>
                                                {!isDisabled && user ? (
                                                    <Button onClick={() => submitByRole(USER_ROLE)}>
                                                        <Translation
                                                            value="survey.details.respond.as_user.title"
                                                            data={{ user: user.firstname }}
                                                        />
                                                    </Button>
                                                ) : !isDisabled && !user ? (
                                                    <Button onClick={openSidebar}>
                                                        <Translation value="survey.details.login.title" />
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </LayoutItem>
                                            <LayoutItem option={['auto']}>
                                                {!isDisabled && flashsurvey.allowAnonymous && (
                                                    <Button
                                                        onClick={() => submitByRole(ANONYMOUS_ROLE)}
                                                    >
                                                        <Translation value="survey.details.respond.anonymously.title" />
                                                    </Button>
                                                )}
                                            </LayoutItem>
                                        </Layout>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </Section>
    )
}

FlashSurvey.propTypes = {
    flashsurvey: PropTypes.shape({
        choices: PropTypes.array.isRequired,
        view: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        statistics: PropTypes.object,
    }).isRequired,
    form: formShape,
    userSurveys: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    createFlashSurveyAnswer: PropTypes.func.isRequired,
    openSidebar: PropTypes.func,
}

FlashSurvey.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default withLocalize(createForm()(connect(mapStateToProps, mapDispatchToProps)(FlashSurvey)))
