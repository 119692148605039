import React from 'react'
import { Helmet } from 'react-helmet-async'

import { translation } from '../../../utils'
import { HeaderPage } from '../../../components/HeaderPage'
import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'
import { SubscriptionFormContainer } from '../components/SubscriptionForm'

const HubSubscriptionView = () => (
    <View>
        <Helmet>
            <title itemProp="name">{translation('hub.subscription.title')}</title>
            <meta name="description" content={translation('hub.subscription.info.description')} />
        </Helmet>
        <HeaderPage
            heading="hub.subscription.title"
            breadcrumb="app.espace_citoyen.title"
            icon="subscribe"
        />
        <Wrapper>
            <SubscriptionFormContainer />
        </Wrapper>
    </View>
)

export default HubSubscriptionView
