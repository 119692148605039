import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Card } from '../../../../components/Card'
import { Icon } from '../../../../components/Icon'
import { Heading } from '../../../../components/Heading'
import { Button } from '../../../../components/Button'
import { isTranslationObject, resolveServiceLink } from '../../../../utils'

import './_serviceCard.scss'

const ServiceCard = props => {
    const { icon, name, description, actionLabel } = props
    const { label, url, external } = resolveServiceLink(props, actionLabel)

    return (
        <article className="serviceCard">
            <Card href={url} external={external}>
                <div>
                    <header className="serviceCard_header">
                        {icon && (
                            <div className="serviceCard_icon">
                                <Icon name={icon} />
                            </div>
                        )}
                        <Heading option={['h3']}>
                            <Translation value={name} />
                        </Heading>
                    </header>
                    {description && (
                        <div className="serviceCard_description">
                            <Translation value={description} isHtml />
                        </div>
                    )}
                </div>
                <div className="serviceCard_button">
                    <Button
                        option={[external ? 'iconRight' : 'blue']}
                        icon={external ? 'external' : null}
                    >
                        {label}
                    </Button>
                </div>
            </Card>
        </article>
    )
}

ServiceCard.propTypes = {
    icon: PropTypes.string,
    name: isTranslationObject,
    description: isTranslationObject,
    actionLabel: isTranslationObject,
}

export default ServiceCard
