import React from 'react'

import { MoodRadio, MoodRadioItem } from '../../../../components/MoodRadio'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { FormLabel } from '../../../../components/Form'

const QuestionChoiceRadioMood = ({
    question: { id, choices, required },
    form,
    form: { getFieldValue, getFieldError, setFieldsInitialValue, isFieldTouched },
    formValues = {},
    color,
}) => {
    const radioItem = choices.map((choice, i) => (
        <MoodRadioItem
            id={choice.id}
            name={id}
            key={i}
            i={i}
            form={form}
            status={[getFieldError(id) && 'error']}
            value={choice.id}
            checked={formValues[id] === choice.id && 'checked'}
            options={{
                preserve: getFieldValue(id) ? true : false,
                validate: [
                    {
                        trigger: 'onChange',
                        rules: [
                            {
                                required: required,
                            },
                        ],
                    },
                ],
            }}
        >
            <Translation value={choice.content} isHtml />
        </MoodRadioItem>
    ))

    return (
        <Wrapper option={['small']}>
            <FormLabel>
                <Translation value="survey.details.answer.mood.title" />
            </FormLabel>
            <MoodRadio option={[color]}>{radioItem}</MoodRadio>
        </Wrapper>
    )
}

export default QuestionChoiceRadioMood
