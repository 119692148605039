import moment from 'moment'
import React from 'react'

import { Translation } from '../Translation'

import './_copyright.scss'

const Copyright = () => (
    <div className="copyright">
        <Translation value="app.agora.title" /> — <Translation value="app.espace_citoyen.title" />{' '}
        <br />© {moment().format('YYYY')}
    </div>
)
export default Copyright
