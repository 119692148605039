import moment from 'moment'

import {
    FETCH_CONTACT_SETTINGS_REQUEST,
    FETCH_CONTACT_SETTINGS_SUCCESS,
    FETCH_CONTACT_SETTINGS_FAILURE,
} from '../actions/settingActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function contactSettings(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTACT_SETTINGS_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_CONTACT_SETTINGS_SUCCESS:
            return {
                entities: action.result.data,
            }
        case FETCH_CONTACT_SETTINGS_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
