import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { Translation } from '../../Translation'
import { isTranslationObject, translation, formatDate } from '../../../utils'

import { Icon } from '../../Icon'

const ContentBlockMilestoneStep = ({
    title,
    description,
    image,
    achievedDate,
    targetDate,
    targetDateLabel,
    isLastCompleted,
}) => {
    const isStepAchieved =
        achievedDate && Date.now() > new Date(moment(achievedDate, 'YYYY-MM-DD HH:mm').toDate())
            ? true
            : false

    return (
        <div
            className={
                `contentMilestone_item` +
                (isStepAchieved ? ' -done' : '') +
                (isLastCompleted ? ' -last' : '')
            }
        >
            <div className="contentMilestone_container">
                {title && (
                    <h3 className="contentMilestone_heading">
                        <Translation value={title} />
                    </h3>
                )}

                {targetDate ? (
                    <time className="contentMilestone_date" dateTime={targetDate}>
                        {!_.isEmpty(targetDateLabel) ? (
                            <Translation value={targetDateLabel} />
                        ) : (
                            formatDate(targetDate)
                        )}
                    </time>
                ) : (
                    achievedDate && (
                        <time className="contentMilestone_date" dateTime={achievedDate}>
                            {formatDate(achievedDate)}
                        </time>
                    )
                )}

                {description && (
                    <div>
                        <Translation value={description} />
                    </div>
                )}

                {image && (
                    <div
                        className="contentMilestone_image"
                        style={{ backgroundImage: `url('${translation(image)}')` }}
                    ></div>
                )}

                <div className="contentMilestone_dot">
                    {isStepAchieved && (
                        <div className="contentMilestone_dot_check">
                            <Icon name="check" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

ContentBlockMilestoneStep.propTypes = {
    title: isTranslationObject,
    description: isTranslationObject,
    image: PropTypes.object,
    achievedDate: PropTypes.string,
    targetDate: PropTypes.string,
}

export default ContentBlockMilestoneStep
