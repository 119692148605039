import React from 'react'

import { View } from '../components/View'
import { NotFound } from '../components/NotFound'

const NotFoundView = () => (
    <View>
        <NotFound />
    </View>
)

export default NotFoundView
