import { LOCATION_CHANGE } from 'connected-react-router'

import { OPEN_NAV, CLOSE_NAV, TOGGLE_NAV } from '../actions/navActions'

const initialState = {
    isNavOpen: false,
}

export const navReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_NAV:
            return {
                ...state,
                isNavOpen: true,
            }

        case CLOSE_NAV:
        case LOCATION_CHANGE:
            return {
                ...state,
                isNavOpen: false,
            }

        case TOGGLE_NAV:
            return {
                ...state,
                isNavOpen: !state.isNavOpen,
            }

        default:
            return state
    }
}
