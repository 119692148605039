import { connect } from 'react-redux'

import { fetchSelf, login } from '../../actions/userActions'

import { LoginForm } from '../LoginForm'

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    isHidden: state.hub.auth.user !== null,
})

const mapDispatchToProps = dispatch => ({
    fetchSelf: () => dispatch(fetchSelf()),
    login: (data, config) => dispatch(login(data, config)),
})

const LoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(LoginForm)

export default LoginFormContainer
