import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import ContentLoader from 'react-content-loader'
import { createForm, formShape } from 'rc-form'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'

import { openSidebar } from '../../../../actions/sidebarActions'

import { Button } from '../../../../components/Button'
import { Card } from '../../../../components/Card'
import {
    Form,
    FormSelect,
    // FormError,
    FormInput,
    FormItem,
    FormLabel,
} from '../../../../components/Form'
import { Section } from '../../../../components/Section'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { Heading } from '../../../../components/Heading'
import { Link } from '../../../../components/Link'

import { createIdea } from '../../actions/ideaActions'
import { findServiceByType, translation } from '../../../../utils'

import commentImage from '../../../../assets/images/idea_comment.png'

const IdeaSubmitForm = ({
    isAuthenticated,
    user,
    lang,
    openSidebar,
    categories,
    translate,
    form,
    faqService,
    form: { getFieldError, getFieldValue, validateFields },
}) => {
    const CATEGORY_FIELD = 'category'
    const SUBJECT_FIELD = 'subject'
    const DESCRIPTION_FIELD = 'description'

    const [respondAs, setRespondAs] = useState(isAuthenticated ? user.id : null)
    const [isFinished, setIsFinished] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setRespondAs(isAuthenticated ? user.id : null)
    }, [isAuthenticated, user])

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (isLoading) {
                return false
            }

            if (formErrors) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                return false
            }

            setIsLoading(true)

            const formData = {
                category: formValues[CATEGORY_FIELD],
                title: formValues[SUBJECT_FIELD],
                description: formValues[DESCRIPTION_FIELD],
                user: respondAs,
            }

            await createIdea(formData, { cancelToken: signal.token })
                .catch(err => null)
                .finally(() => setIsLoading(false))

            setIsFinished(true)
        })
    }

    let categoriesInputs = categories.map(category => ({
        value: category.id,
        name: category.name[lang],
    }))

    categoriesInputs = [
        { value: '', name: translate('form.dropdown.default') },
        ...categoriesInputs,
    ]

    if (isFinished) {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        return (
            <Wrapper>
                <Section>
                    <Card utility={['textCenter', 'paddingLarge']}>
                        <Wrapper option={['noPaddingSmall']}>
                            <Heading el="h1" option={['h1']}>
                                <Translation value="idea.details.finished.title" />
                            </Heading>

                            <img
                                className="ideaDetailsCommentForm_finished_image"
                                src={commentImage}
                                alt={<Translation value="idea.details.finished.title" />}
                            />

                            <p className="ideaDetailsCommentForm_finished_description">
                                <Translation value="idea.details.finished.description" />
                            </p>

                            <div className="ideaDetailsCommentForm_finished_button">
                                <Button
                                    option={['large', 'green', 'icon', 'iconLeft']}
                                    icon="arrow-left"
                                    route="home"
                                >
                                    <Translation value={'idea.details.comment.finished.button'} />
                                </Button>
                            </div>
                        </Wrapper>
                    </Card>
                </Section>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Section>
                <Card option={['paddingLarge']}>
                    <Wrapper option={['medium', 'noPaddingSmall']}>
                        <FormItem utility={['textCenter']}>
                            <Heading el="h1" option={['h1']}>
                                <Translation value="idea.suggest.title" />
                            </Heading>
                            <div>
                                <p>
                                    {faqService ? (
                                        <Translation
                                            value="idea.suggest.description"
                                            data={{
                                                faqUrl: faqService.slug && (
                                                    <Link
                                                        href={`/${translation(faqService.slug)}`}
                                                        utility={['baseline']}
                                                    >
                                                        <Translation value="idea.suggest.faq" />
                                                    </Link>
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <Translation value="idea.suggest.placeholders.description" />
                                    )}
                                </p>
                            </div>
                        </FormItem>

                        <FormItem>
                            <div className="ideaDetailsCommentForm_buttons">
                                {!isAuthenticated ? (
                                    <Button
                                        onClick={() => openSidebar()}
                                        className={'ideaDetailsCommentForm_buttons_item'}
                                        option={['white']}
                                    >
                                        <Translation value="idea.details.comment.form.respond.connected.title" />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => setRespondAs(user.id)}
                                        className={`ideaDetailsCommentForm_buttons_item ${respondAs ===
                                            user.id && ' is-selected'}`}
                                        option={['white', 'interactionBlue']}
                                    >
                                        <Translation
                                            value="idea.details.comment.form.respond.as_user.title"
                                            data={{ user: user.firstname }}
                                        />
                                    </Button>
                                )}

                                <Button
                                    onClick={() => setRespondAs(null)}
                                    className={
                                        'ideaDetailsCommentForm_buttons_item' +
                                        (respondAs == null ? ' is-selected' : '')
                                    }
                                    option={['white', 'interactionBlue']}
                                >
                                    <Translation value="idea.details.comment.form.respond.anonymously.title" />
                                </Button>
                            </div>
                        </FormItem>

                        <Form onSubmit={handleSubmit}>
                            <FormItem>
                                <FormLabel inputId={CATEGORY_FIELD}>
                                    <Translation value="idea.suggest.form.category.title" />
                                </FormLabel>
                                <FormSelect
                                    status={[getFieldError(CATEGORY_FIELD) && 'error']}
                                    form={form}
                                    id={CATEGORY_FIELD}
                                    name={CATEGORY_FIELD}
                                    options={{
                                        validateFirst: true,
                                        validate: [
                                            {
                                                trigger: 'onChange',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate(
                                                            'idea.suggest.form.category.errors.required'
                                                        ),
                                                    },
                                                    {
                                                        asyncValidator: (rule, value) => {
                                                            return new Promise(
                                                                (resolve, reject) => {
                                                                    if (!_.isEmpty(value)) resolve()
                                                                    else reject(false)
                                                                }
                                                            )
                                                        },
                                                        message: translate(
                                                            'idea.suggest.form.category.errors.required'
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                    maxLength="100"
                                >
                                    {categoriesInputs.map(categoryInput => (
                                        <option
                                            key={categoryInput.value}
                                            value={categoryInput.value}
                                            name="category"
                                        >
                                            {categoryInput.name}
                                        </option>
                                    ))}
                                </FormSelect>
                                {/*<FormError>{getFieldError(CATEGORY_FIELD)}</FormError>*/}
                            </FormItem>

                            <FormItem>
                                <FormLabel inputId={SUBJECT_FIELD}>
                                    <Translation value="idea.suggest.form.subject.title" />
                                </FormLabel>
                                <FormInput
                                    status={[getFieldError(SUBJECT_FIELD) && 'error']}
                                    form={form}
                                    id={SUBJECT_FIELD}
                                    name={SUBJECT_FIELD}
                                    options={{
                                        initialValue: '',
                                        validate: [
                                            {
                                                trigger: 'onBlur',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate(
                                                            'idea.suggest.form.subject.errors.required'
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                    type="text"
                                />
                                {/*<FormError>{getFieldError(SUBJECT_FIELD)}</FormError>*/}
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId={DESCRIPTION_FIELD}>
                                    <Translation value="idea.suggest.form.description.title" />
                                </FormLabel>
                                <FormInput
                                    status={[getFieldError(DESCRIPTION_FIELD) && 'error']}
                                    form={form}
                                    id={DESCRIPTION_FIELD}
                                    name={DESCRIPTION_FIELD}
                                    options={{
                                        initialValue: '',
                                        validate: [
                                            {
                                                trigger: 'onBlur',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate(
                                                            'idea.suggest.form.description.errors.required'
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                    type="textarea"
                                    maxLength="300"
                                />
                                {/*<FormError>{getFieldError(DESCRIPTION_FIELD)}</FormError>*/}
                            </FormItem>
                            <FormItem utility={['textCenter']}>
                                <Button disabled={isLoading} option={['large']} type="submit">
                                    <Translation value="idea.suggest.form.submit.title" />
                                </Button>
                            </FormItem>
                        </Form>
                    </Wrapper>
                </Card>
            </Section>
        </Wrapper>
    )
}

IdeaSubmitForm.Placeholder = () => <ContentLoader></ContentLoader>

IdeaSubmitForm.protoTypes = {
    categories: PropTypes.array.isRequired,
    form: formShape,
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    lang: getActiveLanguage(state.localize).code,
    faqService: findServiceByType(state.hub.services.entities, 'city/content/service/questions'),
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(createForm()(IdeaSubmitForm)))
