import _ from 'lodash'

export function formatFormNotifications(items) {
    if (_.isArray(items)) {
        return items
    } else {
        if (typeof items.message === 'string') {
            return [items]
        } else {
            return []
        }
    }
}

export function verifySameField(value, field) {
    if (!field) {
        return false
    }
    return field !== value ? false : true
}
