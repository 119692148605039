import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Wrapper } from '../../../../components/Wrapper'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'
import { Section } from '../../../../components/Section'
// import { CardFeedback } from '../../../../components/CardFeedback'
// import { Button } from '../../../../components/Button'
import { translation } from '../../../../utils'

import { WorkCard } from '.'
import { FlashSurveyContainer } from '../../../survey/components/FlashSurvey'
import { FINISHED_CONTENT } from '../../../../config'

const WorkList = ({
    works,
    categories,
    filter,
    flashsurvey = false,
    url,
    viewFilter = 'ongoing',
}) => {
    // First element large if odd count
    let renderedWorks
    if (works.length % 2) {
        const firstWorkCategory = _.find(categories, category => category.id === works[0].category)
        const renderedFirstWork = (
            <LayoutItem key={works[0].id}>
                <WorkCard work={works[0]} category={firstWorkCategory} isLarge key={works[0].id} />
            </LayoutItem>
        )
        renderedWorks = [
            renderedFirstWork,
            ..._.tail(works).map((work, i) => {
                const category = _.find(categories, category => category.id === work.category)

                return (
                    <LayoutItem utility={['1/2@from-small']} key={i}>
                        <WorkCard work={work} category={category} key={work.id} />
                    </LayoutItem>
                )
            }),
        ]
    } else {
        renderedWorks = works.map((work, i) => {
            const category = _.find(categories, category => category.id === work.category)

            return (
                <LayoutItem utility={['1/2@from-small']} key={i}>
                    <WorkCard work={work} category={category} key={work.id} />
                </LayoutItem>
            )
        })
    }

    if (filter === translation(FINISHED_CONTENT)) {
        viewFilter = 'finished'
    }

    return (
        <div>
            <Wrapper>
                <Heading option={['h3']} el="h2">
                    <Translation value={'work.listing.' + viewFilter + '.title'} />
                    <sup>{works.length}</sup>
                </Heading>
                <Section>
                    <Layout option={['flex', 'stretch', 'gutterSmall', 'gutterBottom']}>
                        {renderedWorks}
                    </Layout>
                </Section>
            </Wrapper>
            {flashsurvey && <FlashSurveyContainer flashsurvey={flashsurvey} />}
        </div>
    )
}

WorkList.propTypes = {
    works: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

WorkList.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="28" y="19" rx="5" ry="5" width="219" height="123" />
        <rect x="288" y="35" rx="0" ry="0" width="132" height="5" />
        <rect x="288" y="57" rx="0" ry="0" width="141" height="14" />
        <rect x="288" y="106" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="79" rx="0" ry="0" width="141" height="14" />
        <rect x="30" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="50" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="50" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="50" y="318" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="308" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="308" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="308" y="318" rx="20" ry="20" width="122" height="33" />
    </ContentLoader>
)

export default WorkList
