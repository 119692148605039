import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import { Translation } from '../Translation'

import sprite from '../../assets/images/sprite.svg'
import './_icon.scss'

const Icon = ({ name, title = null, ...classProps }) => {
    classProps.className = `-${name}`
    return (
        <div className={renderClasses('icon', classProps)}>
            <svg role="img">
                {title !== null && (
                    <title>
                        <Translation value={title} />
                    </title>
                )}
                <use xlinkHref={`${sprite}#${name}`} />
            </svg>
        </div>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
}

export default Icon
