import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { Translation } from '../../../../components/Translation'
import { useDev, logErrors } from '../../../../utils'

import { fetchCategories } from '../../actions/categoryActions'

import { AlertSlider } from './'

const AlertSliderContainer = props => {
    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const { isLoading, error, items } = useFetchItems(useFetchCategories)
    useDev(logErrors(error))
    const { showHeader, ...allAlertsBtn } = props.options

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <AlertSlider.Placeholder />
                ) : (
                    <AlertSlider
                        name={<Translation value={props.name} />}
                        showHeader={showHeader}
                        alerts={props.alerts}
                        categories={items}
                        seeAllButton={allAlertsBtn}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default AlertSliderContainer
