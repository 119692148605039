import React from 'react'
import PropTypes from 'prop-types'
import { isTranslationObject } from '../../../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { Section, SectionHeader } from '../../../../components/Section'
import { Heading } from '../../../../components/Heading'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Translation } from '../../../../components/Translation'
import { ServiceCard } from '.'

const ServiceList = ({ name, services }) => {
    const renderedServices = services.map((service, i) => {
        return (
            <LayoutItem utility={['1/2@from-small']} key={i}>
                <ServiceCard key={service.id} {...service} />
            </LayoutItem>
        )
    })

    return (
        <Wrapper>
            <Section>
                <SectionHeader>
                    <Heading option={['h3']} el="h2">
                        <Translation value={name} />
                    </Heading>
                </SectionHeader>
                <Layout option={['flex', 'stretch', 'gutterSmall', 'gutterBottom']}>
                    {renderedServices}
                </Layout>
            </Section>
        </Wrapper>
    )
}

ServiceList.propTypes = {
    name: isTranslationObject,
    services: PropTypes.array.isRequired,
}

export default ServiceList
