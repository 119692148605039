import { apiClient } from '../../../../actions'

import { inquiryBaseUrl } from '../inquiryActions'

const baseUrl = `${inquiryBaseUrl}/files`

/**
 * Retrieve files settings.
 */
export const fetchFileSettings = (config = {}) => {
    return apiClient.get(`${baseUrl}/settings`, config)
}

/**
 * Create files.
 */
export const createFiles = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}`, formData, config)
}
