import React from 'react'
import PropTypes from 'prop-types'
import { isTranslationObject } from '../../../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { Section } from '../../../../components/Section'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { Card, CardHeader } from '../../../../components/Card'

const RegisterPanel = ({ options: { title, description, btnLabel } }) => {
    return (
        <Section option={['green', 'paddingLarge', 'offset']}>
            <Wrapper>
                <Card option={['paddingLarge']} utility={['textCenter']}>
                    <Wrapper option={['small']}>
                        <CardHeader
                            option={['smallMargin']}
                            heading={title}
                            description={description}
                        />
                        <Button
                            option={['large', 'green', 'iconLeft']}
                            icon="check"
                            route="hub-subscription"
                        >
                            <Translation value={btnLabel} />
                        </Button>
                    </Wrapper>
                </Card>
            </Wrapper>
        </Section>
    )
}

RegisterPanel.propTypes = {
    name: isTranslationObject,
    options: PropTypes.shape({
        title: isTranslationObject,
        description: isTranslationObject,
        btnLabel: isTranslationObject,
    }),
}

export default RegisterPanel
