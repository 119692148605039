import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'
import { getStore } from '../../../store'

export const retrieveRouteFromResult = path => {
    const surveyResultDetailsRoute = _.find(
        getStore().getState().routes,
        route => route.id === 'survey-result-details'
    )
    if (_.isUndefined(surveyResultDetailsRoute)) {
        return null
    }
    const { code: lang } = getActiveLanguage(getStore().getState().localize)

    // retrieve last string from url
    const resultString = surveyResultDetailsRoute.path[lang].split('/').pop()
    return path.replace(`/${resultString}`, '')
}
