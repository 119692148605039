import { API_URL } from '../config'

const baseUrl = `${API_URL}/services/categories`

/**
 * Retrieve a list of services.
 */
export const FETCH_SERVICE_CATEGORY_LIST_REQUEST = 'FETCH_SERVICE_CATEGORY_LIST_REQUEST'
export const FETCH_SERVICE_CATEGORY_LIST_SUCCESS = 'FETCH_SERVICE_CATEGORY_LIST_SUCCESS'
export const FETCH_SERVICE_CATEGORY_LIST_FAILURE = 'FETCH_SERVICE_CATEGORY_LIST_FAILURE'
export const FETCH_SERVICE_CATEGORY_LIST_PENDING = 'FETCH_SERVICE_CATEGORY_LIST_PENDING'
export const fetchServiceCategories = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_SERVICE_CATEGORY_LIST_REQUEST,
            FETCH_SERVICE_CATEGORY_LIST_SUCCESS,
            FETCH_SERVICE_CATEGORY_LIST_FAILURE,
            FETCH_SERVICE_CATEGORY_LIST_PENDING,
        ],
    }
}
