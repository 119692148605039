import { apiClient } from '../../../actions'

import { API_URL } from '../config'

export const inquiryBaseUrl = `${API_URL}/inquiries`

/**
 * Create inquiry.
 */
export const createInquiry = (formData = {}, config = {}) => {
    return apiClient.post(`${inquiryBaseUrl}`, formData, config)
}
