import { combineReducers } from 'redux'

import * as mappings from './config/mappings'
import { contactSettings } from './reducers/settings'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'

export default {
    mappings,
    reducer: combineReducers({
        settings: contactSettings,
    }),
    translations: {
        en: enTranslations,
        fr: frTranslations,
    },
}
