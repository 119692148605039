import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_content.scss'

const Content = ({ children, html = false, ...classProps }) => {
    if (html) {
        return (
            <div
                className={renderClasses('content', classProps)}
                dangerouslySetInnerHTML={{ __html: html }}
            ></div>
        )
    } else {
        return <div className={renderClasses('content', classProps)}>{children}</div>
    }
}

Content.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
}

export default Content
