import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../Translation'
import { isTranslationObject } from '../../utils'

import { Wrapper } from '../Wrapper'

import './_contentQuote.scss'
import './_contentBlock.scss'

const ContentBlockQuote = ({ quote, author, authorPosition, id }) => (
    <div className={`contentBlock`} id={id}>
        <Wrapper option={['medium']}>
            <blockquote className="contentQuote">
                {quote && <Translation value={quote} />}

                {author && (
                    <footer className="contentQuote_footer">
                        <span className="contentQuote_footer_author">
                            <Translation value={author} />
                        </span>
                        {authorPosition && (
                            <span className="contentQuote_footer_position">
                                <Translation value={authorPosition} />
                            </span>
                        )}
                    </footer>
                )}
            </blockquote>
        </Wrapper>
    </div>
)

ContentBlockQuote.propTypes = {
    quote: isTranslationObject,
    author: isTranslationObject,
    authorPosition: isTranslationObject,
    id: PropTypes.string,
}

export default ContentBlockQuote
