import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'
import { Card } from '../../../../components/Card'
import { CardLabel } from '../../../../components/Card'
import { Heading } from '../../../../components/Heading'
import { localizePath } from '../../../../utils'

import { IdeaVote } from './'

import './_ideaCard.scss'

const IdeaCard = ({ idea, category, selectedCategory = false }) => {
    if (selectedCategory !== 'all' && selectedCategory !== idea.category) {
        return null
    }

    return (
        <Card option={['marginSmall']}>
            <div className="ideaCard">
                <div className="ideaCard_content">
                    <CardLabel utility={['blue']}>{category.name.fr}</CardLabel>

                    <Heading el="h3" option={['h3']}>
                        {idea.title}
                    </Heading>

                    <div className="ideaCard_button">
                        <Button href={localizePath(idea.slug)}>
                            <Translation value="idea.listing.label" />
                        </Button>
                    </div>
                </div>
            </div>

            <div className="ideaCard_vote">
                <IdeaVote idea={idea} />
            </div>
        </Card>
    )
}

IdeaCard.propTypes = {
    idea: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
}

export default IdeaCard
