import PropTypes from 'prop-types'
import React from 'react'
import Lottie from 'react-lottie'

import { isTranslationObject } from '../../../../utils'
import { Translation } from '../../../../components/Translation'

// import heroImage from '../../../../assets/images/hero.svg'
// import * as animationData from '../../../../assets/lottie/data.json'
import * as animationData from '../../../../assets/lottie/data'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    path: '',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMax meet',
    },
}

const HeroCharacter = ({ options: { character, greeting } }) => (
    <div className="headerHero_image">
        <div className="headerHero_image_message">
            <div className="headerHero_image_bubble">
                <Translation value={greeting} />
            </div>
        </div>
        <div className="headerHero_image_img">
            {/*<img src={heroImage} alt="" />*/}
            <Lottie options={defaultOptions} />
        </div>
    </div>
)

HeroCharacter.propTypes = {
    options: PropTypes.shape({
        greeting: isTranslationObject,
        character: PropTypes.string,
    }).isRequired,
}

export default HeroCharacter
