import React, { useRef, useState, useCallback, useEffect } from 'react'

import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import { withLocalize } from 'react-localize-redux'

import { Button } from '../../../../components/Button'
import { Link } from '../../../../components/Link'
import {
    Form,
    FormError,
    FormInput,
    FormItem,
    FormLabel,
    FormNotifications,
} from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Spinner } from '../../../../components/Spinner'
import { formatFormNotifications } from '../../../../utils/forms'

import { ForgotPasswordForm } from '../ForgotPassword'

const LoginForm = ({
    fetchSelf,
    login,
    form,
    form: { getFieldError, validateFields },
    isAuthenticated = false,
    isHidden = false,
    translate,
}) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isForgotPassword, setIsForgotPassword] = useState(false)

    const signal = axios.CancelToken.source()
    let didCancel = useRef(false)

    useEffect(() => {
        return () => {
            didCancel.current = true
        }
    }, [didCancel])

    const handleSubmit = useCallback(
        event => {
            event.preventDefault()

            if (isAuthenticated) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (formErrors) {
                    return false
                }

                setErrors([])
                setIsLoading(true)

                login(formValues, { cancelToken: signal.token })
                    .then(response => {
                        if (!didCancel.current) {
                            setErrors([])
                        }
                        /** Load the authenticated user's details. */
                        fetchSelf({ cancelToken: signal.token })
                            /** Redirection on success is managed through "redux-auth-wrapper". */
                            .then(() => {
                                if (!didCancel.current) {
                                    setIsLoading(false)
                                }
                            })
                            .catch(() => {
                                console.warn('Something terrible went wrong for this to happen!')
                            })
                    })
                    .catch(error => {
                        setErrors([
                            { message: 'Mauvaise combinaison de courriel et de mot de passe' },
                        ])
                        setIsLoading(false)
                    })
            })
            return false
        },
        [fetchSelf, isAuthenticated, login, signal, validateFields, didCancel]
    )

    if (isLoading) {
        return <Spinner />
    }

    const toggleForgotPasswordView = event => {
        event.preventDefault()
        setIsForgotPassword(!isForgotPassword)
    }

    if (isForgotPassword) {
        return (
            <div>
                <ForgotPasswordForm />
                <hr />
                <div className="u-textCenter">
                    <Button
                        option={['small', 'gray', 'iconLeft']}
                        icon="arrow-left"
                        onClick={toggleForgotPasswordView}
                    >
                        <Translation value="hub.loginForm.back" />
                    </Button>
                </div>
            </div>
        )
    }

    if (isHidden) {
        return null
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors
        ? formatFormNotifications(errors)
        : isAuthenticated
        ? [
              {
                  message: translate('hub.loginForm.isAuthenticated'),
              },
          ]
        : []
    const hasNotifications = !_.isEmpty(notifications)

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                {hasNotifications && (
                    <FormItem>
                        <FormNotifications
                            hasErrors={hasErrors}
                            hasSuccess={isAuthenticated}
                            items={notifications}
                        />
                    </FormItem>
                )}
                <FormItem option={['small']}>
                    <FormLabel option={['large']} inputId="login-email">
                        <Translation value="hub.loginForm.email" />
                    </FormLabel>
                    <FormInput
                        form={form}
                        id="login-email"
                        name="email"
                        options={{
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: translate('hub.loginForm.emailMissing'),
                                },
                            ],
                        }}
                        type="email"
                    />
                    <FormError>{getFieldError('login-email')}</FormError>
                </FormItem>
                <FormItem option={['small']}>
                    <FormLabel option={['large']} inputId="login-password">
                        <Translation value="hub.loginForm.password" />
                    </FormLabel>
                    <FormInput
                        form={form}
                        id="login-password"
                        name="password"
                        options={{
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: translate('hub.loginForm.passwordMissing'),
                                },
                            ],
                        }}
                        type="password"
                    />
                    <FormError>{getFieldError('login-password')}</FormError>
                </FormItem>
                <Link option={['small']} onClick={toggleForgotPasswordView}>
                    <Translation value="hub.forgotPassword.title" />
                </Link>
                <div className="u-textCenter">
                    <FormItem option={['button', 'small']}>
                        <Button type="submit">
                            <Translation value="hub.loginForm.submit" />
                        </Button>
                    </FormItem>
                    <hr />
                    <Button
                        option={['small', 'gray', 'iconRight']}
                        icon="arrow-right"
                        route="hub-subscription"
                    >
                        <Translation value="hub.subscription.subscribe.title" />
                    </Button>
                </div>
            </Form>
        </div>
    )
}

LoginForm.propTypes = {
    isHidden: PropTypes.bool,
    fetchSelf: PropTypes.func.isRequired,
    form: formShape,
    isAuthenticated: PropTypes.bool,
    login: PropTypes.func.isRequired,
}

export default withLocalize(createForm()(LoginForm))
