import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchCiviliaParkingData } from '../../actions/civiliaActions'

import { ParkingCivilia } from './'

const ParkingCiviliaContainer = () => {
    const useFethCiviliaMontcalm = useCallback(() => {
        return fetchCiviliaParkingData('montcalm')
    }, [])

    // Adding {} to avoid overwrite
    const { isLoading, error, items } = useFetchItems(useFethCiviliaMontcalm)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <ParkingCivilia.Placeholder />
                ) : (
                    <ParkingCivilia
                        data={items}
                        // flashsurvey={service.items.flashsurvey}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default ParkingCiviliaContainer
