import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Polyline } from '@react-google-maps/api'

const GoogleMapPolyline = ({
    paths,
    isClickable,
    index,
    selectPolyline,
    isSelectedItem = false,
}) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(isSelectedItem)
    }, [isSelectedItem])

    let options = {
        fillColor: 'blue',
        fillOpacity: 0.2,
        // strokeColor: "blue",
        strokeOpacity: 0.5,
        // strokeWeight: 2,
        clickable: isClickable,
        geodesic: false,
    }

    if (isSelected) {
        options = {
            ...options,
            ...{
                fillColor: 'green',
            },
        }
    }

    const handleClick = polyMouseEvent => {
        if (!isClickable) {
            return null
        }

        setIsSelected(!isSelected)
        selectPolyline(index)
        polyMouseEvent.stop()
    }

    return <Polyline path={paths} onClick={handleClick} options={options} />
}

GoogleMapPolyline.propTypes = {
    paths: PropTypes.array.isRequired,
    isClickable: PropTypes.bool,
    index: PropTypes.number,
    selectPolyline: PropTypes.func,
    isSelectedItem: PropTypes.bool,
}

export default GoogleMapPolyline
