import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/answers`

export const createSurveyAnswer = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}`, formData, config)
}

export const logSurveyAnswer = (formData = {}, config = {}) => {
    return apiClient.post(`${baseUrl}/log`, formData, config)
}
