import React from 'react'
import PropTypes from 'prop-types'
import { isTranslationObject } from '../../../../../utils'

import { Wrapper } from '../../../../../components/Wrapper'
import { Section, SectionHeader } from '../../../../../components/Section'
import { Heading } from '../../../../../components/Heading'
import { Layout, LayoutItem } from '../../../../../components/Layout'
import { Translation } from '../../../../../components/Translation'
import { PageCard } from '.'

const PageList = ({ name, pages }) => {
    const renderedPages = pages.map((page, i) => {
        return (
            <LayoutItem utility={['1/2@from-small']} key={i}>
                <PageCard key={page.id} {...page} />
            </LayoutItem>
        )
    })

    return (
        <Wrapper>
            <Section>
                <SectionHeader>
                    <Heading option={['h3']} el="h2">
                        <Translation value={name} />
                    </Heading>
                </SectionHeader>
                <Layout option={['flex', 'stretch', 'gutterSmall', 'gutterBottom']}>
                    {renderedPages}
                </Layout>
            </Section>
        </Wrapper>
    )
}

PageList.propTypes = {
    name: isTranslationObject,
    pages: PropTypes.array.isRequired,
}

export default PageList
