import { LOCALSTORAGE_ONBOARDING_DISPLAYED } from '../config'

import { FETCH_ONBOARDING_SELF_REQUEST } from '../actions/onboardingActions'

const initialState = false

export function hasViewedOnboarding(state = initialState, action) {
    switch (action.type) {
        /**
         * Retrieve the info from localStorage about the onboarding displayed.
         */
        case FETCH_ONBOARDING_SELF_REQUEST:
            return (
                JSON.parse(localStorage.getItem(LOCALSTORAGE_ONBOARDING_DISPLAYED)) || initialState
            )

        default:
            return state
    }
}
