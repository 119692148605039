import { combineReducers } from 'redux'

import * as mappings from './config/mappings'
import routes from './config/routes.json'
import { votes } from './reducers/votes'

import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'
import * as views from './views'

export default {
    mappings,
    reducer: combineReducers({
        votes,
    }),
    routes,
    translations: {
        en: enTranslations,
        fr: frTranslations,
    },
    views,
}
