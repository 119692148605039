import { apiClient } from '../../../../actions'
import { FINISHED_CONTENT } from '../../../../config'
import { translation } from '../../../../utils'

import { WORK_API_URL } from '../../config'

const baseUrl = `${WORK_API_URL}/works`

/**
 * Retrieve work listing.
 */
export const fetchWorks = (filter, config = {}) => {
    if (filter === 'ongoing') {
        return apiClient.get(`${baseUrl}?status=ongoing`, config)
    }
    if (filter === translation(FINISHED_CONTENT)) {
        return apiClient.get(`${baseUrl}?status=finished`, config)
    }

    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve work details by slug.
 */
export const fetchWorkBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}

/**
 * Retrieve work details.
 */
export const fetchWork = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
