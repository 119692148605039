import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_card.scss'

const CardLabel = ({ children, ...classProps }) => {
    return <p className={renderClasses('card_label', classProps)}>{children}</p>
}

CardLabel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
}

export default CardLabel
