import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    ContentBlockAccordion,
    ContentBlockDocument,
    ContentBlockGallery,
    ContentBlockImage,
    ContentBlockMilestone,
    ContentBlockQuote,
    ContentBlockTable,
    ContentBlockText,
    ContentBlockVideo,
    ContentNav,
} from '.'

const ContentBlocks = ({ blocks, showNav }) => (
    <Fragment>
        {showNav && <ContentNav blocks={blocks} />}

        {blocks.map((block, index) => {
            const blockProps = _.omit(block, 'type')
            if (block.type === 'accordion') {
                return <ContentBlockAccordion key={index} {...blockProps} />
            } else if (block.type === 'file-gallery') {
                return <ContentBlockDocument key={index} {...blockProps} />
            } else if (block.type === 'gallery') {
                return <ContentBlockGallery key={index} {...blockProps} />
            } else if (block.type === 'image') {
                return <ContentBlockImage key={index} {...blockProps} />
            } else if (block.type === 'milestone') {
                return <ContentBlockMilestone key={index} {...blockProps} />
            } else if (block.type === 'quote') {
                return <ContentBlockQuote key={index} {...blockProps} />
            } else if (block.type === 'table') {
                return <ContentBlockTable key={index} {...blockProps} />
            } else if (block.type === 'text') {
                return <ContentBlockText key={index} {...blockProps} />
            } else if (block.type === 'video') {
                return <ContentBlockVideo key={index} {...blockProps} />
            }
            return null
        })}
    </Fragment>
)

ContentBlocks.propTypes = {
    blocks: PropTypes.array.isRequired,
    showNav: PropTypes.bool,
}

export default ContentBlocks
