import { FlashSurveyContainer } from '../components/FlashSurvey'
import SurveyListView from '../views/SurveyListView'
import { SERVICE_SURVEY_SURVEYS } from '.'

export const componentMap = {
    'city/project/widget/home/flashsurvey': FlashSurveyContainer,
}
export const viewMap = {
    'city/survey/service/surveys': SurveyListView,
}

export const routeServiceMap = {
    'survey-details': SERVICE_SURVEY_SURVEYS,
    'survey-result-details': SERVICE_SURVEY_SURVEYS,
}
