import React from 'react'

import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import { Card } from '../Card'
import { Wrapper } from '../Wrapper'
import { Heading } from '../Heading'
import { Translation } from '../Translation'

import './_popup.scss'

const Popup = ({ children, heading, description, onClick = () => {}, ...classProps }) => (
    <div className={renderClasses('popup', classProps)}>
        <div className="popup_overlay" onClick={onClick}></div>
        <Wrapper>
            <div className="popup_card">
                <Card>
                    <div className="popup_text">
                        <Heading option={['h1']}>
                            <Translation value={heading} />
                        </Heading>
                        <p>
                            <Translation value={description} />
                        </p>
                    </div>
                    {children}
                </Card>
            </div>
        </Wrapper>
    </div>
)

Popup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default Popup
