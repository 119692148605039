import React from 'react'

import { View } from '../../../components/View'

import { SurveyResultDetailsContainer } from '../components/Survey'
import { retrieveRouteFromResult } from '../utils'

const SurveyResultDetailsView = ({ location }) => (
    <View option={['survey']}>
        <SurveyResultDetailsContainer
            slug={retrieveRouteFromResult(location.pathname.substr(1).replace(/\/$/, ''))}
        />
    </View>
)

export default SurveyResultDetailsView
