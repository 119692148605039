import {
    QuestionChoiceCheckbox,
    QuestionChoiceRadio,
    QuestionChoiceRadioMood,
    QuestionChoiceRadioRowsTwo,
    QuestionChoicePriorityDragAndDrop,
    QuestionTextarea,
} from '.'

export const QuestionComponents = {
    checkbox: {
        default: QuestionChoiceCheckbox,
    },
    radio: {
        default: QuestionChoiceRadio,
        mood: QuestionChoiceRadioMood,
        row_2: QuestionChoiceRadioRowsTwo,
    },
    textarea: {
        default: QuestionTextarea,
    },
    priority: {
        dragAndDrop: QuestionChoicePriorityDragAndDrop,
    },
}
