import React from 'react'

import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'

import { FaqListContainer } from '../components/Faq'
import { Wrapper } from '../../../components/Wrapper'

const FaqListView = ({ serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading={serviceData.name}
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            intro={serviceData.introBox}
        />

        <Wrapper>
            <FaqListContainer />
        </Wrapper>
    </View>
)

export default FaqListView
