import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { ContentBlockMilestoneStep } from '.'

import { isTranslationObject } from '../../../utils'
import { Wrapper } from '../../Wrapper'
import { ContentBlockHeader } from '../'

import './_contentMilestone.scss'
import '../_contentBlock.scss'

const ContentBlockMilestone = ({ title, steps, id }) => (
    <div className={`contentBlock`} id={id}>
        <Wrapper option={['medium']}>
            {title && <ContentBlockHeader title={title} option={['border']} />}
            <div className="contentMilestone">
                {steps.map((step, index) => {
                    const stepProps = _.omit(step, 'type')
                    if (step.type === 'step') {
                        const isLastCompleted =
                            !_.isNil(steps[index + 1]) &&
                            (!steps[index + 1].achievedDate ||
                                Date.now() <
                                    new Date(
                                        moment(
                                            steps[index + 1].achievedDate,
                                            'YYYY-MM-DD HH:mm'
                                        ).toDate()
                                    )) &&
                            (step.achievedDate ||
                                Date.now() >
                                    new Date(
                                        moment(step.achievedDate, 'YYYY-MM-DD HH:mm').toDate()
                                    ))
                                ? true
                                : false
                        return (
                            <ContentBlockMilestoneStep
                                key={index}
                                {...stepProps}
                                isLastCompleted={isLastCompleted}
                            />
                        )
                    }
                    return null
                })}
            </div>
        </Wrapper>
    </div>
)

ContentBlockMilestone.propTypes = {
    title: isTranslationObject,
    steps: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default ContentBlockMilestone
