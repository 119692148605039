import React from 'react'
import { Helmet } from 'react-helmet-async'

import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'
import { HeaderPage } from '../../../components/HeaderPage'
import { translation } from '../../../utils'

import { SubscriptionFormContainer } from '../components/SubscriptionForm'

const HubEditProfileView = () => (
    <View>
        <Helmet>
            <title itemProp="name">{translation('hub.editProfile.title')}</title>
        </Helmet>
        <HeaderPage
            heading="hub.editProfile.title"
            breadcrumb="app.espace_citoyen.title"
            icon="subscribe"
        />
        <Wrapper>
            <SubscriptionFormContainer />
        </Wrapper>
    </View>
)

export default HubEditProfileView
