import React, { Fragment } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { renderClasses, isTranslationObject } from '../../utils'

import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { Translation } from '../Translation'

import './_headerPage.scss'

import { Content } from '../Content'
import { Wrapper } from '../Wrapper'

import { Card, CardHeader } from '../Card'

const HeaderPage = ({
    heading,
    subheading,
    breadcrumb,
    icon,
    featured = false,
    backLink = false,
    intro,
    ...classProps
}) => (
    <Fragment>
        <header
            className={renderClasses('headerPage' + (featured ? ' -featured' : ''), classProps)}
        >
            {backLink && (
                <div className={'headerPage_button'}>
                    <Button option={['circle', 'white']} href={backLink} icon="arrow-left" />
                </div>
            )}

            {breadcrumb && (
                <p className="headerPage_breadcrumb">
                    <Translation value={breadcrumb} />
                </p>
            )}

            <div className="headerPage_heading">
                <Heading el="h1" option={['h1', 'noMargin']}>
                    <Translation value={heading} />
                </Heading>
            </div>

            {subheading && <p className="headerPage_subheading">{subheading}</p>}

            {icon && (
                <div className="headerPage_icon">
                    <Icon name={icon} />
                </div>
            )}
        </header>
        {intro && intro.active && (
            <div className="contentBlock">
                <Wrapper option={['medium']}>
                    <Card>
                        {!_.isEmpty(intro.title) && (
                            <CardHeader option={['noMargin']} heading={intro.title} />
                        )}
                        {!_.isEmpty(intro.description) && (
                            <Content className="question_description">
                                <p>
                                    <Translation value={intro.description} />
                                </p>
                            </Content>
                        )}
                    </Card>
                </Wrapper>
            </div>
        )}
    </Fragment>
)

HeaderPage.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.string, isTranslationObject]).isRequired,
    subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string, isTranslationObject]),
    breadcrumb: PropTypes.oneOfType([PropTypes.string, isTranslationObject]),
    icon: PropTypes.string,
    featured: PropTypes.bool,
    backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, isTranslationObject]),
    intro: PropTypes.object,
}

export default HeaderPage
