import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { isTranslationObject, translation } from '../../utils'
import { Copyright } from '../Copyright'
// import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { Translation } from '../Translation'
import { Wrapper } from '../Wrapper'

import './_footer.scss'

const Footer = ({ title, address, logo, privacyPolicy, termsOfUse }) => (
    <footer className="footer">
        <Wrapper>
            <Layout>
                {logo && (
                    <LayoutItem utility={['1/2']}>
                        <Link className="footer_logo" route="home">
                            {/*<Icon name="logo" title="app.agora.title" />*/}
                            <img src={logo} alt={translation(title)} />
                        </Link>
                    </LayoutItem>
                )}

                {address && (
                    <LayoutItem utility={['1/2']}>
                        <div className="footer_contact">
                            <Translation value={address} isHtml />
                        </div>
                    </LayoutItem>
                )}
                <LayoutItem utility={['1/2']}>
                    <Copyright />
                </LayoutItem>
                <LayoutItem utility={['1/2']}>
                    <div className="footer_links">
                        {termsOfUse && (
                            <Link option={['black']} href={`/${translation(termsOfUse.slug)}`}>
                                <Translation value={termsOfUse.title} />
                            </Link>
                        )}
                        {privacyPolicy && (
                            <Link option={['black']} href={`/${translation(privacyPolicy.slug)}`}>
                                <Translation value={privacyPolicy.title} />
                            </Link>
                        )}
                    </div>
                </LayoutItem>
            </Layout>
        </Wrapper>
    </footer>
)

Footer.propTypes = {
    title: isTranslationObject,
    address: isTranslationObject,
    logo: PropTypes.string,
}

const mapStateToProps = state => ({
    title: state.hub.hubSettings.entities.title,
    address: state.hub.hubSettings.entities.address,
    logo: state.hub.hubSettings.entities.logo,
    privacyPolicy: state.hub.hubSettings.entities.privacyPolicy,
    termsOfUse: state.hub.hubSettings.entities.termsOfUse,
})

export default connect(mapStateToProps)(Footer)
