import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/categories`

/**
 * Retrieve premit categories listing.
 * NOT USED
 *
export const fetchCategories = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}
*/

/**
 * Retrieve permit category.
 */
export const fetchCategory = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
