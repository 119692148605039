import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Icon } from '../../../../components/Icon'

import './_subscriptionFormCollapse.scss'

const SubscriptionFormCollapse = ({
    onCheck,
    item: { id, name, description, icon, color, required, checked },
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const defaultCheck = required || checked ? true : false

    return (
        <div className={'subscriptionFormCollapse' + (isOpen ? ' is-open' : '')}>
            <input
                className="subscriptionFormCollapse_input"
                type="checkbox"
                id={id}
                defaultChecked={defaultCheck}
                disabled={required}
                required={required}
                onChange={() => onCheck(id)}
            />
            <div className="subscriptionFormCollapse_header">
                <div
                    className="subcriptionFormCollapse_click"
                    onClick={() => setIsOpen(!isOpen)}
                ></div>
                <div className="subscriptionFormCollapse_text">
                    <label className="subscriptionFormCollapse_check" htmlFor={id}>
                        <Icon name="check" />
                    </label>
                    <div className="subscriptionFormCollapse_icon">
                        {icon && <Icon name={'memo-' + icon} />}
                    </div>
                    <div className="subscriptionFormCollapse_title">
                        <Translation value={name} />
                    </div>
                </div>
                <div className="subscriptionFormCollapse_info">?</div>
            </div>
            <div className="subscriptionFormCollapse_main">
                {description && <Translation value={description} isHtml />}
            </div>
        </div>
    )
}

SubscriptionFormCollapse.propTypes = {
    onCheck: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
}

export default SubscriptionFormCollapse
