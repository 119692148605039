import {
    AUTH_SET_TOKEN,
    CREATE_USER_LOGIN_REQUEST,
    CREATE_USER_LOGIN_SUCCESS,
    CREATE_USER_LOGIN_FAILURE,
    DELETE_USER_LOGIN_REQUEST,
    DELETE_USER_LOGIN_SUCCESS,
    DELETE_USER_LOGIN_FAILURE,
    FETCH_USER_SELF_REQUEST,
    FETCH_USER_SELF_SUCCESS,
    FETCH_USER_SELF_FAILURE,
    UPDATE_USER_SELF_REQUEST,
    UPDATE_USER_SELF_SUCCESS,
    UPDATE_USER_SELF_FAILURE,
} from '../actions/userActions'

const initialState = {
    isAuthenticated: false,
    token: null,
    user: null,
}

export function auth(state = initialState, action) {
    switch (action.type) {
        /**
         * Manage authentication token.
         */
        case AUTH_SET_TOKEN:
            return {
                ...state,
                token: action.token,
            }

        /**
         * Create a new login session, authenticating a specific user.
         */
        case CREATE_USER_LOGIN_REQUEST:
            return {
                ...state,
            }
        case CREATE_USER_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.result.data.token,
            }
        case CREATE_USER_LOGIN_FAILURE:
            return {
                ...state,
                token: null,
            }

        /**
         * Delete a login session, unauthenticating the authenticated user.
         */
        case DELETE_USER_LOGIN_REQUEST:
            return {
                ...state,
            }
        case DELETE_USER_LOGIN_SUCCESS:
            return {
                ...initialState,
            }
        case DELETE_USER_LOGIN_FAILURE:
            return {
                ...state,
            }

        /**
         * Retrieve the authenticated user's profile.
         */
        case FETCH_USER_SELF_REQUEST:
            return {
                ...state,
            }
        case FETCH_USER_SELF_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.result.data,
            }
        case FETCH_USER_SELF_FAILURE:
            return {
                ...initialState,
            }

        /**
         * Update the authenticated user's profile.
         */
        case UPDATE_USER_SELF_REQUEST:
            return {
                ...state,
            }
        case UPDATE_USER_SELF_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_USER_SELF_FAILURE:
            return {
                ...state,
            }

        default:
            return state
    }
}
