export const formIoI18n = {
    resources: {
        en: {
            translation: {
                complete: 'Submission Complete',
                error: 'Please fix the following errors before submitting.',
                required: '{{field}} is required',
                pattern: '{{field}} does not match the pattern {{pattern}}',
                minLength: '{{field}} must be longer than {{length}} characters.',
                maxLength: '{{field}} must be shorter than {{length}} characters.',
                min: '{{field}} cannot be less than {{min}}.',
                max: '{{field}} cannot be greater than {{max}}.',
                maxDate: '{{field}} should not contain date after {{- maxDate}}',
                minDate: '{{field}} should not contain date before {{- minDate}}',
                invalid_email: '{{field}} must be a valid email.', // eslint-disable-line camelcase
                invalid_url: '{{field}} must be a valid url.', // eslint-disable-line camelcase
                invalid_regex: '{{field}} does not match the pattern {{regex}}.', // eslint-disable-line camelcase
                invalid_date: '{{field}} is not a valid date.', // eslint-disable-line camelcase
                invalid_day: '{{field}} is not a valid day.', // eslint-disable-line camelcase
                mask: '{{field}} does not match the mask.',
                stripe: '{{stripe}}',
                month: 'Month',
                day: 'Day',
                year: 'Year',
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                may: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December',
                next: 'Next',
                previous: 'Previous',
                cancel: 'Cancel',
                submit: 'Submit Form',
            },
        },
        fr: {
            translation: {
                complete: 'Soumission complétée',
                error: 'Veuillez corriger les erreurs ci-dessus avant de soumettre.',
                required: '{{field}} est obligatoire',
                pattern: '{{field}} ne convient pas au format {{pattern}}',
                minLength: '{{field}} doit avoir plus de {{length}} caractères.',
                maxLength: '{{field}} doit avoir moins de {{length}} caractères.',
                min: '{{field}} doit être plus grand que {{min}}.',
                max: '{{field}} doit être plus petit que {{max}}.',
                maxDate: '{{field}} ne doit pas être une date postérieur à {{- maxDate}}',
                minDate: '{{field}} ne doit pas être une date antérieure à {{- minDate}}',
                invalid_email: "{{field}} n'est pas un courriel valide.", // eslint-disable-line camelcase
                invalid_url: '{{field}} doit être un URL valide.', // eslint-disable-line camelcase
                invalid_regex: '{{field}} ne convient pas au format {{regex}}.', // eslint-disable-line camelcase
                invalid_date: "{{field}} n'est pas une date valide.", // eslint-disable-line camelcase
                invalid_day: "{{field}} n'est pas un jour valide.", // eslint-disable-line camelcase
                mask: '{{field}} ne convient pas au mask.',
                stripe: '{{stripe}}',
                month: 'Mois',
                day: 'Jour',
                year: 'Année',
                january: 'Janvier',
                february: 'Février',
                march: 'Mars',
                april: 'Avril',
                may: 'Mai',
                june: 'Juin',
                july: 'Juillet',
                august: 'Août',
                september: 'Septembre',
                october: 'Octobre',
                november: 'Novembre',
                december: 'Décembre',
                next: 'Suivant',
                previous: 'Précédent',
                cancel: 'Annuler',
                submit: 'Soumettre',
                'Drop files to attach, or': 'Joindre des fichiers, ou',
                browse: 'naviguer',
                'File Name': 'Nom',
                Size: 'Poid',
            },
        },
    },
}
