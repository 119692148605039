import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Marker } from '@react-google-maps/api'

const GoogleMapPoint = ({ path, isClickable, index, selectPoint, isSelectedItem = false }) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(isSelectedItem)
    }, [isSelectedItem])

    let options = {
        fillColor: 'blue',
        fillOpacity: 0.2,
        // strokeColor: "blue",
        strokeOpacity: 0.5,
        // strokeWeight: 2,
        clickable: isClickable,
        geodesic: false,
    }

    if (isSelected) {
        options = {
            ...options,
            ...{
                fillColor: 'green',
            },
        }
    }

    const handleClick = polyMouseEvent => {
        if (!isClickable) {
            return null
        }

        setIsSelected(!isSelected)
        selectPoint(index)
        polyMouseEvent.stop()
    }

    return <Marker position={path} onClick={handleClick} options={options} />
}

GoogleMapPoint.propTypes = {
    path: PropTypes.object.isRequired,
    isClickable: PropTypes.bool,
    index: PropTypes.number,
    selectPoint: PropTypes.func,
    isSelectedItem: PropTypes.bool,
}

export default GoogleMapPoint
