import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Heading } from '../../../../components/Heading'
import { Content } from '../../../../components/Content'

import './_ideaDetailsComments.scss'

const IdeaDetailsComments = ({ comments }) => {
    if (!comments.length) {
        return null
    }

    const displayedComments = comments.map(comment => (
        <div className="ideaDetailsComments" key={comment.id}>
            <p className="ideaDetailsComments_author">
                {!comment.user.id ? <Translation value="hub.anonymous" /> : comment.user.fullName}
            </p>

            <div className="ideaDetailsComments_content">
                <Content>{comment.content}</Content>
            </div>

            <hr />
        </div>
    ))

    return (
        <div>
            <Heading el="h4" option={['h3']} utility={['textCenter']}>
                <Translation value="idea.details.comment.label" />

                {comments.length > 1 && ` (${comments.length})`}
            </Heading>

            <hr />
            {displayedComments}
        </div>
    )
}

IdeaDetailsComments.propTypes = {
    comments: PropTypes.array.isRequired,
}

export default IdeaDetailsComments
