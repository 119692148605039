import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../Translation'
import { isTranslationObject, translation } from '../../utils'

import { Wrapper } from '../Wrapper'

import './_contentImage.scss'
import './_contentBlock.scss'

const ContentBlockImage = ({ image, caption, fromGallery = false, id }) => (
    <div className="contentBlock" id={id}>
        {image && translation(image) && (
            <div className={'contentImage' + fromGallery && ' -gallery'}>
                <img
                    src={translation(image)}
                    alt={caption && translation(caption)}
                    className="contentImage_image"
                />

                <Wrapper option={['medium']}>
                    <span className="contentImage_caption">
                        {caption && <Translation value={caption} />}
                    </span>
                </Wrapper>
            </div>
        )}
    </div>
)

ContentBlockImage.propTypes = {
    image: PropTypes.object,
    caption: isTranslationObject,
    fromGallery: PropTypes.bool,
    id: PropTypes.string,
}

export default ContentBlockImage
