import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { renderMappedComponent } from '../../../../utils/components'
import { componentMap as hubComponentMap } from '../../config/mappings'
import { componentMap as memoComponentMap } from '../../../memo/config/mappings'
import { componentMap as projectComponentMap } from '../../../project/config/mappings'
import { componentMap as surveyComponentMap } from '../../../survey/config/mappings'

const HomeDashboard = ({ dashboard }) => {
    const componentMap = {
        ...hubComponentMap,
        ...projectComponentMap,
        ...memoComponentMap,
        ...surveyComponentMap,
    }
    return dashboard.widgets.map(widgetData => {
        const { type, ...props } = widgetData

        const renderedComponents = renderMappedComponent(type, componentMap, props)

        // @todo remove this return when integration done in order to understand the view
        return <div key={widgetData.id}>{renderedComponents}</div>
        // return renderMappedComponent(type, hubComponentMap, props)
    })
}

HomeDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired,
}

HomeDashboard.Placeholder = () => (
    <ContentLoader
        height={500}
        width={500}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="28" y="19" rx="5" ry="5" width="219" height="123" />
        <rect x="288" y="35" rx="0" ry="0" width="132" height="5" />
        <rect x="288" y="57" rx="0" ry="0" width="141" height="14" />
        <rect x="288" y="106" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="79" rx="0" ry="0" width="141" height="14" />
        <rect x="30" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="50" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="50" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="50" y="318" rx="20" ry="20" width="122" height="33" />
        <rect x="288" y="176" rx="5" ry="5" width="180" height="101" />
        <rect x="308" y="283" rx="0" ry="0" width="132" height="5" />
        <rect x="308" y="295" rx="0" ry="0" width="124" height="12" />
        <rect x="308" y="318" rx="20" ry="20" width="122" height="33" />
    </ContentLoader>
)

export default HomeDashboard
