import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import _ from 'lodash'

import Slider from 'react-slick'

import { isTranslationObject } from '../../../../utils'

import { Section, SectionHeader } from '../../../../components/Section'
import { Heading } from '../../../../components/Heading'
import { Wrapper } from '../../../../components/Wrapper'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { localizePath } from '../../../../utils'

import { AlertCard } from '.'

import './_alertSlider.scss'

const AlertSliderNext = props => {
    const { className, onClick } = props
    return (
        <div className={'alertSlider_arrow -next ' + className}>
            <Button
                option={['circle', 'white', 'small']}
                icon="arrow-right"
                onClick={onClick}
            ></Button>
        </div>
    )
}

const AlertSliderPrev = props => {
    const { className, onClick } = props
    return (
        <div className={'alertSlider_arrow -prev ' + className}>
            <Button
                option={['circle', 'white', 'small']}
                icon="arrow-left"
                onClick={onClick}
            ></Button>
        </div>
    )
}

const AlertSlider = ({
    alerts,
    categories,
    showHeader = false,
    name = false,
    seeAllButton = false,
}) => {
    const count = alerts.length

    const settings = {
        dots: false,
        speed: 600,
        variableWidth: true,
        infinite: false,
        slidesToScroll: 2,
        nextArrow: <AlertSliderNext />,
        prevArrow: <AlertSliderPrev />,
        responsive: [
            {
                breakpoint: 699,
                settings: {
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const header = (
        <SectionHeader>
            <Heading option={['h3']} el="h2">
                {name}
                <sup>{count}</sup>
            </Heading>
            {seeAllButton && (
                <Button
                    option={['small', 'gray', 'iconRight']}
                    icon="arrow-right"
                    href={localizePath(seeAllButton.url)}
                >
                    <Translation value={seeAllButton.label} />
                </Button>
            )}
        </SectionHeader>
    )

    const renderedAlerts = alerts.map((alert, i) => {
        const category = _.find(categories, category => category.id === alert.category)
        return (
            <div className="alertSlider_item" key={i}>
                <div className="alertSlider_item_wrap">
                    <AlertCard key={alert.id} {...alert} category={category} />
                </div>
            </div>
        )
    })

    return (
        <Section option={['overflow', 'marginSmall']}>
            <Wrapper>
                {showHeader && header}

                {alerts.length > 1 ? (
                    <Slider className="alertSlider" {...settings}>
                        {renderedAlerts}
                    </Slider>
                ) : (
                    <div className="u-margin-bottom">
                        {alerts.map((alert, i) => {
                            const category = _.find(
                                categories,
                                category => category.id === alert.category
                            )
                            return <AlertCard key={alert.id} {...alert} category={category} />
                        })}
                    </div>
                )}
            </Wrapper>
        </Section>
    )
}

AlertSlider.Placeholder = () => <ContentLoader></ContentLoader>

AlertSlider.propTypes = {
    name: PropTypes.object,
    seeAllButton: PropTypes.shape({
        label: isTranslationObject,
        url: isTranslationObject,
    }),
    showHeader: PropTypes.bool,
    alerts: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

export default AlertSlider
