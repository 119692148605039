import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'

import { getStore } from '../store'
import { isServer } from '../utils'

/**
 * @see    https://github.com/mrded/is-url-external
 * @param  {String} url
 * @return {Boolean}
 */
export const isExternal = url => {
    if (isServer) {
        return false
    }

    // We don't have an easy access to the hostname when SSR... so fake it till we make it.
    var host = window.location.hostname

    var linkHost = (function(url) {
        // Absolute URL.
        if (/^https?:\/\//.test(url)) {
            // The easy way to parse an URL, is to create <a> element.
            // @see: https://gist.github.com/jlong/2428561
            var parser = document.createElement('a')
            parser.href = url

            return parser.hostname
        } else {
            // Relative URL.
            return window.location.hostname
        }
    })(url)

    return host !== linkHost
}

export const normalizeRouteData = data => {
    const defaultData = {
        id: '',
        bind: '',
        param: '',
    }

    if (typeof data === 'object') {
        return { ...defaultData, ...data }
    } else {
        return { ...defaultData, ...{ id: data } }
    }
}

export const normalizePath = path => {
    if (typeof path !== 'string' || path === '') {
        throw new Error('Path is empty.')
    }

    if (isExternal(path)) {
        return path
    }

    return path[0] === '/' ? path : `/${path}`
}

export const localizePath = path => {
    const { code: lang } = getActiveLanguage(getStore().getState().localize)

    if (typeof path === 'string') {
        return normalizePath(path.replace(':lang', lang))
    } else if (typeof path === 'object') {
        return normalizePath(path[lang])
    } else {
        throw new Error('Invalid path format given for localization.')
    }
}

/**
 * Render a route according to a route identifier and parameter data.
 */
export const renderRoute = routeData => {
    const { id, bind, param } = normalizeRouteData(routeData)

    const routes = getStore().getState().routes
    const route = _.find(routes, ['id', id])
    if (typeof route === 'undefined') {
        return ''
    }

    if (bind !== '' && param !== '') {
        return localizePath(route.path).replace(`:${bind}+`, param)
    } else if (typeof route.url !== 'undefined') {
        return localizePath(route.url)
    } else {
        return localizePath(route.path)
    }
}

/**
 * Render an absolute URL according to a route identifier and parameter data.
 *
 * @param {Object}
 * @return {String}
 */
export const renderAbsoluteRoute = routeData => {
    const origin = window.location.origin
    const route = renderRoute(routeData)
    return `${origin}${route}`
}

/**
 * Saves static routes in Redux store.
 *
 * @param  {Object} store
 * @param  {Array}  modules
 * @return {void}
 */
export const storeStaticRoutes = (store, modules) => {
    /**
     * Holds all the service related to views data as exported by our supported modules.
     * By related to views data, we mean as the routeServiceMap in config.js files.
     *
     * @type {Object}
     */
    const modulesRouteServices = _.flatten(
        _.compact(_.map(modules, module => module.mappings.routeServiceMap))
    )

    /**
     * Holds all the static routes definitions as exported by our supported modules.
     * By static definitions, we mean as defined in routes.json files.
     *
     * @type {Object}
     */
    const moduleRoutes = _.flatten(
        // Before flattening the collection of arrays into a single array, we remove empty values.
        _.compact(
            // Undefined module['routes'] will be purged. You don't need to worry about needing to
            // define an empty route key in your module's export file (index.js).
            _.map(modules, module =>
                _.map(module.routes, route => {
                    // Map the route to a related service
                    const relatedRouteService = _.find(
                        modulesRouteServices,
                        moduleRouteService => moduleRouteService[route.id]
                    )
                    if (relatedRouteService) {
                        return { ...route, service: relatedRouteService[route.id] }
                    }
                    return route
                })
            )
        )
    )

    store.dispatch({ type: 'ADD_ROUTES', payload: moduleRoutes })
}
