import _ from 'lodash'

/**
 * Group service by service category
 *
 * @param  {array} services
 * @return {Object}
 */
function groupServicesByCategory(services) {
    const servicesCategoriesId = _.compact(_.uniq(_.map(services, 'category')))
    return _.groupBy(services, service => {
        let key = null
        _.forEach(servicesCategoriesId, serviceCategoryId => {
            if (service.category === serviceCategoryId) {
                key = serviceCategoryId
                return false
            }
        })
        return key
    })
}

/**
 * Reorder the services grouped by categories
 *
 * @param  {array} services
 * @param  {array} serviceCategories
 * @return {array}
 */
export function parseServiceCategories(serviceCategories, services) {
    const groupedServices = groupServicesByCategory(services)
    return _.forEach(serviceCategories, serviceCategory => {
        return (serviceCategory.services = _.find(
            groupedServices,
            (service, index) => index === serviceCategory.id
        ))
    })
}
