import React from 'react'

import PropTypes from 'prop-types'
import { Link } from '../Link'

import { renderClasses } from '../../utils'

import './_card.scss'

const Card = ({ children, href = null, route = null, external = false, ...classProps }) => {
    return href || route ? (
        <Link
            className={renderClasses('card -link u-buttonHover', classProps)}
            href={href}
            route={route}
            external={external}
        >
            {children}
        </Link>
    ) : (
        <div className={renderClasses('card', classProps)}>{children}</div>
    )
}

Card.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default Card
