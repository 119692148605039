import React from 'react'

import PropTypes from 'prop-types'

const FormError = ({ children }) => {
    if (children !== undefined) {
        return <div className="form_error">* {children}</div>
    } else {
        return false
    }
}

FormError.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
}

export default FormError
