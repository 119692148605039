import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Polygon } from '@react-google-maps/api'

const GoogleMapPolygon = ({ paths, isClickable, index, selectPolygon, isSelectedItem = false }) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(isSelectedItem)
    }, [isSelectedItem])

    let options = {
        fillColor: '#054AE2',
        fillOpacity: 0.2,
        strokeColor: '#054AE2',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        clickable: isClickable,
        geodesic: false,
    }

    if (isSelected) {
        options = {
            ...options,
            ...{
                fillColor: '#26AB75',
                strokeColor: '#26AB75',
                fillOpacity: 0.4,
            },
        }
    }

    const handleClick = polyMouseEvent => {
        if (!isClickable) {
            return null
        }

        setIsSelected(!isSelected)
        selectPolygon(index)
        polyMouseEvent.stop()
    }

    return <Polygon paths={paths} onClick={handleClick} options={options} />
}

GoogleMapPolygon.propTypes = {
    paths: PropTypes.array.isRequired,
    isClickable: PropTypes.bool,
    index: PropTypes.number,
    selectPolygon: PropTypes.func,
    isSelectedItem: PropTypes.bool,
}

export default GoogleMapPolygon
