import * as mappings from './config/mappings'
import routes from './config/routes.json'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'
import * as views from './views'

export default {
    mappings,
    routes,
    translations: {
        en: enTranslations,
        fr: frTranslations,
    },
    views,
}
