import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/alerts`

/**
 * Retrieve alert listing.
 */
export const fetchAlerts = (config = {}) => {
    return apiClient.get(`${baseUrl}`, config)
}

/**
 * Retrieve alert details by slug.
 */
export const fetchAlertBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}

/**
 * Retrieve alert details.
 */
export const fetchAlert = (id, config = {}) => {
    return apiClient.get(`${baseUrl}/${id}`, config)
}
