import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ContentLoader from 'react-content-loader'

import { FINISHED_CONTENT } from '../../../../config'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Button } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'

import { translation } from '../../../../utils'
import { hasUserAlreadyParticipated } from '../../utils'
import { SurveyCard } from '.'

const SurveyList = ({ surveys, categories, userSurveys = [], filter, url }) => {
    if (!surveys.length) {
        if (filter === translation(FINISHED_CONTENT)) {
            return (
                <CardFeedback text="survey.listing.finished.placeholder.description">
                    <Button option={['blue', 'iconLeft']} icon="arrow-left" href={translation(url)}>
                        <Translation value="survey.listing.finished.placeholder.button" />
                    </Button>
                </CardFeedback>
            )
        } else {
            return (
                <CardFeedback text="survey.listing.ongoing.placeholder.description">
                    <Button
                        option={['blue', 'iconRight']}
                        icon="arrow-right"
                        href={`${translation(url)}?status=${translation(FINISHED_CONTENT)}`}
                    >
                        <Translation value="survey.listing.ongoing.placeholder.button" />
                    </Button>
                </CardFeedback>
            )
        }
    }

    const renderedSurveys = surveys.map(survey => {
        const category = _.find(categories, category => category.id === survey.category)
        const isCompleted = hasUserAlreadyParticipated(userSurveys, survey.id)

        return (
            <SurveyCard
                survey={survey}
                category={category}
                isCompleted={isCompleted}
                key={survey.id}
            />
        )
    })

    return <div>{renderedSurveys}</div>
}

SurveyList.propTypes = {
    surveys: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    userSurveys: PropTypes.array,
}

SurveyList.Placeholder = () => <ContentLoader></ContentLoader>

export default SurveyList
