import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'

import { fetchAlerts } from '../../actions/alertActions'
import { fetchCategories } from '../../actions/categoryActions'
import { useDev, logErrors } from '../../../../utils'

import { AlertList, AlertSlider } from './'

const AlertListContainer = ({ service }) => {
    const useFetchAlerts = useCallback(() => {
        return fetchAlerts()
    }, [])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const alerts = useFetchItems(useFetchAlerts)
    const categories = useFetchItems(useFetchCategories)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, alerts, categories, responseCustomizer)
    useDev(logErrors(result.error))

    const alertService = service || {}
    const selectedAlertIds = !_.isEmpty(alertService.options) && !_.isEmpty(alertService.options.selectedAlerts) ? alertService.options.selectedAlerts : []

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <div>
                        <AlertSlider.Placeholder />
                        <AlertList.Placeholder />
                    </div>
                ) : (
                    <AlertList alerts={alerts.items} selectedAlertIds={selectedAlertIds} categories={categories.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default AlertListContainer
