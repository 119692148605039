import React, { useState } from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'
// import _ from 'lodash'

import { Button } from '../../../../components/Button'
import { FormItem } from '../../../../components/Form'
import { FormLabel } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Spinner } from '../../../../components/Spinner'

import { createStripePayment } from '../../actions/stripeActions'

import './_stripe.scss'

const StripeCheckout = ({ payment, apiKey, submitPayment, stripe, setPaymentSuccess }) => {
    // const stripe = window.Stripe(apiKey)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const cardElementStyle = {
        base: {},
        invalid: {
            color: '#CF2F45',
        },
    }

    // While testing, use a test card number that requires authentication (e.g., 4000002760003184)
    // Using a card that doesn’t require authentication (e.g., 4242424242424242) skips this part of the flow and complete at fist createStripePayment.
    // More info:
    // - https://stripe.com/docs/testing#international-cards
    // - https://stripe.com/docs/testing
    const handlePayment = async event => {
        event.preventDefault()

        if (submitPayment(event)) {
            return
        }

        setIsLoading(true)

        const { paymentMethod, error } = await stripe.createPaymentMethod('card', {
            billing_details: {
                name: `${payment.firstname} ${payment.lastname}`,
                email: payment.email,
                // phone: payment.phone,
            },
        })

        // first check on stripe card validation
        if (error) {
            setIsLoading(false)
            setErrors(['Carte invalide.'])
            return
        }

        const paymentMethodData = {
            invoiceId: payment.id,
            paymentType: 'method',
            paymentId: paymentMethod.id,
        }

        let result
        try {
            result = await createStripePayment(paymentMethodData)
        } catch (errors) {
            return handleServerErrors(errors)
        }

        // when stripe require action
        if (result.data.requires_action) {
            // retrieve action from stripe
            const { payment_intent_client_secret } = result.data
            const { paymentIntent, error } = await stripe.handleCardAction(
                payment_intent_client_secret
            )

            if (error) {
                setIsLoading(false)
                setErrors(["Impossible d'authentifier la methode de paiement"])
                return false
            }

            const paymentIntentData = {
                invoiceId: payment.id,
                paymentType: 'intent',
                paymentId: paymentIntent.id,
            }

            // re-execute payment
            try {
                result = await createStripePayment(paymentIntentData)
                if (result.data && result.data.success) {
                    setPaymentSuccess(true)
                }
            } catch (errors) {
                return handleServerErrors(errors)
            }
        } else if (result.data.success) {
            setPaymentSuccess(true)
        }
    }

    /**
     * handle server errors
     * send errors from api to parent component
     */
    const handleServerErrors = errors => {
        let stripeErrors = []
        if (errors.response.data.length) {
            errors.response.data.forEach(err => {
                stripeErrors.push(<Translation value={`payment.form.stripe.errors.${err.code}`} />)
            })
        }

        setIsLoading(false)
        setErrors(stripeErrors)

        return false
    }

    return (
        <div>
            <FormItem>
                <FormLabel>
                    <Translation value="payment.form.card" />
                </FormLabel>

                <CardElement style={cardElementStyle} hidePostalCode />
                <div className="stripeError has-error">{errors}</div>
            </FormItem>

            {!isLoading && (
                <Button
                    option={['green', 'full', 'large', 'iconRight']}
                    icon="arrow-right"
                    onClick={handlePayment}
                >
                    <Translation value="payment.form.pay.title" /> {payment.amount}$
                </Button>
            )}

            {isLoading && <Spinner />}
        </div>
    )
}

export default injectStripe(StripeCheckout)
