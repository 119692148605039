export const stripeDependencies = [
    {
        type: 'script',
        src: 'https://js.stripe.com/v3/',
        id: 'stripe-js',
        attribute: 'data-stripe-injected',
        onLoad: () => {
            window.AgoraStripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
        },
    },
]
