import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Translation } from '../../../../components/Translation'
import { isTranslationObject } from '../../../../utils'

import './_statisticAnswer.scss'

const StatisticAnswer = ({ statistic: { percentage, title } }) => {
    if (_.isNull(title)) {
        return null
    }

    return (
        <div className="statisticAnswers_item">
            <Translation value={title} isHtml />

            <div className="statisticAnswers_bar">
                <div
                    className="statisticAnswers_bar_main"
                    style={{ width: percentage + '%' }}
                ></div>
                <div className="statisticAnswers_bar_percentage">{percentage + '%'}</div>
            </div>
        </div>
    )
}
StatisticAnswer.propTypes = {
    statistic: PropTypes.shape({
        title: isTranslationObject,
        percentage: PropTypes.number.isRequired,
    }).isRequired,
}

export default StatisticAnswer
