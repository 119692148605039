import React, { useState } from 'react'
import { connect } from 'react-redux'

import { SwitchTransition } from 'react-transition-group'

import { View } from '../../../components/View'
import { HomeDashboardContainer } from '../components/HomeDashboard'
import { OnboardingContainer } from '../../../modules/hub/components/Onboarding'
import { Fade } from '../../../components/Fade'
import { isServer } from '../../../utils'

import { LOCALSTORAGE_ONBOARDING_DISPLAYED } from '../config'

const HomeView = ({ hasViewedOnboarding, isAuthenticated }) => {
    // retrieve directly by redux store by default or retrieve local storage for on any action in page.
    const [isOnboardingViewed, setOnboardingViewed] = useState(
        isServer
            ? true
            : hasViewedOnboarding || localStorage.getItem(LOCALSTORAGE_ONBOARDING_DISPLAYED)
    )

    const [isDashboardLoaded, setDashboardLoaded] = useState(false)

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isDashboardLoaded}>
                {isOnboardingViewed || isAuthenticated ? (
                    <React.Fragment>
                        <View>
                            <HomeDashboardContainer
                                setIsLoaded={param => setDashboardLoaded(param)}
                            />
                        </View>
                    </React.Fragment>
                ) : (
                    <OnboardingContainer setIsViewed={param => setOnboardingViewed(param)} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
})

export default connect(mapStateToProps)(HomeView)
