import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Wrapper } from '../Wrapper'
import { renderClasses, parseServiceCategories } from '../../utils'

const FooterMenu = ({ services, serviceCategories }) => {
    const groupedServices = parseServiceCategories(serviceCategories, services)

    return (
        <footer className={renderClasses('footer')}>
            <Wrapper>
                <nav>
                    <div>
                        {groupedServices.map((serviceCategory, index) => (
                            <div key={index}></div>
                        ))}
                    </div>
                </nav>
            </Wrapper>
        </footer>
    )
}

FooterMenu.propTypes = {
    services: PropTypes.array.isRequired,
    serviceCategories: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    services: state.hub.services.entities,
    serviceCategories: state.hub.serviceCategories.entities,
})

export default connect(mapStateToProps)(FooterMenu)
