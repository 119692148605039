import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'

import { fetchIdeaBySlug, fetchIdeaCommentsBySlug } from '../../actions/ideaActions'
import { fetchCategory } from '../../actions/categoryActions'
import { useDev, logErrors } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { fetchSelf, fetchAnonymousVotes } from '../../actions/voteActions'

import { IdeaDetails } from './'

const IdeaDetailsContainer = ({ isAuthenticated, fetchAnonymousVotes, fetchSelf, slug }) => {
    useEffect(() => {
        if (!isAuthenticated) {
            fetchAnonymousVotes()
        } else {
            fetchSelf()
        }
    }, [isAuthenticated, fetchSelf, fetchAnonymousVotes])

    // prepare function to retrieve idea by slug
    const useFetchIdea = useCallback(() => {
        return fetchIdeaBySlug(slug)
    }, [slug])
    const idea = useFetchItems(useFetchIdea)

    const useFetchComments = useCallback(() => {
        return fetchIdeaCommentsBySlug(slug)
    }, [slug])
    const comments = useFetchItems(useFetchComments)

    const useFetchCategory = useCallback(() => {
        if (_.isNil(idea.items.category)) {
            return () => {}
        }
        return fetchCategory(idea.items.category)
    }, [idea.items.category])
    const category = useFetchItems(useFetchCategory)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, idea, category, comments, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {idea.requestStatus === 404 ||
                (!isLoading && idea.items.publishStatus !== 'published') ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <IdeaDetails.Placeholder />
                ) : (
                    <IdeaDetails
                        idea={idea.items}
                        category={category.items}
                        comments={comments.items}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
})

const mapDispatchToProps = dispatch => ({
    fetchSelf: config => dispatch(fetchSelf(config)),
    fetchAnonymousVotes: () => dispatch(fetchAnonymousVotes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdeaDetailsContainer)
