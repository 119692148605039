import { combineReducers } from 'redux'
import * as mappings from './config/mappings'
import routes from './config/routes.json'
import { subscriptions } from './reducers/subscriptions'
import { settings } from './reducers/settings'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'
import * as views from './views'

export default {
    mappings,
    reducer: combineReducers({
        subscriptions,
        settings,
    }),
    routes,
    translations: {
        en: enTranslations,
        fr: frTranslations,
    },
    views,
}
