import moment from 'moment'

import { parseServices } from '../utils'

import {
    FETCH_SERVICE_LIST_REQUEST,
    FETCH_SERVICE_LIST_SUCCESS,
    FETCH_SERVICE_LIST_FAILURE,
} from '../actions/serviceActions'

const initialState = {
    entities: [],
    lastQueried: null,
}

export function services(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICE_LIST_REQUEST:
            return {
                ...state,
                lastQueried: moment().format(),
            }
        case FETCH_SERVICE_LIST_SUCCESS:
            return {
                entities: parseServices(action.result.data),
            }
        case FETCH_SERVICE_LIST_FAILURE:
            return {
                entities: [],
            }
        default:
            return state
    }
}
