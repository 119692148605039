import { combineReducers } from 'redux'

import routes from './config/routes.json'
import * as mappings from './config/mappings'
import { auth } from './reducers/auth'
import { hubSettings } from './reducers/hubSettings'
import { services } from './reducers/services'
import { hasViewedOnboarding } from './reducers/onboardings'
import { serviceCategories } from './reducers/serviceCategories'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'
import * as views from './views'

export default {
    mappings,
    reducer: combineReducers({
        auth,
        hubSettings,
        services,
        serviceCategories,
        hasViewedOnboarding,
    }),
    routes,
    translations: {
        en: enTranslations,
        fr: frTranslations,
    },
    views,
}
