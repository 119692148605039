import React, { useCallback } from 'react'
import { SwitchTransition } from 'react-transition-group'
import _ from 'lodash'

import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { useFetchItems } from '../../../../utils'
import { fetchSurveyStatisticsBySlug } from '../../actions/surveyActions'

import { SurveyResultDetails } from '.'

const SurveyResultDetailsContainer = ({ slug }) => {
    const useFetchSurveyStatistics = useCallback(() => {
        return fetchSurveyStatisticsBySlug(slug)
    }, [slug])

    const { items, error, requestStatus, isLoading } = useFetchItems(useFetchSurveyStatistics)

    if (items.statistics)
        items.statistics = items.statistics.filter(item => item.type !== 'textarea')

    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <SurveyResultDetails.Placeholder />
                ) : requestStatus === 400 ? (
                    <SurveyResultDetails.NotAvailable />
                ) : (
                    <SurveyResultDetails survey={items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}
export default SurveyResultDetailsContainer
