import React, { useCallback } from 'react'
import _ from 'lodash'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useFetchItems, useDev, logErrors } from '../../../../utils'

import { fetchCategories } from '../../actions/categoryActions'
// import { fetchService } from '../../../hub/actions/serviceActions'

import { IdeaSubmitForm } from './'

const IdeaSubmitFormContainer = () => {
    /*
    const useFetchService = useCallback(() => {
        return fetchService(serviceData.id)
    }, [serviceData.id])
    */

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const { isLoading, error, items } = useFetchItems(useFetchCategories)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {isLoading || !_.isNil(error) ? (
                    <IdeaSubmitForm.Placeholder />
                ) : (
                    <IdeaSubmitForm
                        categories={items}
                        // flashsurvey={service.items.flashsurvey}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default IdeaSubmitFormContainer
