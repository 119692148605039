import React from 'react'
import PropTypes from 'prop-types'

// import { Button } from '../../../../components/Button'
import { FilterButtons, FilterButtonsItem } from '../../../../components/FilterButtons'
import { isTranslationObject, localizePath, translation } from '../../../../utils'

import { ONGOING_CONTENT, FINISHED_CONTENT } from '../../../../config'

const ProjectFilterPicker = ({ baseUrl, selected }) => (
    <FilterButtons>
        <FilterButtonsItem
            href={localizePath(baseUrl)}
            label="project.details.ongoing.title"
            selected={selected === ONGOING_CONTENT}
        />
        <FilterButtonsItem
            href={`${localizePath(baseUrl)}?status=${translation(FINISHED_CONTENT)}`}
            label="project.details.finished.title"
            selected={selected === translation(FINISHED_CONTENT)}
        />
    </FilterButtons>
)

ProjectFilterPicker.propTypes = {
    baseUrl: isTranslationObject,
    selected: PropTypes.string.isRequired,
}
export default ProjectFilterPicker
